@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.delete-common-configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-common-configuration__stage-name {
  max-width: unset;
  width: unset;
  margin-top: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @include big-phone {
    margin-top: 1.5rem;
  }
}

.delete-common-configuration__trash-icon {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

.delete-common-configuration__title {
  margin: 0 0 1rem;
}

.delete-common-configuration__ordered-list {
  max-width: 26.125rem;
  width: 100%;
  margin: 0 0 1.125rem;
  padding: 1.25rem 1.375rem;
  font-size: $small;
  background-color: var(--secondary-background);
  border: 1px solid var(--gentle-stroke);
  border-radius: 0.5rem;
  list-style-position: inside;

  li {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.delete-common-configuration__form {
  max-width: 36.5rem;
  width: 100%;

  .delete-common-configuration__checkbox-label {
    margin-left: 0.875rem;
    font-family: PoppinsSans, sans-serif;
    font-weight: bold;
    font-size: $small;
  }
}

.delete-common-configuration__input,
.delete-common-configuration__checkbox {
  margin-top: 1.5rem;
}

.delete-common-configuration__input-label {
  font-size: $xsmall;
}

.delete-common-configuration__info-text {
  margin: 2rem 0 0;
  font-size: $small;
  color: $light-gray-2;
  text-align: center;
}

.delete-common-configuration__modal-footer {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.description {
  white-space: pre-line;
}

.descriptionShortened {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@import "../../../../../../../../../constants";
@import "../../../../../../../../../styles/sizes";

.item {
  --item-color: #{$c-neutrals-600};
  --item-bg-color: transparent;

  padding: 0.875rem var(--item-pr) 0.875rem var(--item-pl);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
  font-size: $small;
  color: var(--item-color);
  text-decoration: none;
  background-color: var(--item-bg-color);
  transition: background-color 0.2s;

  &:hover {
    --item-bg-color: #{$c-pink-hover-feed-cards};
  }
}
.itemActive {
  --item-bg-color: #{$c-pink-active-feed-cards};

  &:hover {
    --item-bg-color: var(--hover-fill);
  }
}
.itemDisabled {
  --item-color: #{$c-neutrals-300};

  cursor: not-allowed;
}

.tooltipContent {
  max-width: 20rem;
  z-index: 3;
}

.textWrapper {
  display: flex;
  align-items: center;
}

.textWrapperActive {
  color: $white;
}

.text {
  margin-left: 0.875rem;
}

@import "../../../../../../constants";

.container {
  min-height: 3rem;
  margin: 1.5rem auto 0;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: $small;
  color: var(--primary-text);
  background-color: var(--secondary-background);
  border-radius: 0.25rem;
  box-sizing: border-box;
}

.avatar {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.resultText {
  margin-right: 1rem;
  word-break: break-word;
}

.icon {
  flex-shrink: 0;
}

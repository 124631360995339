@import "../../../../../../../../constants";
@import "../../../../../../../../styles/sizes";

.create-common-review-image-slider {
  position: relative;
  display: flex;
  overflow: hidden;

  .create-common-review-image-slider__slide {
    display: flex;
  }

  .create-common-review-image-slider__image-container {
    width: 100%;
  }

  .create-common-review-image-slider__image {
    width: 100%;
    height: 14rem;
    object-fit: cover;
  }

  .create-common-review-image-slider__arrow-wrapper {
    position: absolute;
    top: 50%;
    z-index: 2;
    color: $white;
    transform: translateY(-50%);

    &:disabled {
      color: $gray;
    }
  }

  .create-common-review-image-slider__left-arrow-wrapper {
    @extend .create-common-review-image-slider__arrow-wrapper;
    left: 1.5rem;

    @include big-phone {
      left: 1rem;
    }
  }

  .create-common-review-image-slider__right-arrow-wrapper {
    @extend .create-common-review-image-slider__arrow-wrapper;
    right: 1.5rem;

    @include big-phone {
      right: 1rem;
    }
  }

  .create-common-review-image-slider__arrow-icon {
    width: 2rem;
    height: 2rem;
  }

  .create-common-review-image-slider__gallery-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 2;
  }

  .create-common-review-image-slider__text-wrapper {
    position: absolute;
    top: 0;
    right: 4rem;
    bottom: 0;
    left: 4rem;
    z-index: 2;
    max-height: 100%;
    height: 100%;
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    text-align: center;
    word-break: break-word;
    box-sizing: border-box;
    pointer-events: none;

    @include big-phone {
      left: 3.75rem;
      right: 3.75rem;
      justify-content: flex-start;
    }
  }

  .create-common-review-image-slider__common-info-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .create-common-review-image-slider__hint-text {
    margin: 0;
    font-size: $xsmall;
  }

  .create-common-review-image-slider__common-name {
    margin: 0 0 0.25rem;
    text-shadow: 0 0.125rem 0.25rem $black-gray-4;
    font-family: NotoSerifSC-Bold, serif;
    font-size: $moderate;
    font-weight: bold;
    line-height: 1.22;

    &:last-child {
      margin: 0;
    }
  }
}

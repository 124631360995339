@import "../../../../../constants";
@import "../../../../../styles/sizes";

.tabs {
  flex-shrink: 0;
  z-index: 2;
  height: var(--layout-tabs-height, 4rem);
  display: none;
  grid-template-columns: repeat(var(--items-amount, 3), minmax(0, 1fr));
  background-color: var(--secondary-background);
  border-top: 0.0625rem solid var(--gentle-stroke);
  white-space: nowrap;

  @include tablet {
    display: grid;
  }
}

.tab {
  --tab-ml: 3rem;
  --tab-pb: 1.375rem;
  --tab-fw: 600;
  --tab-fs: #{$small};
  --tab-inactive-color: #{$c-neutrals-300};
  --tab-inactive-hover-color: var(--hover-fill);
  --tab-active-color: var(--primary-fill);

  @include tablet {
    --tab-ml: 0;
    --tab-pb: 0;
    --tab-fs: #{$xxxsmall-2};
  }
}

.iconWrapper {
  position: relative;
  height: 1.5rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconBadge {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 0.625rem;
  padding: 0 0.125rem;
  font-family: PoppinsSans, sans-serif;
  font-weight: 600;
  font-size: $xxxsmall-3;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: $c-shades-100;
  background-color: $c-error-300;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

@import "../../../../../../../../../../constants";

.container {
  display: flex;
  flex-direction: column;
}

.field {
  margin-bottom: 1.875rem;

  &:last-child {
    margin-bottom: 0;
  }

  .textFieldLabelWrapper {
    font-size: $xsmall;
  }

  .textFieldHint {
    font-style: normal;
    color: $c-neutrals-300;
  }
}

.titleTextarea {
  height: 3.125rem !important;
}

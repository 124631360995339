@import "../../../constants";
@import "../../../styles/sizes";

.page-not-found {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-text);

  .page-not-found__message {
    margin: 0 0 0 1.5rem;
    font-size: $moderate-med;
    font-family: NotoSerif;
    margin: 3rem 0;
    text-align: center;
  }

  @include big-phone {
    margin-top: 2.5rem;
    img {
      width: 20rem;
    }
  }
}

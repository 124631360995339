@import "../../../../constants.scss";
@import "../../../../styles/sizes";

.chatWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: inherit;
  height: calc(100% - var(--chat-header-height, 0rem));
  background-color: var(--primary-background);
  box-sizing: border-box;
  --chat-input-wrapper-height: 3.125rem;
}

.messages {
  z-index: 0;
  height: 100%;
  overflow: auto;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column-reverse;
  padding: 0.5rem 2rem 0 2rem;

  @include big-phone {
    --chat-input-wrapper-height: 2.5rem;
    padding: 0.5rem 0.75rem 0 0.5rem;
  }
}

.emptyChat {
  display: flex;
  justify-content: center;
  align-self: center;
}

.chatInputWrapper {
  display: flex;
  flex-shrink: 0;
  padding: 0 1.125rem;
  background-color: var(--primary-background);
  border-top: 0.0625rem solid var(--gentle-stroke);
  box-shadow: none;
  min-height: var(--chat-input-wrapper-height);
  align-items: center;
  justify-content: space-between;
  border-radius: 0;
}

.chatInputWrapperMultiLine {
  align-items: flex-end;
}

$phone-breakpoint: 415px;

.container {
  position: absolute;
  bottom: 50%;
  right: 0.5625rem;
  transform: translateY(50%);
  z-index: 10000;
}

.singleEmojiText {
  font-size: $xlarge;
  line-height: 2.8125rem;
}

.multipleEmojiText {
  font-size: $large;
  line-height: 2.125rem;
}

.mentionTextCurrentUser {
  color: $c-pink-mention-2;
  font-weight: 600;
}

.singleEmojiText {
  font-size: $xlarge;
}

.multipleEmojiText {
  font-size: $large;
}

.scrollToBottomContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: calc(var(--chat-input-wrapper-height) + 2rem);
  height: 2.5rem;
  width: 3rem;
  padding-left: 0.5rem;
  background-color: var(--primary-background);
  border-radius: 8px 0 0 8px;
  box-shadow: 0px 4px 15px 0px $shadow;
  cursor: pointer;
}

.scrollToBottomIcon {
  color: var(--primary-fill);
}

@import "../../../../../constants";

$border-radius: 0.75rem;

.edit-common-menu {
  color: $secondary-blue;
}

.edit-common-menu__menu-modal-title {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $moderate-small;
  color: $secondary-blue;
}

.edit-common-menu__menu-modal-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.edit-common-menu__menu-modal-button {
  padding: 1.375rem 2rem;
  display: flex;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-weight: 600;
  color: $secondary-blue;

  &:hover {
    text-decoration: none;
  }
}
.edit-common-menu__menu-modal-button--disabled {
  color: $light-gray-3;
}

.edit-common-menu__dropdown {
  z-index: $share-z-index;
}

.edit-common-menu__dropdown-menu {
  min-width: 16.125rem;
  border: 0;
  border-radius: $border-radius;
  box-shadow: 0 3px 8px 0 $shadow-2;
}

.edit-common-menu__dropdown-menu-list {
  max-height: unset;
}

.edit-common-menu__dropdown-menu-item {
  padding: 1.125rem 1.5rem;
  display: flex;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
  font-weight: 600;
  color: $secondary-blue;
  border-bottom: 1px solid $light-gray-1;

  &:first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  &:hover {
    background-color: $light-gray-4;
  }
}
.edit-common-menu__dropdown-menu-item--disabled {
  color: $light-gray-3;
  cursor: not-allowed;
}
.edit-common-menu__dropdown-menu-item--red {
  color: $red;
}

.edit-common-menu__item-icon {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

@import "../../../../../../../../../../constants";

.container {
  padding: 1.25rem 0.625rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-background);
  margin-top: 2.5rem;
}

.table {
  width: 100%;
  border-spacing: 0;
}

.tableHead {
  border-bottom: 1px solid var(--gentle-stroke);
  padding: 0 1rem;
}

.tableBody {
  padding: 0 1rem;
  background-color: var(--tertiary-fill);
  width: 100%;
  color: var(--primary-text);
}

.tableHeader {
  text-align: left;
  padding-bottom: 0.375rem;
  white-space: nowrap;
  font-size: $xsmall;
  font-weight: 400;
  padding-right: 1rem;
  color: var(--primary-text);
}

.tableHeader:first-child {
  min-width: 6rem;
  max-width: 10rem;
}

.tableCell {
  padding-top: 0.5rem;
  padding-bottom: 0.375rem;
  border-top: 0.0625rem solid var(--gentle-stroke);
  border-bottom: 0.0625rem solid var(--gentle-stroke);
}

.tableCell:first-child {
  padding-left: 0.5625rem;
  width: 1.125rem;
  border-left: 0.0625rem solid var(--gentle-stroke);
}

.goalCell {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  -webkit-box-align: center;
  overflow: hidden;
}

.tableCell:last-child {
  border-right: 0.0625rem solid var(--gentle-stroke);
}

.editIcon {
  width: 24px;
  height: 24px;
  margin: 0 0.5rem;
}

.addRecipientButton {
  padding: 0.5rem 0.8125rem;
  margin: 0;
}

.icon {
  width: 0.6875rem;
  height: 0.6875rem;
}

.clickableRow {
  cursor: pointer;
}

@import "../../../constants.scss";
@import "../../../styles/sizes";

.social-links-wrapper {
  z-index: $share-z-index;
  position: absolute;
  margin: 1.5rem 0;
  border-radius: 1.875rem;
  box-shadow: 0 0.25rem 1rem 0 $shadow;
  top: 4rem;
  right: 10%;

  .title {
    font-size: $xsmall;
    font-weight: bold;
    text-align: center;
    color: $blue;
    margin-bottom: 1rem;
  }
}

.social-links-wrapper--modal-mobile {
  padding-top: 0.5rem;
  padding-bottom: 0;
}

.social-links-wrapper--modal-desktop {
  box-shadow: none;
  border-radius: 0;
  position: static;
}

.social-links-wrapper--top-center {
  top: -1rem;
  transform: translateX(50%) translateY(-100%);
  right: 50%;
}

.social-links-wrapper--loading {
  min-width: 7rem;
}

.social-links {
  display: flex;
  justify-content: center;

  button {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.5rem;
    height: 3.5rem;
    background-color: var(--secondary-background);
    box-shadow: 0px 6.77778px 25.4167px rgba(0, 0, 0, 0.15259);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    margin: 0 0.75rem;

    & > .social-icon {
      width: 2rem;
      height: 2rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    & > .facebook-icon {
      color: #0077fb;
    }

    & > .telegram-icon {
      color: #34aadf;
    }

    & > .twitter-icon {
      color: #1da1f2;
    }
  }

  button:first-child {
    margin-left: unset;
  }

  button:last-child {
    margin-right: unset;
  }

  button:hover {
    opacity: 0.9;

    & > .facebook-icon {
      color: #5d6ef9;
    }

    & > .telegram-icon {
      color: #00bfff;
    }

    & > .twitter-icon {
      color: #6495ed;
    }
  }
}

.social-links--modal-mobile {
  button {
    width: 3rem;
    height: 3rem;
    margin: 0 0.75rem;

    @include phone {
      width: 2.5rem;
      height: 2.5rem;
      margin: 0 0.4rem;
    }
  }
}

@include tablet {
  .social-links-wrapper {
    position: unset;
    box-shadow: unset;

    .social-links {
      align-items: center;
    }
  }
}

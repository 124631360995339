@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.update-common-confirmation-error {
  width: 100%;
  max-width: 23.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .update-common-confirmation-error__image {
    width: 10.825rem;
    height: 10.825rem;
    margin-top: 2rem;

    @include big-phone {
      max-width: 16.75rem;
      width: 100%;
      max-height: 16.75rem;
      height: 100%;
      margin-top: 0;
    }
  }

  .update-common-confirmation-error__title {
    margin: 4.625rem 0 1rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: bold;
    text-align: center;

    @include big-phone {
      margin: 1.5rem 0 2rem;
    }
  }

  .update-common-confirmation-error__sub-title {
    margin: 0;
    font-size: $xsmall;
    text-align: center;
  }

  .update-common-confirmation-error__submit-button {
    max-width: 11.25rem;
    margin-top: 3.5rem;

    @include big-phone {
      max-width: unset;
      margin-top: 5rem;
    }
  }

  .update-common-confirmation-error__view-details {
    display: none;
    margin: 1.5rem 0 1rem;
    font-size: $xsmall;
    color: $light-gray-2;
    text-align: center;
    text-decoration: none;

    @include big-phone {
      width: 100%;
      margin: 0.5rem 0 0;
      padding: 1rem 0;
    }
  }

  .update-common-confirmation-error__error {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    background-color: $purple-alpha-10;
    border-radius: 0.875rem;
    color: $error;
    text-align: center;
    word-break: break-word;

    @include big-phone {
      padding: 0.5rem 1rem;
    }
  }
}

@import "../../../../../../../../constants";

.voters {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.voterItem {
  border-bottom: 0.0625rem solid var(--gentle-stroke);

  &:last-child {
    border-bottom: 0;
  }
}

.noVotesText {
  margin: 0;
  padding: 0.8125rem 1rem;
}

@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.proposal-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;

  .title {
    font-size: $moderate-med;
    font-weight: bold;
    line-height: 1.17;
    letter-spacing: normal;
    color: $secondary-blue;
    font-family: PoppinsSans, sans-serif;
  }

  .add-button {
    display: flex;
    padding: 0.5rem 1.5rem;
    border-radius: 30.5px;
    background-color: $light-gray-7;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $secondary-blue;
    cursor: pointer;
    width: 176px;
    align-items: center;
    justify-content: center;
    font-family: PoppinsSans, sans-serif;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

.proposals-component-wrapper {
  .proposal-item-wrapper {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 8px $shadow-2;
    border-radius: 0.625rem;
    margin: 2rem 0;
    cursor: pointer;
    .proposal-item-header {
      position: relative;
      background-color: $purple-2;
      border-top-right-radius: 0.625rem;
      border-top-left-radius: 0.625rem;
      display: flex;
      flex-direction: column;
      padding: 2rem 0rem 2rem 2rem;
      .proposal-item-header-top {
        display: flex;
        justify-content: space-between;
        //padding: 2rem 0.5rem 2rem 2rem;
        margin-bottom: 0.5rem;
      }
      .proposal-item-type {
        text-align: right;
        background-color: $light-gray-9;
        display: flex;
        align-self: flex-end;
        padding: 0.3rem;
        border-bottom-left-radius: 0.4rem;
        border-top-left-radius: 0.4rem;
      }
      .proposal-title {
        font-size: $moderate-med;
        color: $white;
        font-weight: bold;
        cursor: pointer;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .proposal-item-body {
      display: flex;
      flex-direction: column;
      .user-info-wrapper {
        display: flex;
        padding: 2rem;
        .name-and-proposal-state {
          margin-left: 1rem;
          .user-name {
            font-weight: bold;
          }
        }
      }
    }
    .proposal-item-votes-wrapper {
      width: 300px;
      margin: 0.5rem 0;
    }
    .proposal-item-bottom {
      display: flex;
      justify-content: space-between;
      border-top: 0.06rem solid $light-gray-1;
      padding: 1rem;
      .discussion-count-wrapper {
        display: flex;
        .discussion-count {
          margin-left: 0.5rem;
          color: $light-gray-2;
        }
      }
      .extra-data {
        color: $light-gray-2;
      }
    }
  }
}

@include big-phone {
  .proposal-title-wrapper {
    .add-button {
      position: fixed;
      right: 1.5rem;
      bottom: 4.625rem;
      z-index: 10;
      box-shadow: 0 0 6px 0 $fixed-button-shadow;
      background-color: $purple;
      width: 64px;
      height: 64px;
      min-width: unset;
      border-radius: 50%;
      padding: unset;

      span {
        display: none;
      }

      img {
        margin-right: unset;
        filter: invert(1);
      }
    }
  }

  .proposals-component-wrapper {
    .proposal-item-wrapper {
      .proposal-item-votes-wrapper {
        width: 100%;
      }
    }
    .discussion-item-wrapper {
      padding: 20px;
      padding-top: 0;

      .state-wrapper {
        margin: 0 -20px;
      }

      .countdown-wrapper {
        margin-right: -20px;
        margin-left: -20px;
      }
    }
  }

  .proposals-component-wrapper {
    .discussion-item-wrapper {
      .discussion-content {
        .additional-information {
          .swiper-slide {
            &.image-item {
              flex-shrink: 0 !important;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@include small-laptop {
  .proposals-component-wrapper {
    .discussion-item-wrapper {
      .discussion-content {
        .additional-information {
          .images-wrapper {
            .image-item {
              img {
                width: 15rem;
                height: 10.625rem;
              }
            }
          }
        }
      }
    }
  }
}

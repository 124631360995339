@import "../../../../../../../../../constants";
@import "../../../../../../../../../styles/sizes";

.contentWrapper {
  display: flex;
  padding: 0.375rem 0.75rem;
  border-radius: 0.313rem;

  &:hover {
    background-color: var(--secondary-hover-fill);
  }

  &.open {
    color: var(--highlight);
  }
}

.menuButton {
  width: 100%;
  margin: 0;
  padding: 0.875rem 0.625rem;
  display: flex;
  align-items: center;
  color: var(--primary-text);
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;

  @include tablet {
    padding-left: 0.875rem;
    padding-right: 0;
  }
}

.avatar {
  margin-right: 1rem;
}

.name {
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@import "../../../constants";

.file-preview-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $file-preview-z-index;

  .file-preview-wrapper__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $carousel-overlay-shadow;
  }

  .file-preview-wrapper__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .file-preview-wrapper__content--zoomed {
    padding-bottom: 1.5rem;
  }

  .file-preview-wrapper__top-content {
    flex-shrink: 0;
    margin: 1.5rem 7.5rem 1rem;
  }

  .file-preview-wrapper__file-preview {
    flex: 1 0;
  }

  .file-preview-wrapper__close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    background-color: $black-gray;
    border-radius: 50%;
    color: $white;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
}

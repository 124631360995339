@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.remove-circle-member-info {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: $small;
  line-height: 100%;
}

.remove-circle-member-info__avatar {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
}

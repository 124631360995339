@import "../../../constants";

.loader {
  flex-shrink: 0;
  width: 3.125rem;
  height: 3.125rem;
}

.bigLoader {
  height: 4.6875rem;
  width: 4.6875rem;
}

.globalLoaderOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $c-shades-neutrals-30;
}

.globalLoaderWrapper {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-background);
  border-radius: 1rem;
}

@import "../../../constants";
@import "../../../styles/mixins";

.hide-modal {
  max-width: 25rem;
  width: 100%;
  border-radius: 0.5rem;
}

.hide-modal__header {
  .modal__title {
    font-size: $moderate-med;
  }
}

.hide-modal__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.hide-modal__title {
  font-size: $moderate;
  color: $secondary-blue;
  text-align: center;
  margin-top: 0;
}

.hide-modal__input {
  resize: none;
  width: 100%;
  border-width: 0;
  outline: 0;
  border: 0.0625rem $light-gray-3 solid;
  height: 100%;
}

.hide-modal__button-container {
  display: flex;
  justify-content: flex-end;
}

.hide-modal__button-container__button {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
}

.hide-modal__button-container__cancel {
  background-color: $light-gray-1;
  color: $light-gray-2;
  margin-right: 0.75rem;
}

.hide-modal__button-container__cancel:hover {
  background-color: $light-gray-3;
}

.hide-modal__button-container__send {
  background-color: $error;
  color: $white;
  margin-left: 0.75rem;
}

.hide-modal__button-container__send:hover {
  background-color: $red;
}

.hide-modal__button-container__send__loader {
  height: 1.25rem;
  width: 1.25rem;
}

@import "../../../../../constants";
@import "../../../../../constants";
@import "../../../../../styles/sizes";

.billing-bank-account-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include big-phone {
    max-width: 20.75rem;
    height: 100%;
  }
}

.billing-bank-account-info__details {
  width: 100%;
  padding: 1.5rem 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary-background);
  border: 1px solid var(--gentle-stroke);
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: var(--primary-text);

  @include big-phone {
    padding: 2.25rem 1.5rem;
  }
}

.billing-bank-account-info__details-list {
  width: 100%;
  margin: 0 0 1.875rem;
  padding: 0;
  font-size: $xsmall;
  text-align: left;
  list-style-type: none;

  @include big-phone {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.billing-bank-account-info__details-list-item {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  &:last-child {
    margin-bottom: 0;
  }

  @include big-phone {
    max-width: 13.75rem;
    width: 100%;
  }
}

.billing-bank-account-info__hint {
  margin: 1rem 0;
  font-size: $xsmall;
  text-align: center;
}

.billing-bank-account-info__details-buttons-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  // FIXME: uncomment & adjust this after an issues with bank account's deleting will be resolved on the BE
  // justify-content: space-between;
  align-items: center;
  width: 80%;

  @include big-phone {
    flex-direction: column;
    width: 100%;
  }
}

.billing-bank-account-info__delete-prompt-modal {
  margin: 0;

  .modal__content {
    padding: 0;
  }
}

@import "../../../constants";
@import "../../../styles/sizes";

.delete-prompt__wrapper {
  display: flex;
  flex-direction: column;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 11;
  background-color: var(--primary-background);
  align-items: center;
  color: var(--primary-text);
  border-radius: 30px;
  padding: 45px;
  filter: drop-shadow(0 0.6rem 1.3rem rgba(0, 0, 0, 0.25));
  box-shadow: 0 0.25rem 1rem 0 var(--drop-shadow);

  .delete-prompt__buttons-wrapper {
    display: flex;
    margin-top: 2rem;

    button {
      font-family: PoppinsSans, sans-serif;
      font-weight: 100;
      min-width: 9.5rem;
      margin: 0 0.5rem;

      &.delete {
        background-color: var(--warning);
        color: var(--primary-text);
        margin-left: 1.5rem;
      }
    }
  }

  .delete-prompt__title {
    margin: 0.5rem 1rem;
  }

  .delete-prompt__description {
    margin: 0.5rem 1rem;
  }
}

@include big-phone {
  .delete-prompt__wrapper {
    transform: translate(-50%, -100%);
    top: 100%;
    padding: 15px;
    border-radius: 50px 50px 0 0;
    width: 100%;

    .delete-prompt__title {
      margin-top: 15px;
    }

    .delete-prompt__buttons-wrapper {
      flex-direction: column;
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      margin-top: unset;
      button {
        margin: 0.5rem 0;
        width: 100%;
      }
    }
  }
}

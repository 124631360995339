@import "../../../../../constants.scss";

.about-name-wrapper {
  padding: 40px;
  border-radius: 14px;
  box-shadow: 0 3px 8px 0 #001a3614;
  background-color: $white;

  .about-name-wrapper__see-more {
    width: 100%;
    margin-bottom: 1.75rem;
    display: inline-block;
    text-align: right;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: #001a36;
    margin-top: 24px;
    margin-bottom: 20px;
  }
  .links {
    display: flex;
    flex-direction: column;
    a {
      font-size: 16px;
      font-weight: bold;
      color: #001a36;
      margin-bottom: 16px;
      vertical-align: middle;
      display: inline-block;
      &::before {
        background-image: url("/icons/link.svg");
        display: inline-block;
        content: " ";
        background-size: 15px 15px;
        height: 15px;
        width: 15px;
        margin-right: 8px;
      }
      &:hover {
        color: $purple;
      }
    }
  }

  .join-wrapper {
    .button-blue {
      width: 175px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .social-wrapper {
      width: 270px;
    }
  }
  .see-more-button {
    font-family: PoppinsSans, sans-serif;
    color: gray;
    text-align: right;
  }
}
.about-name-wrapper__description {
  white-space: pre-line;
}

.about-name-wrapper__description--shortened {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media only screen and (max-width: $mobile-breakpoint) {
  .about-name-wrapper {
    padding: 20px;
    padding-left: unset;
    padding-right: unset;
    box-shadow: none;
    background-color: transparent;
  }
}

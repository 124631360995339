@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.leave-common-main-step {
  display: flex;
  flex-direction: column;

  .leave-common-main-step__checkbox-label {
    margin-left: 0.875rem;
    font-family: PoppinsSans, sans-serif;
    font-weight: bold;
    font-size: $small;
  }
}

.leave-common-main-step__text {
  margin: 0;
  font-size: $small;
}

.leave-common-main-step__ordered-list {
  margin: 0.875rem 0 1.125rem;
  padding: 1.25rem 1.375rem;
  font-size: $small;
  background-color: var(--secondary-background);
  border: 1px solid var(--gentle-stroke);
  border-radius: 0.5rem;
  list-style-position: inside;

  li {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.leave-common-main-step__error-text {
  font-size: $small;
}

.leave-common-main-step__buttons-wrapper {
  margin-top: 3.25rem;
  display: flex;
  justify-content: flex-end;
}

.leave-common-main-step__button {
  max-width: 9.5rem;
  margin-right: 1.375rem;

  &:last-child {
    margin-right: 0;
  }

  @include big-phone {
    max-width: unset;
  }
}

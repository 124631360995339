.headingMarkIcon {
  width: 1.25rem;
  height: 1rem;
}

.listMarkIcon {
  width: 1rem;
  height: 0.75rem;
}

.linkMarkIcon {
  width: 1.125rem;
  height: 1.125rem;
}

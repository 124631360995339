@import "../../../../../constants";
@import "../../../../../styles/mixins";
@import "../../../../../styles/sizes";

.login-help-buttons {
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--primary-text);
  text-align: left;
  list-style-type: none;
  box-sizing: border-box;

  .login-help-buttons__item {
    border-bottom: 1px solid var(--gentle-stroke);

    &:last-child {
      border-bottom: 0;
    }
  }

  .login-help-buttons__link {
    padding: 1.125rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: 600;
    color: inherit;
    text-decoration: none;
  }
}
@include rtl-direction {
  .login-help-buttons {
    direction: rtl;
  }

  .login-help-buttons__arrow-icon {
    transform: rotate(180deg);
  }
}

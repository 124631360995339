@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.buttonIcon {
  background-color: transparent;

  &:hover {
    background-color: var(--hover-fill);
  }
}

.icon {
  width: 1rem;
  height: 1rem;
  color: inherit;

  @include tablet {
    width: 0.875rem;
    height: 0.875rem;
  }
}

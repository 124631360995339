@import "../../../../../constants";
@import "../../../../../styles/sizes";

$max-content-width: 36.125rem;

.proposal-page-votes-modal {
  color: $secondary-blue;

  .proposal-page-votes-modal__title {
    margin: 0;
    padding: 1.75rem 0 1.5rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: 600;
    line-height: 121%;
    text-align: center;

    @include big-phone {
      padding: 1.5rem 0;
      font-family: PoppinsSans, sans-serif;
      font-weight: bold;
      font-size: $moderate;
      line-height: 100%;
    }
  }

  .proposal-page-votes-modal__content-wrapper {
    @include big-phone {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .proposal-page-votes-modal__header-wrapper {
    @include big-phone {
      padding: 0 1rem;
    }
  }

  .proposal-page-votes-modal__content {
    max-width: $max-content-width;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include big-phone {
      max-width: unset;
    }
  }

  .proposal-page-votes-modal__tabs-wrapper {
    max-width: $max-content-width;
    margin: 0 auto;
    padding: 0 1.5rem;
    justify-content: space-between;
    white-space: nowrap;
    word-break: keep-all;
    border-bottom: 1px solid $light-gray-1;
    box-sizing: border-box;

    @include big-phone {
      max-width: unset;
      margin: 0 -1rem;
      justify-content: flex-start;
    }
  }

  .proposal-page-votes-modal__tab {
    padding-bottom: 0.375rem;
    font-family: PoppinsSans, sans-serif;
    font-size: $xsmall;
    font-weight: 600;
    line-height: 143%;
    letter-spacing: 0.02em;
    border-bottom-width: 3px;
  }

  .proposal-page-votes-modal__tab-panels-wrapper {
    display: flex;
    flex-direction: column;
  }

  .proposal-page-votes-modal__vote-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .proposal-page-votes-modal__no-votes-text {
    padding: 0 $content-padding-mobile;
    font-size: $small;
    text-align: center;
    color: $light-gray-2;
  }
}

@import "../../../constants";

.custom-dropdown-wrapper {
  position: relative;
  color: var(--primary-text);
  font-family: PoppinsSans, sans-serif;
  font-size: $xsmall;
  z-index: $dropdown-z-index;

  &.--open {
    z-index: $dropdown-z-index + 1;
  }
}

.custom-dropdown-wrapper__label-wrapper {
  margin-bottom: 0.625rem;
  display: flex;
  font-size: $xsmall;
  line-height: 1.32;
}

.custom-dropdown-wrapper__label {
  text-align: left;
}

.custom-dropdown-wrapper__menu-button {
  width: 100%;
  height: 3rem;
  display: inline-flex;
  align-items: center;
  padding: 0.75rem 0.8rem;
  border-radius: 3px;
  border: 1px solid var(--gentle-stroke);
  background-color: var(--secondary-background);
  user-select: none;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
}
.custom-dropdown-wrapper__menu-button--disabled {
  color: $c-gray-30;
  border-color: var(--gentle-stroke);
}

.custom-dropdown-wrapper__value {
  flex: 1;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-dropdown-wrapper__placeholder {
  color: $light-gray-2;
}

.custom-dropdown-wrapper__arrow-icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  transform: rotate(90deg);
  transition: all 0.2s;
}

.custom-dropdown-wrapper__menu {
  position: absolute;
  width: 100%;
  border: 1px solid var(--gentle-stroke);
  background-color: var(--tertiary-fill);
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
  }
}
.custom-dropdown-wrapper__menu--fixed {
  position: fixed;
  max-width: 17.125rem;
}

.custom-dropdown-wrapper__arrow-icon--opened {
  transform: rotate(-90deg);
}

.custom-dropdown-wrapper__menu-button--hidden {
  display: none;
}

.custom-dropdown-wrapper__menu-list {
  max-height: 18rem;
  margin: 0;
  padding: 0;
  overflow-y: auto;
  list-style: none;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-dropdown-wrapper__menu-item {
  padding: 0.75rem 0.8rem;
  word-break: break-word;
  transition: all 0.2s;

  &:hover {
    background-color: var(--hover-fill);
  }
}
.custom-dropdown-wrapper__menu-item--active {
  background-color: var(--secondary-background);
}

.custom-dropdown-wrapper__global-overlay {
  z-index: $dropdown-z-index - 1;
}

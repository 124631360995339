@import "../../../constants";
@import "../../../styles/sizes";

$invoice-tile-border-radius: 14px;

.invoice-tile {
  position: relative;
  width: 160px;
  height: 160px;

  .invoice-tile__top-content-wrapper {
    margin-bottom: 8px;
    display: flex;
  }

  .invoice-tile__image-wrapper {
    color: $secondary-blue;
    text-decoration: none;
    cursor: pointer;
  }

  .invoice-tile__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: $invoice-tile-border-radius;
  }
  .invoice-tile__image--full-width {
    height: 100px;
  }
  .invoice-tile__image--general-file {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray-1;
    text-align: center;
    word-break: break-word;
    user-select: none;
    overflow: hidden;
  }

  .invoice-tile__delete-button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 8px;
    margin-right: 8px;
    padding: 8px;
    border-radius: 50%;
    background-color: $black-gray;
    color: $white;

    &:hover {
      opacity: 0.9;
    }
  }
  .invoice-tile__delete-button--static {
    position: static;
    top: unset;
    right: unset;
    margin: 0 0 0 auto;
    padding: 0 8px;
    border-radius: unset;
    background-color: unset;
    color: $black;
  }

  .invoice-tile__delete-icon {
    width: 16px;
    height: 16px;
  }

  .invoice-tile__amount {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 8px 16px;
    font-family: PoppinsSans, sans-serif;
    font-size: $xxsmall;
    font-weight: bold;
    color: $white;
    text-align: center;
    border-bottom-left-radius: $invoice-tile-border-radius;
    border-bottom-right-radius: $invoice-tile-border-radius;
    background-color: $black-gray;
  }
  .invoice-tile__amount--static {
    position: static;
    right: unset;
    bottom: unset;
    left: unset;
    padding: 0 0 0 8px;
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
    text-align: left;
    border-radius: 0;
    background-color: unset;
  }
  .invoice-tile__amount--purple {
    color: $purple;
  }
}
.invoice-tile--full-width {
  width: 100%;
  height: unset;
}

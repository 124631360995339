@import "../../../../../constants";
@import "../../../../../styles/sizes";

.container {
  position: fixed;
  top: 0;
  right: calc(var(--page-extra-pr) + var(--sb-h-indent));
  left: var(--sb-h-indent);
  z-index: 3;
  height: var(--header-h);
  padding-left: 1.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-background);
  box-sizing: border-box;
}

.leftContent {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.menuButton {
  margin-right: 0.5rem;
  color: $c-gray-40;
}

.backLink {
  display: flex;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-size: 1rem;
  color: var(--primary-text);

  &:hover {
    text-decoration: underline;
  }
}

.backIcon {
  flex-shrink: 0;
  margin-right: 0.375rem;
}

.rightContent {
  flex-shrink: 0;
  height: 100%;
  display: flex;
}

.navigation {
  position: relative;
  padding-right: 2rem;

  &:after {
    content: "";
    position: absolute;
    top: 0.5rem;
    right: 0;
    bottom: 0.5rem;
    width: 0.0625rem;
    background-color: $c-gray-30;
  }
}

.userInfoContentButton {
  height: 100%;
}

.userInfoAvatar {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}

.userInfoName {
  font-family: PoppinsSans, sans-serif;
  font-size: $moderate-xsmall;
  font-weight: normal;
}

.userInfoLoginButton {
  margin-left: 1rem;
}

.userInfoRightArrowIcon {
  margin-left: 2.5rem;
}

.menuItemsWrapper {
  left: 0;
  right: 0;
}

@import "../../../constants";

.element-dropdown__menu-wrapper {
  .element-dropdown__menu {
    width: 14rem;
    height: 9rem;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0.5rem;
    z-index: 1;
  }

  .element-dropdown__menu-item {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    height: 3rem;
    padding: 0.65625rem 1.5rem;
    font-size: $xsmall;
    box-shadow: inset 0 -0.0625rem 0 var(--drop-shadow);

    > :first-child {
      margin-right: 0.3rem;
    }
  }

  .element-dropdown__menu-button--transparent {
    background-color: transparent;

    button {
      background-color: transparent;
    }
  }

  .share-icon {
    width: 16px;
    height: 16px;
  }

  .dropdown-copy-link {
    display: flex;
    align-items: center;

    > :first-child {
      margin-right: 0.3rem;
    }
  }
}

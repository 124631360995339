@import "../../../../../../../../../constants";

.itemsWrapper {
  --items-br: 0.5rem;

  position: absolute;
  left: var(--sidenav-content-ph);
  right: var(--sidenav-content-ph);
  z-index: 1;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  border-radius: var(--items-br);
  filter: drop-shadow(0 0.25rem 0.9375rem var(--drop-shadow));
}
.itemsWrapperPlacementTop {
  bottom: 100%;
}
.itemsWrapperPlacementBottom {
  top: 100%;
}

@import "../../../../constants";

.custom-radio-button-group {
  display: flex;
  flex-direction: column;
  font-family: PoppinsSans, sans-serif;
  font-weight: normal;
  font-size: $xsmall;
  margin-bottom: 1rem;

  .custom-radio-button-group__label {
    margin-bottom: 1rem;
    font-size: $xsmall;
    line-height: 1.42;
  }

  .custom-radio-button-group__buttons {
    width: 35%;
    justify-content: space-between;
    display: flex;
  }
  .custom-radio-button-group__buttons--vertical {
    flex-direction: column;
  }
}

@import "../../../constants";
@import "../../../styles/sizes";

.header-wrapper {
  padding: 10px $content-padding;
  box-shadow: 0 3px 10px 0 $shadow;
  background-color: $white;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: $header-z-index;
  box-sizing: border-box;
  width: 100%;
  height: $header-height;

  .logo {
    height: 60px;
  }

  .navigation-wrapper {
    width: 100%;
    margin-left: 7.5rem;
    display: flex;

    .my-account-button-wrapper {
      flex-shrink: 0;
    }

    .header-wrapper__mobile-link {
      position: relative;

      &:disabled {
        background-color: $light-gray-1;
      }
    }

    .header-wrapper__link-loader {
      @include big-phone {
        position: absolute;
        top: 50%;
        right: 0.5rem;
        left: unset;
        width: 2rem;
        height: 2rem;
        transform: translateY(-50%);
      }
    }

    button,
    a {
      text-decoration: none;
      font-size: $moderate;
      color: $blue;
      margin: 0 3.125rem 0 0;
      padding: 0;
      background-color: transparent;
      border: none;
      font-family: PoppinsSans, sans-serif;
      cursor: pointer;
      white-space: nowrap;

      &.active {
        font-weight: bold;
      }
    }
  }

  .header-wrapper__language-dropdown-wrapper {
    width: 100%;
    border-top: 1px solid $light-gray-1;
  }
}

.header-wrapper--without-shadow {
  box-shadow: unset;
}

@media only screen and (max-width: $small-screen) {
  .header-wrapper {
    .navigation-wrapper {
      margin-left: 4.5rem;

      button,
      a {
        font-size: $small;
        margin-right: 1.875rem;
      }
    }
  }
}

@media only screen and (max-width: $header-mobile-breakpoint) {
  .header-wrapper {
    height: $header-height-mobile;
    padding: 10px $content-padding-mobile;
    flex-direction: row-reverse;

    .logo {
      height: 40px;
    }

    .common-logo {
      margin: 0 auto;
      align-items: center;
      display: flex;
    }

    .humburger-menu {
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }

    .humburger-menu:hover {
      cursor: pointer;
      opacity: 0.9;
    }

    .hamburger-menu__user-avatar {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
    }

    .menu-wrapper {
      position: fixed;
      top: $header-height-mobile;
      width: 100%;
      background-color: $white;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      box-shadow: 0 10px 10px 0 $shadow;
      cursor: pointer;
    }

    .navigation-wrapper {
      flex-direction: column;
      align-items: center;
      margin-left: unset;
      width: 100%;

      button,
      a {
        margin-right: unset;
        width: 100%;
        text-align: center;
        padding: 20px 0;

        &.active {
          background-color: $purple;
          color: $white;
        }
      }

      a:not(.active):hover {
        background-color: $light-purple;
      }

      button:not(:first-child),
      a:not(:first-child) {
        border-top: 1px solid $light-gray-1;
      }
    }
  }
}

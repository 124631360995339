@import "../../../../../constants";

.user-auth-info {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: PoppinsSans, sans-serif;
  font-size: $xsmall;
  text-align: center;
  color: var(--primary-fill);

  .user-auth-info__provider-icon {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-right: 0.125rem;
  }
  .user-auth-info__provider-icon--phone {
    width: 1.25rem;
    height: 1.25rem;
    color: var(--primary-fill);
  }
}

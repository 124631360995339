@import "../../../../../../styles/sizes";

.container {
  display: flex;
  flex-direction: column;
}

.tabNavigationContainer {
  margin-top: 0.875rem;
  margin-left: 0;
  padding-right: 0;

  @include desktop {
    max-width: var(--main-content-mw);
    padding-left: 0;
  }

  @include only-laptop {
    max-width: var(--main-content-mw);
    padding-left: 0;
  }

  @include tablet {
    margin-top: 0.375rem;
    padding-right: 1rem;
  }
}

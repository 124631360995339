@import "../../../constants";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
  cursor: pointer;
  margin-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.linkWrapper {
  display: flex;
  width: fit-content;
  color: var(--primary-text);
}

.fileExtension {
  position: absolute;
  background-color: $c-neutrals-300;
  color: $white;
  text-transform: uppercase;
}

.fileExtensionExtraSmall {
  right: 0;
  bottom: 0.25rem;
  max-height: 0.375rem;
  font-size: $xxxxsmall;
  line-height: 0.375rem;
  font-weight: 700;
  padding: 0.0625rem 0.125rem;
}

.fileExtensionSmall {
  right: 0;
  bottom: 0.59375rem;
  max-height: 0.625rem;
  font-size: $xxsmall-3;
  line-height: 0.625rem;
  font-weight: 700;
  padding: 0.1875rem 0.3125rem;
}

.fileExtensionMedium {
  right: 0;
  bottom: 0.59375rem;
  max-height: 0.625rem;
  font-size: $xxsmall-3;
  line-height: 0.625rem;
  font-weight: 700;
  padding: 0.1875rem 0.3125rem;
}

.fileExtensionCurrentUser {
  background-color: var(--primary-fill);
}

.fileInfo {
  color: $c-gray-40;
  font-size: $xxsmall;
  line-height: 0.6875rem;
  font-weight: 500;
  margin: 0;
  margin-top: 0.125rem;
}

.fileInfoCurrentUser {
  color: $c-light-gray;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:hover {
    background-color: $black-gray-4;
  }
}

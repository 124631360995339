@import "../../../../../../../../../../constants";

.text {
  margin: 0;
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
  white-space: pre-line;
  color: $c-neutrals-600;
}

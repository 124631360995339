@import "../../../../../constants.scss";

.preview-information-wrapper {
  margin-bottom: 48px;
  .title-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    .title {
      font-size: $moderate-med;
      color: #001a36;
      font-family: PoppinsSans;
      font-weight: bold;
    }
    .view-all {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: right;
      color: #001a36;
      flex: 30;
      cursor: pointer;
      position: relative;
      padding-right: 16px;
      &:hover {
        color: #7786ff;
        &::after {
          background-image: url("/icons/right-arrow-hover.svg");
        }
      }
      &::after {
        background-image: url("/icons/right-arrow.svg");
        display: inline-block;
        content: " ";
        background-size: 15px 15px;
        height: 15px;
        width: 15px;
        top: 4px;
        position: absolute;
        right: -4px;
      }
    }
  }
  .information-content {
    .item {
      min-height: 64px;
      padding: 16px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #001a36;
      border-bottom: 1px solid $light-gray-1;
      cursor: pointer;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      &:hover {
        background-color: #{$purple}0A;

        .item__right-arrow-icon {
          visibility: unset;
        }
      }

      .item__title-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .item-title {
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 90%;
      }

      .item__right-arrow-icon {
        margin-left: 0.5rem;
        visibility: hidden;
      }

      .item-bottom {
        display: flex;
        align-items: center;
        padding-top: 16px;
        .votes {
          min-width: 100px;
          .votes-wrapper {
            .progress {
              display: none;
            }
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              margin-left: 4px;
            }
            .top-votes-side {
              .for {
                font-size: 12px;
                margin-right: 16px;
              }
              .against {
                font-size: 12px;
                margin-right: 16px;
              }
            }
          }
          .count {
            display: none;
          }
        }
      }
      .discussion-count {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-left: 4px;
        }
        .count {
          font-size: 12px;
          color: $gray;
        }
      }
      .countdown {
        flex: 70;
        align-items: flex-end;
        .countdown-wrapper {
          justify-content: flex-end;
          .inner-wrapper {
            height: auto;
            width: auto;
            margin: 0;
            background: none;
            display: flex;
            align-items: center;
            img {
              width: 16px;
              height: 16px;
              margin-right: 4px;
              margin-left: 4px;
            }
            .text {
              span {
                font-size: 12px;
                font-weight: bold;
                line-height: 1.33;
                color: $gray;
              }
            }
          }
        }
      }
    }
  }
  .empty-information-wrapper {
    height: 214px;
    border-radius: 14px;
    background-color: $light-purple;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    .message {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      color: #001a36;
    }
    img {
      width: 130px;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .preview-information-wrapper {
    display: none;
  }
}

@import "../../../../../constants";

.item {
  display: inline-block;
  font-weight: 600;
  font-size: $xsmall;
  color: var(--breadcrumbs-inactive-color);
  word-break: break-word;
}
.itemLast {
  color: var(--breadcrumbs-active-color);
}
.itemLink {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@import "../../../../../../../constants.scss";
@import "../../../../../../../styles/sizes.scss";

.whitepaper-members-wrapper {
  display: flex;
  flex-direction: column;
  .whitepaper-members__members-select {
    display: flex;
    justify-content: space-evenly;
    height: 5rem;
    align-items: center;
    box-shadow: 0 0.188rem 0.625rem rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    padding-left: unset;
    > li {
      cursor: pointer;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      font-weight: bold;
      color: $light-gray-2;

      &.active {
        color: $purple-2;
      }
      &.active::after {
        content: "";
        display: block;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        background-color: $purple-2;
        position: absolute;
        top: 2rem;
      }
    }
  }
  .whitepaper-members__content {
    display: flex;
    flex-direction: column;
    .whitepaper-members__sub-title {
      font-size: $moderate-med;
      font-weight: bold;
      margin: 1rem 0;
    }
    .whitepaper-members__feature-title {
      display: flex;
      align-items: center;
      margin: 1rem 0;
      .whitepaper-members__checkmark-icon {
        margin-right: 1.5rem;
      }
    }
  }
}

@import "../../../../../../constants";

.container {
  display: flex;
  flex-direction: column;
}

.text {
  margin: 0;
}

.title {
  font-size: $moderate;
  color: $c-neutrals-600;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}
.titleRTL {
  text-align: right;
  direction: rtl;
}

.subtitle {
  margin-bottom: 0.5rem;
  font-size: $moderate;
  line-height: 120%;
  color: $c-neutrals-300;
}

.description {
  white-space: pre-line;
}

.descriptionShortened {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.descriptionLessShortened {
  -webkit-line-clamp: 20;
}

.seeMore {
  color: $c-pink-active-feed-cards;
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.image {
  max-width: 33.0625rem;
}

@import "../../../styles/mixins";

.loader-wrapper {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 50px;
  width: 50px;
  background-image: url("/icons/loader-pink.svg");
  background-repeat: no-repeat;
  background-position: center;
}

@include rtl-direction {
  .loader-wrapper {
    left: unset;
    right: 50%;
    transform: translateX(50%);
  }
}

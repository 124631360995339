@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.create-common-payment-steps-progress {
  max-width: 6.5rem;
  width: 100%;
  margin: 0.25rem auto 1.5rem;

  @include big-phone {
    margin-top: 0;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.create-common-confirmation-success {
  width: 100%;
  max-width: 23.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .create-common-confirmation-success__image {
    width: 14.75rem;
    height: 14.75rem;

    @include big-phone {
      max-width: 13.25rem;
      width: 100%;
      max-height: 13.25rem;
      height: 100%;
      margin-top: 0.625rem;
    }
  }

  .create-common-confirmation-success__title {
    margin: 2.625rem 0 1rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: bold;
    text-align: center;

    @include big-phone {
      margin: 2.5rem 0 3rem;
    }
  }

  .create-common-confirmation-success__sub-title {
    margin: 0;
    font-size: $xsmall;
    text-align: center;
  }

  .create-common-confirmation-success__buttons {
    width: 100%;
    margin: 5.25rem 0 1.625rem;
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;

    @include big-phone {
      margin: 3.125rem 0 0;
      flex-direction: column;
    }
  }

  .create-common-confirmation-success__button-wrapper {
    width: 100%;
  }

  .create-common-confirmation-success__continue-button {
    margin-left: 1rem;
    @extend .create-common-confirmation-success__button-wrapper;

    @include big-phone {
      margin-left: unset;
      margin-top: 1rem;
    }
  }
}
.create-common-confirmation-success__title-logo {
  width: 5.5rem;
  height: 2.5rem;
  margin: 0.75rem 0;
}

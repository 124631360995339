@import "../../../../../../../../constants";
@import "../../../../../../../../styles/sizes";

.icon {
  width: 1rem;
  height: 1rem;
  color: var(--primary-fill);

  @include tablet {
    width: 1.125rem;
    height: 1.125rem;
  }
}

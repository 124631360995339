@import "../../../../../constants";
@import "../../../../../styles/sizes";

.add-image-wrapper {
  width: 100%;

  .add-images-form-wrapper {
    .add-imaged-wrapper {
      display: flex;
      align-items: center;
    }

    .add-additional-information {
      display: flex;
      flex-direction: column;

      .images-wrapper {
        display: flex;

        .images-array__add-button {
          margin-top: 1rem;
        }

        .link {
          cursor: pointer;
          font-family: PoppinsSans, sans-serif;
          font-size: 1rem;
          font-weight: 600;
          line-height: 1.25;
          letter-spacing: normal;
          color: $purple;
          margin-right: 1.5rem;

          img {
            margin-right: 0.5rem;
          }
        }
      }
      .additional-content-wrapper {
        display: flex;
        align-items: flex-start;
        margin-top: 2rem;
        flex-direction: column;

        .additional-content-wrapper__delete-button {
          &:disabled {
            cursor: not-allowed;
          }
        }

        .images-preview {
          display: flex;
          flex-wrap: wrap;

          @include big-phone {
            flex-direction: column;
          }

          .img-item {
            position: relative;
            width: 10rem;
            height: 10rem;
            margin-right: 0.5rem;
            margin-bottom: 0.5rem;
            background-size: cover;
            background-position: center;
            margin-right: 0.5rem;

            @include big-phone {
              margin-right: 0;
            }

            img {
              width: 100%;
              height: 100%;
              margin-bottom: 0.5rem;
              border-radius: 0.7rem;
              object-fit: cover;
            }

            button {
              position: absolute;
              top: 0;
              right: 0;
            }
          }
        }
      }
    }
  }
}

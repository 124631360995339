.originalText {
  margin-left: 0.5rem;
  font-weight: normal;
  font-size: 0.875rem;
  font-style: italic;
}

.linkIcon {
  flex-shrink: 0;
  margin-left: 0.5rem;
}

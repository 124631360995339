@import "../../../../../constants";

.container {
  width: max-content;
  padding: 0.25rem 0.625rem;
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
  color: var(--primary-text);
  background-color: var(--secondary-background);
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.15259);
  box-sizing: border-box;
}

.arrowWrapper {
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  display: flex;
  color: var(--secondary-background);
}

.overlay {
  z-index: 10000;
  user-select: none;
}

@import "../../../../../constants";

.container {
  display: flex;
  flex-wrap: wrap;
  border-left: var(--text-editor-border);
  border-right: var(--text-editor-border);

  & > * {
    border-right: var(--text-editor-border);
    border-bottom: var(--text-editor-border);
  }
}

.bottomBorder {
  flex: 1;
  border-right: 0;
}

@import "../../../../../constants";

.custom-phone-input-native-select {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .custom-phone-input-native-select__value {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    color: inherit;
    box-sizing: border-box;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }
  }

  .custom-phone-input-native-select__arrow-icon {
    transform: rotate(90deg);
  }

  .custom-phone-input-native-select__select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
    }
  }
}

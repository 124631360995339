@import "../../../../../../../../../constants";

// based on TreeItemTrigger arrow icon button
.gap {
  // {item-arrow-pl} + {arrow-icon-button-pr} + {arrow-icon-button-width}
  width: calc(var(--item-arrow-pl) + 1.25rem + 0.5rem);
}

.image {
  background-color: $c-neutrals-300;
}

.icon {
  flex-shrink: 0;
  margin-right: var(--item-image-mr);
  color: var(--item-text-color);
}

.text {
  color: var(--primary-fill);
}

@import "../../../../../../constants";

.container {
  display: flex;
  background-color: $white;
  border-top: 0.0625rem solid $light-gray-1;
  padding: 0.6875rem var(--feed-card-ph, 1.5rem);
  justify-content: space-between;
}

.leftContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $c-neutrals-300;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    color: $c-primary-400;
    text-decoration: underline;
  }
}

.messageIcon {
  margin-top: 0.125rem;
  color: inherit;
}

.rightContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  margin: 0;
}

.messageCount {
  font-size: $small;
  line-height: 1.375rem;
  margin-left: 0.75rem;
}

.lastActivity {
  color: $c-neutrals-300;
  font-size: $xxsmall;
  font-weight: 300;
  text-align: right;
  word-break: break-word;
}

.unreadMessages {
  margin-left: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  background-color: $c-primary-300;
  border-radius: 0.625rem;
  font-size: $xxsmall;
  color: $c-neutrals-100;
}

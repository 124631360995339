@import "../../../constants";
@import "../../../styles/mixins";

.report-modal {
  max-width: 31.8125rem;
  width: 100%;
  height: 25.875rem;
  border-radius: 0.5rem;
}

.report-modal__header {
  justify-content: flex-start !important;

  .modal__title {
    font-size: $moderate-small;
  }
}

.report-modal__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  @media screen and (min-width: $mobile-breakpoint) {
    padding: 2rem 3rem !important;
  }
}

.report-modal__title {
  font-size: $xsmall;
}

.report-modal__input {
  resize: none;
  width: 100%;
  border-width: 0;
  outline: 0;
  border: 0.0625rem var(--gentle-stroke) solid;
  height: 100%;
  background-color: var(--secondary-background);
  color: var(--primary-text);
}

.report-modal__button-container {
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
}

.report-modal__button-container__button {
  max-width: 9.5rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
}

.report-modal__button-container__cancel {
  margin-right: 0.75rem;
}

.report-modal__button-container__send {
  margin-left: 0.75rem;
}

.report-modal__button-container__send__loader {
  height: 1.25rem;
  width: 1.25rem;
}

@import "../../../constants";

.button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border: 0;
  color: var(--primary-text);

  &:hover {
    cursor: pointer;
  }
}

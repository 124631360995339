@import "~react-datepicker/dist/react-datepicker.css";
@import "../../../constants";

$custom-dropdown-vertical-padding: 0.875rem;

.custom-date-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-family: PoppinsSans, sans-serif;
  font-weight: normal;
  color: var(--primary-text);

  .custom-date-picker__label {
    margin-bottom: 0.625rem;
    display: inline-block;
    font-size: $xxsmall;
    line-height: 1.32;
  }

  .react-datepicker-popper {
    z-index: $date-picker-z-index;
  }

  .custom-date-picker__date-picker-wrapper {
    position: relative;
    width: 100%;

    .react-datepicker__month-dropdown-container {
      .react-datepicker__month-read-view {
        margin-right: 1.125rem;
      }
    }

    .react-datepicker__year-dropdown {
      user-select: none;
    }

    .react-datepicker__navigation--years-upcoming::before,
    .react-datepicker__navigation--years-previous::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.5625rem;
      height: 0.5625rem;
      display: block;
      border-color: $date-picker-arrow-color;
      border-style: solid;
      border-width: 0.1875rem 0.1875rem 0 0;
    }

    .react-datepicker__navigation--years-upcoming::before {
      transform: translateX(-50%) rotate(-45deg);
    }

    .react-datepicker__navigation--years-previous::before {
      transform: translate(-50%, -100%) rotate(135deg);
    }
  }

  .custom-date-picker__date-picker {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
    height: 3rem;
    margin: 0;
    padding: $custom-dropdown-vertical-padding 1.875rem
      $custom-dropdown-vertical-padding 0.75rem;
    border-radius: 3px;
    border: 1px solid var(--gentle-stroke);
    background-color: var(--secondary-background);
    color: var(--primary-text);
    font-family: inherit;
    font-size: $xsmall;
    line-height: 1.42;
    outline: none;
    box-sizing: border-box;
    resize: none;

    &::placeholder {
      color: $light-gray-2;
    }
  }

  .custom-date-picker__calendar-icon {
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    color: var(--primary-text);
  }
}

@import "../../../../../constants";
@import "../../../../../styles/sizes";

.proposal-container-countdown-card {
  max-width: 23.875rem;
  width: 100%;
  padding: 1.625rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 3px 8px $shadow-2;
  box-sizing: border-box;
}

.proposal-container-countdown-card__time-title {
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
}

.proposal-container-countdown-card__timer {
  margin: 0.625rem 0 0.875rem;
  font-family: PoppinsSans, sans-serif;
  font-weight: 800;
  font-size: $mlarge;
  line-height: 112%;

  @include big-phone {
    margin: 0 0 0.5rem;
    font-weight: bold;
    font-size: $moderate-med;
    line-height: 120%;
  }
}

.proposal-container-countdown-card__status-title {
  font-family: PoppinsSans, sans-serif;
  font-weight: 600;
}

.proposal-container-countdown-card__status {
  margin: 1.5rem 0 0;
  padding: 0.25rem 0.5rem;
  font-size: 16px;
  line-height: 125%;
  color: $white;
  background-color: $green;
  border-radius: 0.25rem;

  @include big-phone {
    margin-top: 0.5rem;
  }
}
.proposal-container-countdown-card__status--red {
  background-color: $red;
}

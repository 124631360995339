@import "../../../../../../../../../constants";

.container {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  color: var(--highlight);
  overflow: hidden;
}

.image {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.name {
  padding: 0.375rem 0.75rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $moderate-xsmall;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemsButton {
  color: inherit;
}

.itemsIcon {
  transform: rotate(90deg);
}

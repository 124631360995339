@import "../../../../../../../../../../styles/mixins";
@import "../../../../../../../../../../styles/sizes";

.form {
  display: flex;
  flex-direction: column;
}

.discussionForm {
  margin-top: 1.5rem;
}

.buttonsContainer {
  margin-top: 2.5rem;
}

.buttonsWrapper {
  @include flex-list-with-gap(2rem);
}

.button {
  max-width: 10.625rem;
  width: 100%;

  @include tablet {
    max-width: unset;
  }
}

@import "../../../constants";
@import "../../../styles/mixins";

.share-modal__wrapper {
  box-shadow: 0 0.25rem 1rem 0 $shadow;
  max-width: 36.25rem;
  filter: drop-shadow(0 0.6rem 1.3rem rgba(0, 0, 0, 0.25));

  &.mobile {
    min-width: 13.5rem;
  }

  .share-modal__wrapper-header {
    padding-bottom: unset;
  }

  .share-modal__wrapper-content-mobile {
    padding: 0 0.5rem 0.5rem;
  }

  .share-modal_title {
    font-family: Lexend, sans-serif;
    align-self: baseline;
  }

  .share-modal__wrapper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.5rem 0.5rem;

    &.desktop {
      .share-modal_title {
        font-size: $moderate-med;
      }
    }

    &.mobile {
      .share-modal_title {
        font-size: $small;
      }
    }

    .share-modal_copy-link {
      max-width: 25.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }

    .share-modal__copy-button {
      border-radius: 0.5rem;
      transition: background-color 0.2s;

      &:active {
        background-color: $light-gray-1;
      }
    }

    .share-modal_copy-link-text {
      margin-left: 0.875rem;
      font-size: $xsmall;
      color: $purple-2;
      text-decoration: underline;
      color: var(--primary-fill);
      text-decoration: none;
    }
  }
}

@include rtl-direction {
  .share-modal_title {
    direction: rtl;
  }
}

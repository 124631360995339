@import "../../../../constants";

.custom-toggle-button-group {
  display: flex;
  flex-direction: column;
  font-family: PoppinsSans, sans-serif;
  font-weight: normal;
  font-size: $xsmall;
  color: $secondary-blue;

  .custom-toggle-button-group__label {
    margin-bottom: 8px;
    font-size: $xxsmall;
    line-height: 1.42;
  }

  .custom-toggle-button-group__buttons {
    display: flex;
  }
  .custom-toggle-button-group__buttons--vertical {
    flex-direction: column;
  }
}

@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.funds-allocation-configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.funds-allocation-configuration__stage-name {
  margin-top: 0.75rem;

  @include big-phone {
    margin-top: 1.5rem;
  }
}

.funds-allocation-configuration__avatar-icon {
  flex-shrink: 0;
}

.funds-allocation-configuration__form {
  max-width: 36.5rem;
  width: 100%;
}

.create-funds-allocation__currency {
  width: 48%;

  &:last-child {
    margin-bottom: 0;
  }
}

.funds-allocation-details__type-dropdown {
  z-index: $dropdown-z-index + 1;
  width: 48%;

  @include big-phone {
    width: 100%;
  }
}

.funds-allocation-configuration__modal-footer {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.funds_allocation__links-array-item {
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}
.file-upload-button {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  margin-bottom: 1.5rem;
}
.funds-input-row {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}
.recipient-selection__toggle-button-group {
  margin-top: 1.5rem;
  font-size: $small;
}

@import "../../../../../constants";
@import "../../../../../styles/sizes";

.supporters-page-payment-step__error {
  font-size: $small;

  @include big-phone {
    text-align: center;
  }
}

@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.update-rules-review {
  width: 100%;
  max-width: 36.5rem;
  margin: 0 auto;
  word-break: break-word;

  .update-rules-review__image-slider {
    margin-bottom: 1.813rem;

    @include big-phone {
      margin: 0 -1.5rem 2rem;
    }
  }

  .update-rules-review__main-info {
    @include big-phone {
      margin-bottom: 2rem;
    }
  }

  .update-rules-review__separator {
    margin: 1rem 0;
  }

  .update-rules-review__section {
    margin-bottom: 1.25rem;
  }

  .update-rules-review__section-title {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-family: PoppinsSans, sans-serif;
    font-size: $moderate;
    font-weight: bold;
    line-height: 1.22;

    @include big-phone {
      font-size: $small;
    }
  }

  .update-rules-review__section-description {
    margin: 0;
    font-size: $xsmall;
    line-height: 1.42;
  }
  .update-rules-review__section-description--bold {
    font-weight: bold;
  }

  .update-rules-review__links-section-title {
    @extend .update-rules-review__section-title;
    margin-bottom: 0;

    @include big-phone {
      margin-bottom: 0.5rem;
      font-size: $xsmall;
    }
  }

  .update-rules-review__rules {
    margin-bottom: 1.5rem;
  }

  .update-rules-review__additional-info-container {
    margin: 1.5rem 0 0;
    padding: 1rem 1.5rem;
    border-radius: 0.875rem;
    background-color: $purple-alpha-10;

    @include big-phone {
      padding: 0.5rem 1rem;
    }
  }

  .update-rules-review__additional-info-text {
    font-size: $xsmall;
    line-height: 1.43;
    text-align: center;
    color: $blue-2;
  }

  .update-rules-review__additional-info-text--bold {
    font-weight: bold;
  }

  .update-rules-review__submit-button-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 1.75rem;

    @include big-phone {
      padding: 1.5rem 0 0;
    }
  }
}

@import "../../../../../../constants.scss";
@import "../../../../../../styles/sizes";

.add-bank-details-wrapper {
  display: flex;
  align-items: center;
  height: 40rem;
  flex-direction: column;

  .add-bank-details-title {
    font-family: PoppinsSans, sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.33;
    text-align: center;
    margin-top: -1.5rem;
  }

  .add-bank-details-description {
    margin-top: 2rem;
    font-size: 0.875rem;
    line-height: 1.43;
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid $light-gray-1;
    margin-bottom: 2rem;
  }

  .add-bank-details-form {
    flex: 1;
    max-width: 43rem;
    width: 100%;
    display: flex;
    justify-content: center;

    @include big-phone {
      max-width: unset;
    }

    .add-bank-details-form__form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .section {
      display: flex;
      .field {
        width: 100%;
        margin-right: 1rem;
      }
    }
    .files-upload-wrapper {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      .add-photo-id {
        margin-bottom: 1.5rem;
      }
    }

    .add-bank-details-form__label {
      font-size: $xsmall;
    }

    .add-bank-details-form__date-picker {
      max-width: 8.25rem;
      margin-right: 1rem;

      @include big-phone {
        max-width: unset;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    .save-button {
      display: block;
      margin: auto;
      width: 11rem;
      margin-bottom: 1rem;
    }
    .error {
      color: $red;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .add-bank-details-form__contact-info-general-data {
    margin-top: 1rem;

    @include big-phone {
      margin-top: 0;
    }
  }
}

.add-bank-details-form__dropdown {
  z-index: 1;
}

@include big-phone {
  .add-bank-details-wrapper {
    height: auto;
    width: 100%;
    .add-bank-details-form {
      width: 100%;
      form {
        width: 100%;
      }
      .section {
        flex-direction: column;
        .field {
          margin-right: unset;
          margin-bottom: 1rem;
        }
      }
      .save-button {
        width: 100%;
      }
    }
  }
}

@import "../../../constants";

.menu-button {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--tertiary-fill);

  &:hover {
    background-color: var(--hover-fill);
  }

  &.menu-button--with-border {
    border: 1px solid var(--gentle-stroke);
  }
}

.menu-button--arrow {
  width: 1.5rem;
  height: 1.5rem;
}

.menu-button__icon {
  width: 1.5rem;
  height: 1.5rem;
}

.arrow__icon {
  transform: rotate(90deg);
  color: $c-neutrals-300;
}

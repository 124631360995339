@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.modal {
  max-width: 39.125rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.image {
  width: 12.5rem;
  height: 12.5rem;
  margin-bottom: 1.5rem;

  @include tablet {
    max-width: 10rem;
    width: 100%;
    max-height: 10rem;
    height: 100%;
  }
}

.title {
  margin: 0 0 0.5rem;
  font-family: Lexend, sans-serif;
  font-weight: normal;
  font-size: $moderate-med;
  letter-spacing: 0.02em;
}

.description {
  max-width: 20.5rem;
  margin: 0;
  font-size: $small;
  line-height: 150%;
  color: $c-neutrals-300;
}

.button {
  margin-top: 1.5rem;
}

@import "../../../../../../../styles/sizes";

.modalHeader {
  justify-content: unset !important;
}

.rootCircleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2rem 0;
  min-height: 3rem;

  @include big-phone {
    flex-direction: column;
    margin: 2rem;
    align-items: flex-start;
  }
}

.dropdown {
  margin: 0 1rem;
}

.rowContentWrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: inherit;

  @include big-phone {
    margin: 1rem 0;
    align-items: center;
  }
}

.rowContentText {
  @include big-phone {
    margin-left: 2rem;
  }
}

.parentSpaceNameLabel {
  margin: 0 0.5rem;
}

.applyButton {
  margin-left: auto;
  margin-top: 5rem;
}

@import "../../../../../../../../../constants";
@import "../../../../../../../../../styles/sizes";

.item {
  --item-color: #{$c-gray-40};

  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-size: $mobile-title;
  font-weight: normal;
  color: var(--item-color);
  background-color: transparent;
  border: none;
  text-align: center;
  text-decoration: none;
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    --item-color: #{var(--primary-text)};
  }
}
.itemActive {
  --item-color: var(--highlight);
}
.itemDisabled {
  --item-color: #{$c-neutrals-300};

  cursor: not-allowed;
}

.tooltipContent {
  max-width: 20rem;
  z-index: 3;
}

.textWrapper {
  display: flex;
  align-items: center;
}

.text {
  margin-left: 0.5rem;
}

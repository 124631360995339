@import "../constants";

/**
 * .container {
 *   background-color: green;
 *
 *   @include big-phone {
 *     background-color: blue;
 *   }
 *
 *   @include phone-portrait {
 *     background-color: red;
 *   }
 * }
 *
 * The order of mixins usage is important.
 * We should use them descending (desktop -> laptop -> big-phone -> phone-portrait).
 */
@mixin phone-portrait {
  @media only screen and (max-width: $mobile-portrait) {
    @content;
  }
}

@mixin big-phone {
  @media only screen and (max-width: $mobile-breakpoint) {
    @content;
  }
}

@mixin small-laptop {
  @media only screen and (max-width: $small-laptop) {
    @content;
  }
}

@mixin viewport-breakpoint($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

/* size related mixins v2 */

@mixin phone {
  @media only screen and (max-width: $phone-breakpoint) {
    @content;
  }
}

@mixin only-phone-orientated {
  @media only screen and (min-width: #{$phone-breakpoint + 1}) and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin phone-orientated {
  @media only screen and (max-width: $tablet-breakpoint) {
    @content;
  }
}

@mixin only-tablet {
  @media only screen and (min-width: #{$tablet-breakpoint + 1}) and (max-width: $laptop-breakpoint) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $laptop-breakpoint) {
    @content;
  }
}

@mixin only-laptop {
  @media only screen and (min-width: #{$laptop-breakpoint + 1}) and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin laptop {
  @media only screen and (max-width: $desktop-breakpoint) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: #{$desktop-breakpoint + 1}) {
    @content;
  }
}

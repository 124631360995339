@import "../../../../../constants";

.item {
  --item-color: var(--primary-text);
  --item-bg-color: var(--tertiary-fill);
  --item-border: 0.0625rem solid var(--gentle-stroke);

  margin: 0;
  padding: 1.125rem 1.5rem;
  display: flex;
  text-decoration: none;
  color: var(--item-color);
  background-color: var(--item-bg-color);
  border: var(--item-border);
  border-bottom: 0;
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
  font-size: $small;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s;
  user-select: none;

  &:first-child {
    border-top-left-radius: var(--items-br);
    border-top-right-radius: var(--items-br);
  }

  &:last-child {
    border-bottom: var(--item-border);
    border-bottom-left-radius: var(--items-br);
    border-bottom-right-radius: var(--items-br);
  }

  &:hover {
    --item-bg-color: var(--hover-fill);
  }
}

.itemWithWarning {
  --item-color: #{$c-error-300};

  &:hover {
    --item-color: #{$c-error-300};
  }
}

.iconWrapper {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

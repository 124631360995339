@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.create-proposal-stage-name {
  flex-shrink: 0;
  max-width: 10rem;
  width: 100%;
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $xsmall;
  text-align: center;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
.create-proposal-stage-name--light-pink {
  background-color: var(--secondary-background);
}

.create-proposal-stage-name--light-yellow {
  background-color: #fff8d2;
}

.create-proposal-stage-name__text {
  margin-left: 0.625rem;
}

.create-proposal-stage-name__separator {
  max-width: 33.5rem;
  margin: 1.5rem 0;
}

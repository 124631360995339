@import "../../../../../../constants.scss";

.circle-select {
  height: 6rem;
}

.circle-select__no-option-text {
  margin: 0;
  font-size: $small;
  color: $light-gray-2;
  text-align: center;
}

.circle-select__limited-circles-title {
  font-size: 14px;
  color: $secondary-blue;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.circle-select__option {
  height: 2rem;
  cursor: pointer;
  justify-content: center;
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: $light-gray-9;
  }
}

.circle-select__option--disabled {
  background-color: $blue-button-disabled-second-option !important;
  cursor: not-allowed;
}

.circle-select--disabled {
  cursor: not-allowed !important;
}

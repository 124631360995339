@import "../../../../../../constants";

$two-columns-breakpoint: 1270px;
$regular-columns-breakpoint: 1270px;
$two-columns-breakpoint-2: 640px;
$smallest-breakpoint: 380px;

$regular-columns-grid-gap: 0 0.5rem;
$two-columns-grid-gap: 1rem 0.5rem;

.container {
  --columns-amount: 4;
  --columns-gap: #{$regular-columns-grid-gap};

  margin-top: 0.25rem;
  padding: 1.5rem 2.25rem 1.25rem 2rem;
  display: grid;
  grid-template-columns: repeat(var(--columns-amount, 4), auto);
  grid-gap: var(--columns-gap);
  background-color: var(--primary-background);
  border: 0.0625rem solid var(--gentle-stroke);
  box-sizing: border-box;
}
.containerCol4 {
  --columns-amount: 4;
  --columns-gap: #{$regular-columns-grid-gap};

  padding-left: 2rem;
  padding-right: 2.25rem;
}
.containerCol2 {
  --columns-amount: 2;
  --columns-gap: #{$two-columns-grid-gap};

  padding-left: 1rem;
  padding-right: 1rem;
}
.containerCol1 {
  --columns-gap: #{$two-columns-grid-gap};

  padding-left: 1rem;
  padding-right: 1rem;
  grid-template-columns: 1fr;
}

.countdown {
  display: inline-block;
}
.countdownNarrow {
  width: 5.75rem;
}
.countdownWide {
  width: 10.25rem;
}
.countdownWidthUnset {
  width: unset;
}

.text {
  margin: 0;
}

.timeToVote {
  font-size: $moderate-med;
  font-family: Lexend, sans-serif;
  font-weight: 300;
  color: $c-neutrals-600;
}

.timeAgoClassName {
  font-size: $moderate;
}

.voters {
  max-width: 8.5rem;
  font-family: Lexend, sans-serif;
  font-size: $xsmall;
  line-height: 120%;
  color: $c-neutrals-600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.votersNonShortened {
  max-width: unset;
  text-overflow: unset;
  white-space: unset;
  overflow: unset;
  word-break: break-word;
}

.votingStatus {
  color: $c-green-300;
  background-color: $c-green-100;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;
  line-height: 100%;

  &:hover {
    background-color: $c-green-100;
  }
}
.votingStatusFailing {
  color: $c-error-300;
  background-color: $c-error-100;

  &:hover {
    background-color: $c-error-100;
  }
}

@import "../../../../../../../../../constants";
@import "../../../../../../../../../styles/sizes";

.container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  color: $c-gray-40;
  list-style-type: none;
  overflow-x: auto;
  overflow-y: hidden;
}

@include tablet {
  .container {
    overflow: hidden;
  }
}

@import "../../../../constants.scss";
@import "../../../../styles/sizes";

$big-phone-breakpoint: 460px;
$phone-breakpoint: 415px;

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 0.875rem;
  border: 0.0625rem var(--gentle-stroke) solid;
  width: 85%;
  background-color: var(--secondary-background);
  padding: 0.5rem 1rem;
}

.input {
  resize: none;
  width: 100%;
  border-width: 0;
  outline: 0;
  padding-right: 1.75rem;
  word-break: break-word;
}

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 0.75rem;
  height: 2.125rem;
  margin-bottom: 0.25rem;
}

.button {
  font-size: $xsmall;
  border-radius: 0.5rem;
  padding: 0.46875rem 0.8125rem;
  height: fit-content;
}

.cancelButton {
  margin-right: 0.5rem;
}

.saveButton {
  margin-left: 0.5rem;
  width: 3.6875rem;
}

.loader {
  height: 1.25rem;
  width: 1.25rem;
}

.emojiContainer {
  z-index: 10;
}

.pickerContainer {
  position: absolute;
  bottom: 3.25rem;
  right: 0.5625rem;

  @media only screen and (max-width: $big-phone-breakpoint) {
    right: -1.5rem;
  }

  @media only screen and (max-width: $phone-breakpoint) {
    right: -3rem;
  }
}

@import "../../../../../constants";

.imageContainer {
  margin: 0.125rem 0.25rem 0;
}

.mainContent {
  width: 100%;
  max-height: 24rem;
  object-fit: cover;
  box-sizing: border-box;
  border-radius: 0.25rem;
}

.leftItem {
  margin-right: 0.15625rem;
  margin-left: 0;
}

.imageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@import "../../../../../../../../../constants.scss";
@import "../../../../../../../../../styles/sizes.scss";

.whitepaper-proposal-card-wrapper {
  display: flex;
  flex-direction: column;
  .whitepaper-proposal-card__top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin: 0.5rem 0;
    font-size: $moderate-med;
    font-weight: bold;
    &:hover {
      opacity: 0.9;
    }
    .collapsed {
      transform: rotate(0deg);
      &.expanded {
        transform: rotate(180deg);
      }
    }
  }
  .whitepaper-proposal-card__content {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    box-shadow: 0rem 0.1875rem 0.5rem rgba(0, 26, 54, 0.08);
    border-radius: 0.875rem;
    margin-bottom: 1.5rem;
    color: $light-gray-2;
    .whitepaper-proposal-card__content-sub-title {
      font-weight: bold;
      margin-top: 1rem;
    }
    .whitepaper-proposal-card__voters-wrapper {
      display: flex;
      margin: 1rem 0;
      .whitepaper-proposal-card__voter {
        margin-left: 1rem;
      }
    }
    .whitepaper-proposal-card__content-sub-title:first-child {
      margin-top: unset;
    }
    .whitepaper-proposal-card__content-duration-wrapper {
      display: flex;
      .whitepaper-proposal-card__voting {
        margin-left: 5rem;
        color: $purple;
      }
    }
    .arrow {
      margin-right: 0.5rem;
      &.object {
        margin-left: 2rem;
      }
    }
  }
}

@import "../../../constants";
@import "../../../styles/sizes";

.container {
  --main-mw: 100%;
  --main-pl: unset;
  --sb-max-width: unset;
  --sb-width: 18.75rem;
  --sb-content-max-width: 100%;
  --sb-content-width: 100%;
  --sb-content-pb: 0;
  --sb-bg-color: #{$c-shades-white};
  --sb-easing: cubic-bezier(0.16, 1, 0.3, 1);
  --sb-transition-duration: 0.6s;
  --page-bg-color: #{$c-shades-white};
  --scroll-bg-color: #{$c-shades-white};
  --scroll-thumb-color: var(--gentle-stroke);
  --layout-tabs-height: 0rem;

  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg-color);
  box-sizing: border-box;

  @include tablet {
    --sb-max-width: 100%;
    --sb-width: 100%;
    --sb-content-width: 87%;
    --sb-shadow: 0.125rem 0 0.375rem #{$c-sidebar-shadow};
    --layout-tabs-height: 4rem;
  }
}
.containerWithSidenav {
  --main-mw: calc(120rem + var(--sb-h-indent, 0));
  --main-pl: calc(var(--sb-width) + var(--sb-h-indent, 0));

  @include tablet {
    --main-mw: 100%;
    --main-pl: unset;
  }
}

.sidenavContentWrapper {
  border-left: 0.0625rem solid var(--gentle-stroke);
  border-right: 0.0625rem solid var(--gentle-stroke);

  @include tablet {
    border: 0;
  }
}

.sidenavContent {
  margin: 0 auto;
  max-width: 25rem;
}

.main {
  flex: 1;
  max-width: var(--main-mw);
  padding-left: var(--main-pl);
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg-color);
  box-sizing: border-box;
}

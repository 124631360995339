@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.monthly-success-my-contributions-stage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-success-my-contributions-stage__image {
  width: 10rem;
  height: 10rem;

  @include big-phone {
    max-width: 7.25rem;
    width: 100%;
    max-height: 7.25rem;
    height: 100%;
  }
}

.monthly-success-my-contributions-stage__title {
  margin: 3.75rem 0 5.25rem;
  font-family: NotoSerifSC, serif;
  font-size: $moderate-small;
  font-weight: bold;
  text-align: center;
  color: $secondary-blue;

  @include big-phone {
    margin: 2.5rem 0;
    font-family: PoppinsSans, sans-serif;
  }
}

.monthly-success-my-contributions-stage__button {
  max-width: 11.25rem;

  @include big-phone {
    max-width: 100%;
    margin-bottom: 1rem;
  }
}

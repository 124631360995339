.projectsTree {
  padding: 1rem var(--sidenav-content-ph) 0;
  box-sizing: border-box;
}

.loader {
  margin: 1rem auto 0;
  display: block;
}

.createCommonButtonWrapper {
  padding: 0 var(--sidenav-content-ph) 1rem;
}

.createCommonButton {
  width: 100%;
  padding-left: 2.125rem;
  padding-right: 0.875rem;
}

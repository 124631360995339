@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.messageListTransitionContainer {
  position: unset;
}

.messageList {
  scrollbar-width: none;
  padding: 0;
  margin: 0;

  & > li {
    list-style: none;
  }
}

.dateTitle {
  padding: 0.0625rem 0.1875rem;
  border-radius: 0.25rem;
  background-color: var(--secondary-background);
  font-size: $xxsmall;
  text-align: center;
  color: var(--primary-fill);
  margin: 0 auto;
  width: fit-content;
  margin: 1.3125rem auto 1rem;
}

.noMessagesText {
  align-self: flex-start;
  max-width: 17.5rem;
  padding: 0.5rem 1rem;
  font-size: $xsmall;
  line-height: 143%;
  text-align: left;
  background-color: var(--secondary-background);
  color: var(--primary-text);
  border-radius: 0.9375rem;
  box-sizing: border-box;
}

.newMessageLoaderWrapper {
  width: 100%;
  height: 6rem;
}

.veryFirstMessage {
  margin-top: 5rem;
}

.pendingMessagesList {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.initialLoaderContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

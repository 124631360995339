@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

$payment-iframe-height: 26.5rem;
$payment-iframe-height-mobile: 42rem;

.create-common-payment {
  max-width: 36.5rem;
  width: 100%;
  min-height: 25rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PoppinsSans, sans-serif;

  @include big-phone {
    min-height: unset;
  }

  .create-common-payment__sub-title {
    margin: 0 0 0.25rem;
    font-family: inherit;
    font-weight: bold;
  }

  .create-common-payment__sub-text {
    max-width: 23.7rem;
    width: 100%;
    margin: 0;
    font-size: $xsmall;
    text-align: center;
    color: $light-gray-2;
  }

  .create-common-payment__amount {
    color: $purple;
  }

  .create-common-payment__separator {
    margin: 1.5rem 0 0.625rem;
  }

  .create-common-payment__content {
    flex: 1 0;
    position: relative;
    max-width: 25rem;
    width: 100%;
    min-height: $payment-iframe-height;
    display: flex;

    @include big-phone {
      min-height: 3.125rem;
    }
  }

  .create-common-payment__loader {
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    @include big-phone {
      top: unset;
      transform: translateX(-50%);
    }
  }
}

.create-common-payment__continue-button-wrapper {
  padding: 1.75rem 0;
  display: flex;
  justify-content: center;

  .create-common-payment__continue-button {
    min-width: 11.25rem;

    @include big-phone {
      min-width: unset;
    }
  }

  @include big-phone {
    padding: 1.5rem 0;
  }
}

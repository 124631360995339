@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.discussion-detail-modal-wrapper {
  width: 75wh;
  height: 80vh;
  display: flex;
  .discussion-details-container {
    flex: 40;
    margin-right: 48px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    margin-top: 4rem;
    ::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      background: transparent; /* Optional: just make scrollbar invisible */
    }
    .user-and-title-container {
      justify-content: center;
      display: flex;
      flex-direction: column;
      .countdown-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        .inner-wrapper {
          width: 315px;
          height: 32px;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
          background-color: #fff3d8;
          display: flex;
          margin-bottom: 24px;
          align-items: center;
          justify-content: center;
          .clock-icon {
            width: 15px;
            margin-right: 8px;
          }
          .text {
            font-size: 14px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            letter-spacing: normal;
            color: #ffae26;
          }
        }
      }
      .owner-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        .owner-icon-wrapper {
          width: 32px;
          height: 32px;
          position: relative;
          margin-bottom: 8px;
          img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
        .owner-name-and-days-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          .owner-name {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            color: $secondary-blue;
          }
          .days-ago {
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            color: $light-gray-2;
          }
        }
      }
      .discussion-information-wrapper {
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        padding: 0 30px;

        .discussion-name {
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          color: #001a36;
          margin-bottom: 24px;
          overflow-wrap: break-word;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .description-container {
      margin-top: 1.5rem;
    }
  }
  .chat-container {
    flex: 60;
    margin: -30px;
    margin-top: 0;
    overflow-x: hidden;
  }
}

@include big-phone {
  .discussion-detail-modal-wrapper {
    flex-direction: column;
    height: 100%;
    .discussion-details-container {
      margin-right: unset;
      position: absolute;
      left: 0;
      z-index: 1;
      background-color: white;
      width: 100%;
      margin-top: unset;
      padding: 1.5rem;
      box-sizing: border-box;
      border-radius: 1.25rem;
      box-shadow: 0 2px 4px 0 $shadow-1;
      min-height: 7.5rem;
      .user-and-title-container {
        flex-direction: column-reverse;
        .owner-wrapper {
          flex-direction: row;
          align-self: flex-start;
          .owner-icon-wrapper {
            margin-bottom: unset;
            margin-right: 0.5rem;
          }
          .owner-name-and-days-container {
            .days-ago {
              width: 100%;
              text-align: left;
            }
          }
        }
      }
      .expand-btn-container {
        width: 100%;
        display: flex;
        justify-content: center;
        .expanded {
          transform: rotate(0deg);
        }
        .collapsed {
          transform: rotate(180deg);
        }
      }
    }
    .chat-container {
      margin-top: 10rem; // TODO: Add dependency with top card for mobile
    }
  }
}

@import "../../../../constants";
@import "../../../../styles/mixins";
@import "../../../../styles/sizes";

.modalHeader {
  min-height: 0 !important;
}

.modalContent {
  padding: 0 !important;
}

.modalChildren {
  height: 100%;
  width: 100%;
  padding-top: 0.5rem;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem 0 1.3125rem;
  box-sizing: border-box;
  margin-bottom: 0.375rem;
}

.headerContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.backButtonIcon {
  margin-right: 0.25rem;
}

.commonName {
  margin: 0;
}

.title {
  margin: 0 0.0625rem;
  width: 100%;
  padding: 1.25rem 1.125rem;
  border-bottom: 0.0625rem solid $c-neutrals-200;
  font-size: $mobile-title;
  font-family: Lexend, sans-serif;
  text-align: start;
  line-height: 120%;
  color: $c-neutrals-600;
  box-sizing: border-box;
}
.titleRTL {
  text-align: right;
  direction: rtl;
}

.image {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  border-radius: 0.5rem;
  object-fit: cover;
  box-sizing: border-box;
}

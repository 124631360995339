@import "../../../../styles/sizes";

.container {
  display: none;
  background-color: var(--secondary-background);

  @include tablet {
    z-index: 2;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
}

.emptyBlock {
  width: 1.75rem;
}

.backIconButton {
  padding: 0.3125rem;
}

.backIcon {
  flex-shrink: 0;
  width: 0.875rem;
  height: 0.875rem;
}

@import "../../../constants";
@import "../../../styles/sizes";

$modal-action-size: 2rem;
$modal-border-radius: 0.75rem;
$modal-mobile-pop-up-border-radius: 1.5rem;
$modal-padding: 1.875rem;
$modal-mobile-padding: 1.5rem;

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $modal-z-index;
  width: 100vw;
  height: 100vh;
  background-color: var(--drop-shadow-secondary);
  opacity: 0.5;
}

.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $modal-z-index;
  overflow: hidden;
  outline: 0;
  box-sizing: border-box;
  backdrop-filter: blur(2px);
  color: var(--primary-text);

  @include big-phone {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  z-index: 100;
  position: relative;
  max-width: 900px;
  max-height: 90vh;
  margin: 5vh auto;
  box-sizing: border-box;
  border-radius: $modal-border-radius;
  overflow: hidden;

  &.notification {
    max-width: 23.5rem;
  }

  &.discussions,
  &.proposals,
  &.history {
    max-width: $element-max-width;
  }

  .modal__header-wrapper {
    z-index: 2;
    flex: 0 0;
    transition: box-shadow 0.1s;
  }
  .modal__header-wrapper--with-modal-padding {
    padding-left: $modal-padding;
    padding-right: $modal-padding;

    .modal__header {
      padding-left: $modal-action-size;
      padding-right: $modal-action-size;
    }

    @include big-phone {
      padding-left: $modal-mobile-padding;
      padding-right: $modal-mobile-padding;
    }
  }
  .modal__header-wrapper--shadowed {
    box-shadow: 0 2px 9px 0 $fixed-modal-element-shadow;
  }

  .modal__header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal__header--default-padding {
    min-height: $modal-action-size;
    padding-top: 24px;
  }
  .modal__header--with-string-title {
    padding-top: 32px;
    padding-bottom: 32px;

    @include big-phone {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }

  .modal__title {
    margin: 0;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: var(--primary-text);
  }

  .modal__action-wrapper {
    position: absolute;
    width: $modal-action-size;
    height: $modal-action-size;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto 0;

    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }

  .modal__back-wrapper {
    left: 0;
  }
  .modal__back-action {
    width: 100%;
    height: 100%;
    color: var(--primary-text);
  }

  .modal__close-wrapper {
    right: 0;
  }

  .modal__content {
    flex: 1;
    padding: 0 $modal-padding;
    overflow-y: auto;

    @include big-phone {
      padding-left: $modal-mobile-padding;
      padding-right: $modal-mobile-padding;
    }
  }
  .modal__content--without-footer {
    padding-bottom: $modal-padding;

    @include big-phone {
      padding-bottom: $modal-mobile-padding;
    }
  }
  .modal__content--without-h-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .modal__footer {
    z-index: 1;
    flex: 0 0;
  }
  .modal__footer--fixed {
    padding-left: $modal-padding;
    padding-right: $modal-padding;
    transition: box-shadow 0.1s;

    @include big-phone {
      padding-left: $modal-mobile-padding;
      padding-right: $modal-mobile-padding;
    }
  }
  .modal__footer--shadowed {
    box-shadow: 0 2px 9px 0 $fixed-modal-element-shadow;
  }
}
.modal--mobile-full-screen {
  @include big-phone {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    margin: unset;
    border-radius: unset;
  }
}
.modal--mobile-pop-up {
  @include big-phone {
    max-width: 100%;
    width: 100%;
    margin: auto 0 0;
    overflow: hidden auto;
    border-top-left-radius: $modal-mobile-pop-up-border-radius;
    border-top-right-radius: $modal-mobile-pop-up-border-radius;
    border-bottom-left-radius: unset;
    border-bottom-right-radius: unset;
  }
}
.modal--full-height {
  height: 100%;
}

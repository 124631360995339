@import "../../../../../../constants";

$border-radius: 0.625rem;

.container {
  --container-bg: #{$link-my-reply-bg};
  --title-color: var(--primary-background);
  --description-color: var(--hover-fill);
  --url-color: #{$link-my-reply};

  max-width: 100%;
  margin-bottom: 0.625rem;
  display: flex;
  flex-direction: column;
  background-color: var(--container-bg);
  border-radius: $border-radius;
  text-align: left;
  text-decoration: none;
}
.containerOtherPerson {
  --container-bg: var(--secondary-background);
  --title-color: var(--primary-text);
  --description-color: #{$editor-checkbox-inactive};
  --url-color: var(--highlight);
}

.contentWrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
}

.image {
  width: 100%;
  height: 9.5rem;
  object-fit: cover;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
}

.title {
  display: -webkit-box;
  font-size: 0.8125rem;
  font-weight: 600;
  color: var(--title-color);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description {
  display: -webkit-box;
  font-size: 0.6875rem;
  color: var(--description-color);
  white-space: pre-wrap;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.url {
  font-size: 0.6875rem;
  color: var(--url-color);
}

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: white;
    flex: 4;
    justify-content: center;
    align-items: center;
}

.loader {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.discussion-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: $secondary-blue;
    font-family: PoppinsSans, sans-serif;
  }
  .add-button {
    display: flex;
    padding: 0.5rem 1.5rem;
    border-radius: 30.5px;
    background-color: $light-gray-7;
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: $secondary-blue;
    cursor: pointer;
    width: 12.25rem;
    align-items: center;
    justify-content: center;
    font-family: PoppinsSans, sans-serif;

    img {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
    }
  }
}

.discussions-component-wrapper {
  .discussion-item-wrapper {
    padding: 40px;
    border-radius: 14px;
    box-shadow: 0 3px 8px 0 #001a3614;
    background-color: $white;
    margin-bottom: 24px;
    padding-bottom: 20px;
    position: relative;

    .discussion-top-bar {
      display: flex;
      margin-bottom: 24px;
      .img-wrapper {
        margin-right: 16px;
        width: 40px;
        height: 40px;
        position: relative;
        img {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .creator-information {
        .name {
          font-size: 16px;
          color: #001a36;
        }
        .days-ago {
          font-size: 14px;
          color: $light-gray-2;
        }
      }
    }
    .discussion-content {
      .title {
        font-size: 18px;
        font-weight: bold;
        color: #001a36;
        margin-bottom: 16px;
        cursor: pointer;
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        &:hover {
          color: $purple;
        }
      }
      .description {
        font-size: 16px;
        color: #001a36;
        margin-bottom: 16px;
        overflow-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-line;

        &.full {
          -webkit-line-clamp: unset;
          -webkit-box-orient: vertical;
          overflow: unset;
        }
      }
      .read-more {
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;
        margin-bottom: 16px;
        color: #001a36;
        &:hover {
          color: $purple;
        }
      }
    }
    .bottom-content {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .discussion-count {
        display: flex;
        .count {
          margin-left: 8px;
          font-size: 16px;
          font-weight: bold;
          color: $light-gray-2;
          display: inline-block;
        }
      }
      .view-all-discussions {
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        color: #7786ff;
        position: relative;
        padding-right: 15px;
        cursor: pointer;
        &::after {
          background-image: url("/icons/right-arrow-hover.svg");
          display: inline-block;
          content: " ";
          background-size: 16px 16px;
          height: 16px;
          width: 16px;
          top: 5px;
          position: absolute;
          right: -4px;
        }
      }
    }

    .dropdown-menu {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
  }
}

@include big-phone {
  .discussion-title-wrapper {
    .add-button {
      position: fixed;
      right: 1.5rem;
      bottom: 4.625rem;
      z-index: 10;
      box-shadow: 0 0 6px 0 $fixed-button-shadow;
      background-color: $purple;
      width: 64px;
      height: 64px;
      min-width: unset;
      border-radius: 50%;
      padding: unset;
      span {
        display: none;
      }
      img {
        margin-right: unset;
        filter: invert(1);
      }
    }
  }

  .discussions-component-wrapper {
    .discussion-item-wrapper {
      padding: 20px;
    }
  }
}

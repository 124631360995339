@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.user-details {
  width: 100%;
  max-width: 36.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: $secondary-blue;

  .user-details__form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .avatar-wrapper {
    position: relative;

    .user-details__auth-info {
      margin: 0.75rem 0 2.5rem;

      @include big-phone {
        margin-bottom: 2rem;
      }
    }
  }

  .user-details__avatar-loader {
    align-self: center;
    left: unset;
    transform: unset;
  }

  .avatar {
    position: relative;
    text-align: center;
    margin-top: 1.5rem;

    .avatar__input-file {
      display: none;
    }
  }

  .avatar__user-photo {
    width: 4.125rem;
    height: 4.125rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .edit-avatar {
    width: 1.75rem;
    height: 1.75rem;
    background-color: var(--primary-fill);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 38px;
    left: 52%;
    cursor: pointer;

    img {
      width: 0.875rem;
      height: 0.875rem;
    }
  }

  .user-details__text-field {
    width: 100%;
    text-align: left;
    z-index: 1;
  }

  .user-details__first-name {
    grid-area: firstName;
  }

  .user-details__last-name {
    grid-area: lastName;
  }

  .user-details__email {
    grid-area: email;
  }

  .user-details__country {
    grid-area: country;
  }

  .user-details__textarea {
    grid-area: intro;
    width: 100%;
  }

  .user-details__text-field-label-wrapper {
    font-size: $xsmall;
  }

  .user-details__text-field-container {
    max-width: 100%;
    display: grid;
    gap: 0.5rem 1.75rem;
    grid-auto-columns: minmax(0, 1fr);
    grid-template-areas:
      "firstName lastName"
      "email country"
      "intro intro";

    @include big-phone {
      gap: 1.5rem 0;
      grid-template-areas:
        "firstName"
        "lastName"
        "email"
        "country"
        "intro";
    }
  }

  .user-details__text-field-input {
    padding-left: 0.75rem;
    padding-right: 0.875rem;
  }

  .user-details__text-field-label {
    margin-bottom: 0.625rem;
    font-size: $xsmall;
    color: $secondary-blue;
    text-align: left;
  }

  .user-details__save-button {
    max-width: 20.5rem;
    width: 100%;
    margin: 2.5rem auto 0;

    @include big-phone {
      max-width: unset;
      margin-top: 3.5rem;
    }
  }
}

.user-details-wrapper {
  .user-details__title {
    margin: 0;
    text-align: center;

    @include big-phone {
      font-size: $moderate-med;
      font-weight: 600;
    }
  }

  .user-details__sub-title {
    margin: 0.5rem 0 0;
    font-size: $small;
    color: $light-gray-2;
    text-align: center;

    @include big-phone {
      font-size: $xsmall;
    }
  }
}

.user-details-preview {
  display: flex;
  flex-direction: column;
  text-align: left;

  .user-details-preview__name {
    margin: 0 0 0.5rem;
    height: 1.5rem;
    font-size: $moderate-small;
    font-weight: bold;
  }

  .user-details-preview__info {
    margin: 0 0 0.5rem;
    font-size: $xsmall;
    color: $light-gray-2;
    word-break: break-word;

    &:last-child {
      margin: 0;
    }
  }

  .user-details-preview__intro-wrapper {
    max-width: 20.5rem;
    margin-top: 2.5rem;

    @include big-phone {
      max-width: unset;
      margin-top: 1.5rem;
    }
  }

  .user-details-preview__intro-title {
    margin: 0 0 0.5rem;
    font-size: $moderate;
  }

  .user-details-preview__intro-content {
    margin: 0;
    font-size: $xsmall;
    white-space: pre-line;
  }
}

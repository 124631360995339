@import "../../../constants";

.button {
  --btn-w: auto;
  --btn-h: auto;
  --btn-pr: 0;
  --btn-pl: 0;
  --btn-color: inherit;
  --btn-bg-color: transparent;
  --btn-border: none;
  --btn-border-radius: 0.375rem;
  --btn-icon-size: 0.5rem;
  --btn-icon-margin: 0;

  width: var(--btn-w);
  height: var(--btn-h);
  padding: 0 var(--btn-pr) 0 var(--btn-pl);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
  font-weight: normal;
  color: var(--btn-color);
  text-align: center;
  line-height: 125%;
  background-color: var(--btn-bg-color);
  border: var(--btn-border);
  border-radius: var(--btn-border-radius);
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;
  box-sizing: border-box;

  &.buttonDisabled {
    cursor: not-allowed;
  }
}

.buttonTransparentVariant {
  --btn-color: #{$c-gray-100};
  --btn-bg-color: transparent;
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-color: #{$c-neutrals-300};
  }

  &:active {
    --btn-color: #{$c-neutrals-300};
  }

  &.buttonDisabled {
    --btn-color: #{$c-neutrals-200};
  }
}

.buttonPrimaryBlackVariant {
  --btn-color: #{$c-shades-black};
  --btn-bg-color: #{$c-gray-5};
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-color: #{$c-shades-black};
    --btn-bg-color: #{$c-gray-20};
  }

  &:active {
    --btn-color: #{$c-shades-black};
    --btn-bg-color: #{$c-gray-30};
  }

  &.buttonDisabled {
    --btn-color: #{$c-neutrals-300};
    --btn-bg-color: #{$c-gray-5};
  }
}

.buttonPrimaryGrayVariant {
  --btn-color: #{$c-neutrals-300};
  --btn-bg-color: #{$c-neutrals-100};
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-color: #{$c-neutrals-300};
    --btn-bg-color: #{$c-shades-100};
  }

  &:active {
    --btn-color: #{$c-neutrals-300};
    --btn-bg-color: #{$c-neutrals-200};
  }

  &.buttonDisabled {
    --btn-color: #{$c-neutrals-200};
    --btn-bg-color: #{$c-neutrals-100};
  }
}

.buttonPrimaryPurpleVariant {
  --btn-color: #{$c-shades-white};
  --btn-bg-color: #{$c-primary-400};
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-bg-color: #{$c-primary-300};
  }

  &:active {
    --btn-bg-color: #{$c-primary-500};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-shades-neutrals-30};
  }
}

.buttonPrimaryPinkVariant {
  --btn-color: #ffffff;
  --btn-bg-color: var(--primary-fill);
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    // TODO: need the correct color for primary pink hover
    //--btn-bg-color: #{$c-primary-300};
    opacity: 0.8;
  }

  &:active {
    --btn-bg-color: #{$c-pink-active-feed-cards};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-shades-neutrals-30};
  }
}

.buttonLightPurpleVariant {
  --btn-color: #{$c-shades-white};
  --btn-bg-color: #{$c-primary-300};
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-bg-color: #{$c-primary-200};
  }

  &:active {
    --btn-bg-color: #{$c-primary-400};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-shades-neutrals-30};
  }
}

.buttonLightPinkVariant {
  --btn-color: var(--primary-fill);
  --btn-bg-color: #{$c-pink-active-feed-cards-light};
  --btn-border-color: none;
  --btn-border: 0;

  &:hover {
    --btn-bg-color: #{$c-pink-hover-feed-cards};
  }

  &:active {
    --btn-bg-color: #{$c-pink-active-btn};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-gray-10};
  }
}

.buttonOutlineBlueVariant {
  --btn-color: #{$c-primary-400};
  --btn-bg-color: #{$c-primary-100};
  --btn-border-color: #{$c-primary-400};
  --btn-border: 0.0625rem solid var(--btn-border-color);

  &:hover {
    --btn-color: #{$c-primary-300};
    --btn-bg-color: #{$c-hover-secondary};
    --btn-border-color: #{$c-primary-300};
  }

  &:active {
    --btn-color: #{$c-primary-400};
    --btn-bg-color: #{$c-primary-200};
    --btn-border-color: #{$c-primary-400};
  }

  &.buttonDisabled {
    --btn-color: #{$c-shades-neutrals-30};
    --btn-bg-color: transparent;
    --btn-border-color: #{$c-shades-neutrals-30};
  }
}

.buttonOutlinePinkVariant {
  --btn-color: var(--primary-fill);
  --btn-bg-color: #{$c-pink-active-feed-cards};
  --btn-border-color: #{$c-pink-active-btn};
  --btn-border: 0.0625rem solid var(--btn-border-color);

  &:hover {
    --btn-bg-color: #{$c-pink-hover-feed-cards};
  }

  &:active {
    --btn-bg-color: #{$c-pink-active-btn};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-neutrals-100};
  }
}

.buttonOutlineDarkPinkVariant {
  --btn-color: var(--primary-fill);
  --btn-border-color: var(--primary-fill);
  --btn-border: 0.0625rem solid var(--primary-fill);

  &:hover {
    --btn-bg-color: var(--hover-fill);
  }

  &:active {
    --btn-bg-color: #{$c-pink-active-btn};
  }
}

.buttonWarningVariant {
  --btn-color: #{$c-error-300};
  --btn-bg-color: var(--primary-background);
  --btn-border-color: #{$c-error-300};
  --btn-border: 0.0625rem solid var(--btn-border-color);

  &:hover {
    --btn-bg-color: var(--hover-fill);
  }

  &:active {
    --btn-bg-color: #{$c-error-100};
  }

  &.buttonDisabled {
    --btn-bg-color: #{$c-neutrals-100};
  }
}

.buttonLargeSize {
  --btn-h: 3.25rem;
  --btn-pr: 1.5rem;
  --btn-pl: 1.5rem;
  --btn-icon-margin: 1rem;
}
.buttonMediumSize {
  --btn-h: 2.75rem;
  --btn-pr: 1.5rem;
  --btn-pl: 1.5rem;
  --btn-icon-margin: 1rem;
}
.buttonSmallSize {
  --btn-h: 2.25rem;
  --btn-pr: 1.5rem;
  --btn-pl: 1.5rem;
  --btn-icon-margin: 1rem;
}
.buttonXsmallSize {
  --btn-h: 2rem;
  --btn-pr: 1rem;
  --btn-pl: 1rem;
  --btn-icon-margin: 0.75rem;
}

.icon,
.leftIcon {
  width: var(--btn-icon-size);
  height: var(--btn-icon-size);
  color: inherit;
}

.leftIcon {
  margin-right: var(--btn-icon-margin);
}

.buttonLoader {
  width: 2rem;
  height: 2rem;
}

@import "../../../../constants";
@import "../../../../styles/sizes";

$max-content-width: 75rem;

.supporters-page {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $secondary-blue;
}
.supporters-page--rtl {
  direction: rtl;
}

.supporters-page__content {
  position: relative;
  max-width: $max-content-width;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;

  @include big-phone {
    padding-top: 0;
  }
}

.supporters-page__main-image-wrapper {
  margin-bottom: 1.25rem;

  @include big-phone {
    margin-bottom: 2rem;
  }
}

.supporters-page__main-image {
  width: 100%;
  height: 12.5rem;
  border-radius: 0.625rem;
  object-fit: cover;
}

.supporters-page__language-dropdown-portal-content {
  position: absolute;
  top: $header-height;
  right: $content-padding;
  left: $content-padding;

  @include big-phone {
    top: $header-height-mobile;
    right: $content-padding-mobile;
    left: $content-padding-mobile;
  }
}

.supporters-page__language-dropdown-wrapper {
  position: relative;
  max-width: $max-content-width;
  width: 100%;
  margin: 0 auto;
}

.supporters-page__language-dropdown {
  position: absolute;
  top: -3rem;
  right: -2.75rem;

  @include big-phone {
    top: 0;
    right: -1rem;
    transform: translateY(-95%);
  }
}

@import "../../../../constants.scss";

.modal {
  max-width: 37rem;
  box-shadow: 0rem 0.25rem 1rem var(--drop-shadow);
}

.title {
  font-size: $large;
  font-weight: 600;
}

.subTitle {
  font-size: $moderate-small;
  margin-bottom: 2rem;
}

.sendButton {
  width: 100%;
  margin-top: 1rem;
  border-radius: 6.25rem;
}

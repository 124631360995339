@import "../../../../constants";

.container {
  height: 4.5rem;
  margin-top: 1.75rem;
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid var(--gentle-stroke);
}

.tabs {
  flex: 1;
  align-self: flex-end;
  padding-right: 1.5rem;
}

.memberInfo {
  border-left: 0.0625rem solid var(--gentle-stroke);
}

.commonMenuButtonContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1.875rem;
  border-left: 0.0625rem solid var(--gentle-stroke);
}

.commonMenuButton {
  padding: 0.625rem;
  border-radius: 0.375rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--hover-fill);
  }
}

.commonMenuItems {
  // 0.875rem - space between menu button and bottom of its container
  top: calc(100% - 0.875rem);
}

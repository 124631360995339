@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.supporters-page-payment-details {
  display: flex;
  flex-direction: column;
}

.supporters-page-payment-details__title {
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  font-weight: 400;
  font-size: $moderate;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.supporters-page-payment-details__description {
  margin: 0 0 2.5rem;
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
  color: $light-gray-2;
}

.supporters-page-payment-details__info-block {
  margin-bottom: 2.5rem;
  padding: 1.375rem;
  display: flex;
  justify-content: space-between;
  background-color: $light-gray-4;
  border: 1px solid $light-gray-1;
  border-radius: 0.5rem;

  @include big-phone {
    flex-direction: column;
  }
}

.supporters-page-payment-details__info-block-half {
  display: flex;
  flex-direction: column;

  &:last-child {
    align-items: flex-end;

    @include big-phone {
      margin-top: 1.125rem;
      align-items: flex-start;
    }
  }
}

.supporters-page-payment-details__info-title {
  font-family: PoppinsSans, sans-serif;
  font-weight: normal;
  font-size: $moderate;
  line-height: 120%;
  letter-spacing: 0.01em;
}

.supporters-page-payment-details__info-amount {
  font-family: PoppinsSans, sans-serif;
  font-weight: bold;
  font-size: $moderate-small;
  line-height: 120%;
}

.supporters-page-payment-details__info-hint {
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
  color: $light-gray-2;
}

.supporters-page-payment-details__edit-button {
  justify-self: flex-end;

  @include big-phone {
    font-size: 0.875rem;
  }
}

.supporters-page-payment-details__pay-button {
  max-width: 12.625rem;
  margin-top: 1.5rem;

  @include big-phone {
    max-width: unset;
    margin-top: 2.5rem;
  }
}

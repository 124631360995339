@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.wallet__component-wrapper {
  .wallet__section-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: $white;
    border-radius: 1rem;
    box-shadow: 0 0.188rem 0.625rem rgba(0, 0, 0, 0.05);
  }

  .wallet__common-title-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    .wallet__common-title {
      font-size: $moderate-med;
      font-weight: bold;
      line-height: 1.8rem;
      letter-spacing: normal;
      color: $secondary-blue;
    }

    .wallet__common-name {
      font-size: $small;
      font-weight: bold;
      color: $purple-2;
      margin-top: 0.5rem;
    }
  }

  .wallet__common-balance-chart-wrapper {
    height: 10.7rem;
    margin: 1.5rem auto 0 auto;
    max-width: 65%;

    .wallet__common-balance-chart {
      display: flex;
      //FIXME
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      width: 95%;

      .balance {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $light-gray-2;
        width: 23%;

        .current {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 1rem;

          .current-balance-title {
            font-size: $xsmall;
            font-weight: 600;
            text-align: center;
          }

          .current-balance-amount {
            font-size: $small;
            font-weight: bold;
            color: $secondary-blue;
          }
        }

        .pending {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          width: 87%;

          .pending-balance-title {
            font-size: $xxxsmall;
          }

          .pending-balance-amount {
            font-weight: bold;
            font-size: $xxsmall;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 35%;
          }
        }
      }

      //FIXME: temporary hidden Common Wallet chart. Uncomment this and adjust other related styles after clearing up the DB from USD-payments and legacy Commons to which they were made
      // .common-transactions-chart-wrapper {
      //   width: 67%;
      // }
    }
  }

  .wallet__menu-wrapper {
    font-weight: bold;
    font-size: $moderate;
    width: 100%;
    height: 4.875rem;
    margin-top: 2rem;

    .wallet__menu {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      >li {
        cursor: pointer;
        list-style: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: relative;

        &.active {
          color: $purple-2;
        }

        &.active::after {
          content: "";
          display: block;
          width: 0.375rem;
          height: 0.375rem;
          border-radius: 50%;
          background-color: $purple-2;
          position: absolute;
          top: 2rem;
        }

        &:nth-child(2) {
          margin-left: 12%;
        }
      }
    }
  }

  .wallet__transactions-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .wallet__transactions-title {
      font-weight: bold;
      font-size: $moderate;
      margin: 2rem 0;
    }
  }

  .wallet__transactions-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wallet__transactions-wrapper--without-tabs {
    @include tablet {
      margin-top: 0.5rem;
      padding: 0rem 2.5rem;
    }

    @include big-phone {
      padding: 0;
    }
  }
}

@include big-phone {
  .wallet__component-wrapper {
    .wallet__component-header-wrapper {
      background-color: $light-purple;
      height: 14rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .wallet__common-title-wrapper {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }

      .wallet__common-balance-chart-wrapper {
        height: 6.25rem;
        max-width: 90%;

        .wallet__common-balance-chart {
          justify-content: center;
          width: 100%;

          .balance {
            width: 32%;
          }
        }
      }
    }

    .wallet__menu-wrapper {
      margin-top: 0;
      border-radius: 0;

      &.sticked {
        position: fixed;
        top: 60px;
        z-index: $header-z-index;
      }

      .wallet__menu {
        width: 75%;

        &.active::after {
          top: 1.7rem;
        }
      }
    }

    .wallet__transactions-wrapper {
      .transactions-list {
        width: 90%;
      }
    }
  }
}
@import "../../../../../../../../../constants";

.item {
  --bg-color: var(--primary-background);
  --item-pl-per-level: 1.125rem;
  --item-arrow-pl: var(--item-pl-per-level);
  --item-image-mr: 0.5rem;
  --item-text-color: var(--primary-text);

  height: 2.75rem;
  padding-left: calc(var(--item-pl-per-level) * (var(--tree-level, 1) - 1));
  padding-right: 0.875rem;
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  border-radius: 0.25rem;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.2s;
  overflow: hidden;

  &:hover {
    --bg-color: var(--hover-fill);
  }
}
.itemActive {
  --bg-color: #{$c-primary-200}99;

  &:hover {
    --bg-color: var(--hover-fill);
  }
}
.itemWithoutMembership {
  --item-text-color: #{$c-neutrals-300};
}
.itemDisabled {
  cursor: not-allowed;
}

@import "../../../../../../../../../../../styles/sizes";

.modal {
  max-width: 26rem;
}

.text {
  margin: 0 0 1rem;
  padding: 0;
  text-align: center;
  color: var(--primary-text);
}

.createCommonButton {
  margin-left: auto;

  @include tablet {
    margin-right: auto;
  }
}

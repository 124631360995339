@import "../../../../../../../../constants";

.modal {
  max-width: 20rem;
  width: 100%;
  max-height: 25.75rem;
  border-radius: 0;
  box-shadow: 0 0.25rem 0.9375rem rgba(0, 0, 0, 0.15259);

  :global(.modal__header-wrapper--with-modal-padding) {
    .modalHeader {
      padding: 0.875rem 0;
      justify-content: flex-start;
    }
  }

  .modalContent {
    padding: 0;
  }
}

.modalTitleWrapper {
  padding-right: 2.375rem;
  display: flex;
  flex-direction: column;
  color: $c-neutrals-600;
}

.modalTitle {
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  font-weight: bold;
  font-size: $small;
}

.modalOverlay {
  background-color: $c-shades-white;
  opacity: 0.5;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
}

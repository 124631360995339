@import "../../../../../constants";
@import "../../../../../styles/sizes";

.container {
  max-width: 52.4375rem;
  width: 100%;
  max-height: 43.125rem;
  height: 100%;
  margin: auto;

  @include big-phone {
    margin-top: unset;
  }

  & .swiper-container {
    max-height: 35rem;
    height: 100%;
    max-width: 48.8125rem;
    width: 100%;
  }

  & .swiper-wrapper {
    max-height: 35rem;
    height: 90%;
  }

  & .swiper-pagination-bullet {
    background-color: var(--primary-text);
  }

  & .swiper-pagination-bullet-active {
    background-color: $c-neutrals-300;
  }
}

.slider-wrapper {
  align-self: center;
  max-height: 35rem;
  cursor: pointer;
}

.slide-img {
  width: 100%;
  height: 100%;
  max-height: 35rem;
  object-fit: contain;
}

.arrow-wrapper {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  z-index: 2;
  color: var(--primary-text);
  height: 10rem;

  &:disabled {
    color: $gray;
  }
}

.arrow-wrapper-left {
  @extend .arrow-wrapper;
  left: 1rem;

  @include tablet {
    left: 18rem;
  }
}

.arrow-wrapper-right {
  @extend .arrow-wrapper;

  right: 1rem;

  @include tablet {
    right: 18rem;
  }
}

.arrow-icon {
  width: 2rem;
  height: 2rem;
}

.video-container {
  position: relative;
  width: fit-content;
  z-index: 3;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.video-play-button-container {
  position: absolute;
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  box-shadow: 0 0.25rem 0.5rem $shadow-2;
}

.video-play-button {
  position: absolute;
  height: 2.0625rem;
  width: 2.25rem;
  left: calc(50% + 3px);
  top: 50%;
  transform: translate(-50%, -50%);
}

.image-container {
  height: 100%;
  width: 100%;
}

.image-gallery-modal {
  .modal__content {
    padding: 0;
    padding-bottom: 2rem;
    .modal__header-wrapper {
      .modal__header--default-padding {
        margin-right: 0.5rem;
        margin-top: 0.5rem;
        padding-top: 0;
      }
    }
  }
}

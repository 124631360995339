@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.update-governance-edit-steps__modal-title-wrapper {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .update-governance-edit-steps__modal-title-dots {
    margin: 0.375rem 0;
  }

  .update-governance-edit-steps__modal-title {
    margin: 0;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    line-height: 1.38;
    text-align: center;
    color: $secondary-blue;
  }

  @include big-phone {
    height: 4rem;
  }
}

@import "../../../../constants";
@import "../../../../styles/mixins";

.custom-checkbox {
  display: flex;
  flex-direction: column;

  .custom-checkbox__wrapper {
    display: flex;
  }

  .custom-checkbox__input-wrapper {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;

    &.disabled {
      opacity: 0.5;
    }
  }

  .custom-checkbox__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0 !important;
    cursor: pointer;

    &:checked ~ .custom-checkbox__regular-icon,
    &:not(:checked) ~ .custom-checkbox__selected-icon {
      display: none;
    }

    &:focus-visible ~ .custom-checkbox__icon {
      outline: $purple auto 0.3125rem;
    }
  }

  .custom-checkbox__icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .custom-checkbox__label {
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: center;
    font-family: PoppinsSans, sans-serif;
    font-size: $xsmall;
    line-height: 1.42;
    color: var(--primary-text);
    cursor: pointer;
    user-select: none;
  }
}

@include rtl-direction {
  .custom-checkbox__label {
    margin-left: 0;
    margin-right: 0.5rem;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.title {
  font-size: $xsmall;
  color: var(--primary-text);
  margin-top: 1.125rem;
  margin-bottom: 0.5rem;
}

.field {
  border: 0.0625rem var(--gentle-stroke) solid;

  &::placeholder {
    font-size: $xsmall;
  }
}

.input {
  box-sizing: border-box;
  resize: none;
  width: 100%;
  border-width: 0;
  outline: 0;
  border: 0.0625rem var(--gentle-stroke) solid;
  height: 100%;
  min-height: 6.4375rem;
  padding: 0.75rem;
  background-color: var(--secondary-background);
  color: var(--primary-text);

  @include tablet {
    max-height: 4.25rem;
  }

  &::placeholder {
    font-size: $xsmall;
  }
}

.buttonContainer {
  margin-top: 6.4375rem;
  display: flex;
  justify-content: flex-end;
  button {
    margin: 0 0.5rem;
  }

  @include tablet {
    margin-top: 2.5rem;
    justify-content: center;
    flex-direction: column;
    button {
      margin: 0.5rem 0;
    }
  }
}

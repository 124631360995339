@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.chat-wrapper {
  padding: 32px;
  padding-top: unset;
  background-color: #f3f4ff;
  position: relative;
  box-sizing: border-box;

  .messages {
    height: 100%;
    overflow: auto;
    overscroll-behavior: contain;

    &.empty {
      display: flex;
      justify-content: center;
    }

    .message-list {
      scrollbar-width: none;
      padding: 0;
      margin: 0;

      > li {
        list-style: none;
      }

      .date-title {
        padding: 8px;
        border-radius: 15px;
        background-color: $white;
        font-size: 12px;
        text-align: center;
        color: $gray;
        margin: 0 auto;
        width: 120px;
        margin: 24px auto;
      }
    }

    .message-wrapper {
      width: 95%;

      .message {
        display: flex;
        position: relative;
        margin-bottom: 1rem;
        width: 25rem;

        .icon-wrapper {
          flex-shrink: 0;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: $white;
          background-color: #4ce2f1;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.75rem;
          position: relative;
          margin-bottom: 8px;
          overflow: hidden;

          img {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .message-text {
          padding: 0.5rem 1rem;
          border-radius: 0.875rem;
          background-color: #e0e3ff;
          flex-direction: row;
          width: 85%;
          box-sizing: border-box;
          position: relative;

          .message-name {
            font-size: $xsmall;
            font-weight: 600;
            color: #001a36;
            margin-bottom: 0.5rem;
          }

          .message-content {
            font-size: $small;
            color: #001a36;
            word-wrap: break-word;
            white-space: pre-line;

            .time-wrapper-container {
              display: flex;
              flex: 1;
              justify-content: space-between;
              margin-top: 0.6875rem;

              .time-wrapper {
                font-size: $xxxsmall;
                font-weight: bold;
                color: $light-gray-2;
                text-align: right;
              }

              .edited-time-wrapper {
                font-size: $xxsmall;
              }

              .creation-time-wrapper {
                align-self: flex-end;
                flex: 1;
              }
            }
          }
        }

        .dropdown-menu {
          position: absolute;
          top: 0;
          right: 0.5rem;
          z-index: unset;

          .custom-dropdown-wrapper__menu {
            z-index: $dropdown-z-index;
          }

          svg {
            color: $light-gray-2;
          }
        }

        .reply-message-container {
          padding: 0.375rem 1.125rem;
          background-color: #d2d8ff;
          margin-right: 2.5rem;
          margin-bottom: 0.3125rem;
          border-radius: 0.875rem;
          cursor: pointer;

          .reply-message-content {
            line-height: 1.5rem;
            max-height: 3rem;
            overflow: hidden;
          }
        }
      }

      &.highlighted {
        animation: 5s chat-msg-bg-color-change;
        border-radius: 1rem;
      }

      @keyframes chat-msg-bg-color-change {
        0% {
          background-color: $purple;
        }

        100% {
          background-color: transparent;
        }
      }

      &.last-message-with-dropdown {
        padding-bottom: 2.3rem;
      }
    }

    .new-message-loader-wrapper {
      width: 100%;
      height: 6rem;

      &.very-first-message {
        margin-top: 5rem;
      }
    }
  }

  .bottom-chat-wrapper {
    padding: 16px 30px 16px 34px;
    box-shadow: 0 -5px 5px -2px #4f5c6919;
    background-color: #ffffff;
    width: 100%;
    margin: 0 -32px 24px;
    bottom: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 12px 0;

    .text {
      font-size: 14px;
      color: $light-gray-2;
      margin-right: 5px;
      width: 100%;
      text-align: center;
    }

    .button-wrapper {
      display: flex;
      margin-left: 5px;

      .button-blue {
        height: 40px;
        padding: 8px 32px;
        border-radius: 30.5px;
        background-color: $purple;
        border: none;
        outline: none;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: $white;
        cursor: pointer;
        text-decoration: none;
        margin-right: 10px;
        font-family: PoppinsSans, sans-serif;

        &:hover {
          opacity: 0.9;
        }

        &:disabled {
          pointer-events: none;
          opacity: 0.5;
        }
      }
    }

    .message-input {
      width: 100%;
      height: 3rem;
      padding: 0.75rem 1rem 0.5rem;
      border-radius: 20px;
      background-color: #f0f2ff;
      border: none;
      outline: none;
      resize: none;
      font-size: 0.875rem;
      line-height: 1.43;
      color: $secondary-blue;
      display: flex;
      align-items: center;
      box-sizing: border-box;
    }

    .send {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 40px;
      margin-left: 16px;
      border: none;
      outline: none;
      background: white;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
  }

  .bottom-chat-wrapper__fixed {
    position: fixed;
    width: calc(100% - 9.25rem);
    left: 76px;
    margin-bottom: 0;

    @include big-phone {
      width: calc(100% - 3.875rem);
      left: 0px;
      margin-inline: 0px;
    }
  }

  .bottom-reply-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: $white;
    margin: 0px -2rem;
    padding: 0.4375rem 2rem 0.625rem;

    .bottom-reply-message-container {
      width: 100%;
      background-color: $light-gray-4;
      border-radius: 0.875rem;
      padding: 0.4375rem 0.75rem;

      .bottom-reply-message-user-name {
        font-size: $xsmall;
        line-height: 1.25rem;
        font-weight: 600;
        margin-bottom: 0.125rem;
      }

      .bottom-reply-message-text {
        font-size: $small;
        line-height: 1.5rem;
        font-weight: 400;
        margin: 0;
      }
    }

    .bottom-reply-message-close-button {
      margin-left: 1.375rem;
    }
  }

  .bottom-reply-wrapper__fixed {
    position: fixed;
    bottom: 5.25rem;
    width: calc(100% - 9.25rem);
    left: 4.75rem;
    margin-bottom: 0;

    @include big-phone {
      width: calc(100% - 3.875rem);
      left: 0px;
      margin-inline: 0px;
    }
  }
}

@include big-phone {
  .chat-wrapper {
    .messages {
      .message-wrapper {
        .message {
          width: 100%;
        }
      }
    }
  }
}

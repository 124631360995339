@import "../../../../../../../constants";

$marginToText: 0.625rem;

.container {
  display: flex;

  margin-top: 0.125rem;

  &:first-child {
    margin-top: 0;
  }
}
.containerRTL {
  direction: rtl;
}

.inputWrapper {
  position: relative;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: #{$marginToText};
  flex-shrink: 0;
}

[dir="rtl"] .inputWrapper,
.inputWrapperRTL {
  margin-left: #{$marginToText};
  margin-right: 0;
  text-align: right;
}

.input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0 !important;
  cursor: pointer;

  &:checked ~ .circleInactive,
  &:not(:checked) ~ .circleActive {
    display: none;
  }

  &:focus-visible ~ .circle {
    outline: $purple auto 0.3125rem;
  }
}

.circle {
  --circle-bg: transparent;
  --circle-border-color: #{$editor-checkbox-inactive};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--circle-bg);
  border: 0.09375rem solid var(--circle-border-color);
  border-radius: 50%;
  pointer-events: none;
  box-sizing: border-box;
}
.circleInactive {
  --circle-border-color: #{$editor-checkbox-inactive};
}
.circleActive {
  --circle-bg: #{$editor-checkbox-active};
  --circle-border-color: #{$c-shades-white};

  color: var(--circle-border-color);
  border-width: 0.0625rem;
}
.circleDisabled {
  opacity: 0.5;
}

.checkIcon {
  flex-shrink: 0;
}

.textWrapper {
  line-height: 1.125rem;
}

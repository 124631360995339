@import "../../../constants";

.list {
  --tags-gap: 1rem;

  width: calc(100% + var(--tags-gap));
  margin: calc(var(--tags-gap) * -1) 0 0 calc(var(--tags-gap) * -1);
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.item {
  margin: var(--tags-gap) 0 0 var(--tags-gap);
  padding: 0.5rem;
  font-size: $xsmall;
  line-height: 143%;
  letter-spacing: 0.02em;
  color: $c-neutrals-600;
  background-color: $c-primary-100;
  border-radius: 0.5rem;
}

@import "../../../../../../../constants";

.parentItem {
  flex-shrink: 0;
  border-bottom: 0.06rem solid var(--gentle-stroke);
}

.parentItemTrigger {
  overflow: unset;
}

.loader {
  margin: 1rem auto 0;
  display: block;
}

@import "../../../../../../../../../constants";

.container {
  flex-shrink: 0;
  position: relative;
  margin-right: 0.625rem;
  margin-left: auto;
  color: $c-neutrals-600;
}

.icon {
  flex-shrink: 0;
  color: $c-neutrals-300;
}

.iconActive {
  color: var(--primary-text);
}

.changeLabel {
  color: var(--primary-fill);
  font-size: $mobile-title;
  font-family: PoppinsSans, sans-serif;
}

.menuItems {
  width: 15.625rem;
  max-height: 23rem;
  overflow-y: auto;
}

.menuItem {
  padding: 1.125rem 1.5rem;
  display: flex;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
}

.menuItemForCommonCreation {
  color: var(--primary-fill);
}

.menuItemActive {
  --item-bg-color: var(--hover-fill);
}

.menuItemText {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.checkIcon {
  flex-shrink: 0;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.5rem;
}

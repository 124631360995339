@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.container {
  --title-color: var(--primary-text);

  min-height: 3.0625rem;
  max-height: 3.75rem;
  padding: 0.375rem 0.875rem 0.25rem
    calc(var(--base-content-container-extra-pl, 0rem) + 2rem);
  display: flex;
  border-bottom: 0.0625rem solid var(--gentle-stroke);
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: var(--secondary-background);
    .lastMessage {
      background-color: var(--secondary-background);
    }
    .lastMessageActive {
      background-color: var(--hover-fill);
    }
  }

  @include tablet {
    display: flex;
    flex-direction: row;
    &:hover {
      box-shadow: none;
    }
  }
}

.containerActive {
  background-color: var(--hover-fill);

  &:hover {
    background-color: var(--hover-fill);
  }
}

.containerExpanded {
  border: 0.0625rem solid var(--gentle-stroke);
  border-bottom: none;
}

.containerLongPressing {
  box-shadow: 0 0.1875rem 0.5rem #{$c-shades-neutrals-30};
  transition: box-shadow 0.4s;
}

.leftContentContainer {
  margin-right: 0.5rem;
  padding: 0 0.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftContentCircle {
  width: 0.75rem;
  height: 0.75rem;
  background-color: var(--secondary-fill);
  border-radius: 50%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.topContent {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: $c-neutrals-300;
}

.bottomContent {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.text {
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  font-weight: normal;
}

.titleWrapper {
  display: flex;
  align-items: center;
  color: var(--title-color);
  font-size: 0.9375rem;
  line-height: 1.5rem;
  overflow: hidden;

  & > * {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }

  @include tablet {
    max-width: calc(100% - 3.125rem);
  }
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lastMessageContainer {
  overflow: hidden;
}

.lastMessage {
  max-height: 1.25rem;
  color: $c-neutrals-300;
  font-size: $mobile-title;
  line-height: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  & > p {
    white-space: pre-wrap;
    text-overflow: ellipsis;
    overflow: hidden;

    & > a {
      color: $c-pink-mention-2;
    }
  }
}

.lastMessageActive {
  color: var(--primary-text);
  background-color: var(--hover-fill);
}

.mentionText {
  color: var(--primary-fill);
}

.messageCount {
  font-size: $small;
  line-height: 1.375rem;
  margin-left: 0.75rem;
}

.lastActivity {
  color: $c-neutrals-300;
  font-size: $xxsmall;
  font-weight: 300;
  text-align: right;
  word-break: normal;
}

.lastActivityActive {
  color: var(--primary-text);
}

.bottomContentRight {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.tooltipTriggerContainer {
  display: inline-flex;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
  max-width: 20rem;
  z-index: 3;
}

@import "../../../../../constants";

.labelWrapper {
  margin-bottom: 0.625rem;
  display: flex;
  justify-content: space-between;
  font-size: $xsmall;
  line-height: 120%;
  color: var(--primary-text);
}

.optionalHint {
  color: $c-neutrals-300;
}

.hint {
  font-style: italic;
  color: $c-neutrals-300;
}

@import "../../../../../../constants";

.container {
  padding: 0.625rem 0 0.625rem 0.625rem;
  display: flex;
  align-items: center;
  background-color: var(--secondary-background);
  box-sizing: border-box;
}

.linkContainer {
  flex: 1;
  margin-right: 1rem;
  padding: 0.5rem 3.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--hover-fill);
  border-radius: 0.9375rem;
  text-decoration: none;
  box-sizing: border-box;
  overflow: hidden;
}

.image {
  flex-shrink: 0;
  width: 3.375rem;
  height: 3.375rem;
  margin-right: 0.625rem;
  border-radius: 0.25rem;
  object-fit: cover;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.title {
  display: -webkit-box;
  font-size: 0.875rem;
  color: var(--primary-text);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.description {
  display: -webkit-box;
  font-size: 0.6875rem;
  color: $editor-checkbox-inactive;
  white-space: pre-wrap;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.url {
  font-size: 0.625rem;
  color: var(--highlight);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
}

.loadingText {
  font-size: 0.875rem;
  color: var(--primary-text);
}

.closeIconWrapper {
  margin: 0 1rem 0 auto;
  padding: 0.375rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: #{$editor-checkbox-inactive}33;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.create-common-payment-steps__modal-title-wrapper {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .create-common-payment-steps__modal-title-dots {
    margin: 0.375rem 0;
  }

  .create-common-payment-steps__modal-title {
    width: 100%;
    margin: 0;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    line-height: 1.38;
    text-align: center;
    color: $secondary-blue;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @include big-phone {
    height: 5rem;
  }
}

@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.survey-configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.survey-configuration__stage-name {
  margin-top: 0.75rem;

  @include big-phone {
    margin-top: 1.5rem;
  }
}

.survey-configuration__avatar-icon {
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
}

.survey-configuration__form {
  max-width: 36.5rem;
  width: 100%;
}

.create-survey__text-field {
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.survey-configuration__modal-footer {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.survey-form__text-field {
  margin-bottom: 1rem;
  margin-top: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.survey__links-array-item {
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
}

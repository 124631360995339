@import "../../../../../constants";

.itemsWrapper {
  --items-br: 0.5rem;

  position: absolute;
  top: 100%;
  right: 0;
  width: 13.75rem;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: $small;
  list-style-type: none;
  border-radius: var(--items-br);
  box-shadow: 0 0.25rem 0.9375rem var(--drop-shadow);
}

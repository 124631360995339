@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.members__section-element {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.members__common-title-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;

  .members__common-title {
    font-size: $moderate-med;
    font-weight: bold;
    line-height: 1.8rem;
    letter-spacing: normal;
    color: var(--primary-text);
  }

  .members__common-name {
    font-size: $small;
    font-weight: bold;
    color: var(--primary-text);
    margin-top: 0.5rem;
  }
}

.members__menu-wrapper {
  .members__menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    li {
      margin-left: 1rem;
      margin-right: 1rem;
      color: $gray;
      font-family: "PoppinsSans";
      font-style: normal;
      font-weight: 600;
      font-size: $small;
    }

    > li {
      cursor: pointer;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &.active {
        color: $purple;
      }

      &.active::after {
        content: "";
        display: block;
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 50%;
        background-color: $purple;
        position: absolute;
        top: 2rem;
      }

      &.active.isMobileView::after {
        content: "";
        display: block;
        width: 100%;
        height: 0.275rem;
        border-radius: 0%;
        background-color: $purple;
        position: absolute;
        top: 2rem;
      }
    }
  }
  .proposals-component-wrapper {
    width: 100%;

    .proposals-component-wrapper-empty-text {
      font-family: "PoppinsSans";
      font-style: normal;
      font-weight: 400;
      font-size: $xsmall;
      text-align: center;
      color: $light-gray-2;
    }
  }
}

.members__section-list {
  height: auto;
  //min-height: 20rem;
  flex-direction: row;
  align-items: baseline;

  .loader-wrapper {
    margin-left: 15%;
    margin-top: 10%;
    position: inherit;
  }

  .members__section__members-list {
    width: 100%;
    cursor: default;
    list-style-type: none;
    overflow: auto;
    flex: 1;
    padding: 0.2rem;
    margin: 0;

    .members__section__common-member {
      position: relative;
      cursor: pointer;
      padding-bottom: 0.25rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      background-color: var(--primary-background);
      box-shadow: 0 0.1875rem 0.5rem var(--drop-shadow);
      margin-bottom: 1rem;

      .members__section__common-member-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 1rem;

        .members__section__common-member-avatar {
          margin-right: 3rem;
        }

        .members__section__common-member-circles {
          font-family: "PoppinsSans";
          font-style: normal;
          font-weight: 400;
          font-size: $xxsmall;
          color: var(--primary-fill);
          margin-top: 0.1rem;
        }

        .members__section__common-member-name {
          margin-top: 0.2rem;
          font-family: "PoppinsSans";
          font-style: normal;
          font-weight: 700;
          font-size: $xsmall;
          color: var(--primary-text);
        }
      }

      .members__section__common-member-date {
        margin-right: 1rem;
        font-family: "PoppinsSans";
        font-style: normal;
        font-weight: 400;
        font-size: $xxsmall;
        text-align: center;
        color: $light-gray-2;
      }
    }
  }
}

@include big-phone {
  .members__component-header-wrapper {
    background-color: $light-purple-background;
    height: 5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .members__common-title-wrapper {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-family: "NotoSerifSC";
      font-style: normal;
      font-weight: 600;
      color: $secondary-blue;
    }
  }

  .members__menu-wrapper {
    margin-top: 0;
    border-radius: 0;

    &.sticked {
      left: 0;
      position: fixed;
      top: 3.75rem;
      z-index: $header-z-index;
    }

    .members__menu {
      width: 100%;
      padding: 1rem;

      li {
        margin: 0;
      }

      &.active::after {
        top: 1.7rem;
      }
    }
  }
  .members__section-list {
    .members__section__members-list {
      padding: unset;
      .members__section__common-member {
        .members__section__common-member-details {
          .members__section__common-member-avatar {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}

@import "../../../../../constants.scss";

.common-item {
  width: 100%;
  max-width: 18.75rem;
  height: 17.25rem;
  border-radius: 1.5rem;
  box-shadow: 0 0.188rem 0.5rem 0 $box-shadow-color;
  background-color: #ffffff;
  &:hover {
    box-shadow: 0 0.563rem 0.938rem 0 $box-shadow-color;
    // .overlay {
    //   display: none;
    // }
  }
  a {
    text-decoration: none;
  }
  .image-wrapper {
    height: 8rem;
    width: 100%;
    position: relative;
    border-radius: 1.5rem 1.5rem 0 0;
    display: flex;
    .overlay {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      border-radius: 1.5rem 1.5rem 0 0;
      background: rgba(0, 26, 54, 0.3);
    }
    img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.5rem 1.5rem 0 0;
      background-color: $purple;
    }
    .common-information {
      padding: 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-family: NotoSerifSC, serif;
      width: 100%;
      overflow: hidden;
      .name {
        z-index: 1;
        width: 100%;
        max-height: 3.625rem;
        text-shadow: 0 0.125rem 0.25rem #00000033;
        font-size: 1.25rem;
        font-weight: bold;
        color: $white;
        overflow: hidden;
        word-break: break-word;
      }
      .description {
        max-height: 2.375rem;
        z-index: 1;
        padding: 0 0.5rem;
        display: block;
        width: 100%;
        text-shadow: 0 0.125rem 0.25rem #00000033;
        font-size: $xxsmall;
        font-weight: 300;
        color: $white;
        overflow: hidden;
        word-break: break-word;
        box-sizing: border-box;
        &:lang(he) {
          font-family: Assistant-Light;
        }
      }
      .last-activity {
        z-index: 1;
        margin-top: auto;
        font-family: PoppinsSans, sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: $xxsmall;
        line-height: 1rem;
        display: flex;
        align-items: center;
        text-align: center;
        color: $light-gray-1;
      }
    }
  }
  .additional-information {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 4.375rem;
    margin-bottom: 1.25rem;
    .item {
      flex: 33%;
      .title {
        font-size: $xxsmall;
        font-weight: 600;
        text-align: center;
        color: $light-gray-2;
        margin-bottom: 0.5rem;
      }
      .value {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: #001a36;
      }
    }
  }

  .relation-additional-information {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-top: 0.063rem solid lightgray;
    .item {
      flex: 33%;
      .value {
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.5;
        text-align: center;
        color: $light-gray-2;
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          margin-right: 1.25rem;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
}

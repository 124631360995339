@import "../../../../../constants";
@import "../../../../../styles/mixins";
@import "../../../../../styles/sizes";

.connect-wrapper {
  padding: 0.5rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: PoppinsSans, sans-serif;

  @include big-phone {
    flex: 1 0;
    padding-bottom: 0;
    justify-content: space-between;
  }

  .connect-wrapper__content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include big-phone {
      padding: 0 1.5rem;
    }
  }

  .connect-wrapper__loader {
    left: 0;
    margin: auto 0;
    transform: unset;

    @include big-phone {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .connect-wrapper__img {
    width: 12.5rem;
    height: 12.5rem;

    @include big-phone {
      width: 10rem;
      height: 10rem;
      margin-bottom: 2rem;
    }
  }

  .connect-wrapper__title {
    margin: 2.625rem 0 0.5rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: bold;
  }
  .connect-wrapper__title--small {
    margin: 0 0 1rem;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
  }

  .connect-wrapper__sub-title {
    margin: 0 0 2.625rem;
    font-size: $small;
    color: $light-gray-2;
    text-align: center;

    @include big-phone {
      margin-bottom: 1rem;
      font-size: $xsmall;
    }
  }
  .connect-wrapper__sub-title--without-margin {
    margin: 0;
  }

  .connect-wrapper__error {
    margin: 0.5rem 0 1.5rem;
    background-color: $c-pink-active-feed-cards-light;
    color: var(--primary-fill);
  }

  .connect-wrapper__error__join-waitlist {
    margin-top: 1rem;
    b {
      cursor: pointer;
    }
  }

  .connect-wrapper__sub-text {
    margin: 2.625rem 0 0;
    font-size: $xsmall;
    color: $light-gray-8;

    @include big-phone {
      margin-top: 2rem;
    }
  }
  .connect-wrapper__sub-text--join-request {
    margin-bottom: 2.5rem;
  }

  .connect-wrapper__terms-of-use {
    font-weight: 600;
    text-decoration: underline;
    color: var(--primary-fill);
  }

  @include big-phone {
    padding-top: 0;
  }
}
.connect-wrapper--loading {
  min-height: 32.75rem;
  box-sizing: border-box;

  @include big-phone {
    min-height: unset;
  }
}

@include rtl-direction {
  .connect-wrapper__sub-title,
  .connect-wrapper__sub-text {
    direction: rtl;
  }
}

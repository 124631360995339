@import "../../../../constants";
@import "../../../../styles/sizes";

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $c-neutrals-600;

  @include tablet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.header {
  display: flex;
  overflow: hidden;

  @include tablet {
    width: 100%;
    overflow: unset;
  }
}

.commonImage {
  flex-shrink: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;
  border-radius: 1.125rem;

  @include tablet {
    width: 4rem;
    height: 4rem;
    border-radius: 0.75rem;
  }
}
.commonImageRounded {
  border-radius: 50%;
}
.commonImagePlaceholder {
  background-color: $c-primary-300;
}

.commonInfoWrapper {
  overflow: hidden;

  @include tablet {
    width: 100%;
  }
}

.commonName {
  margin: 0;
  font-family: Lexend, sans-serif;
  font-weight: normal;
  font-size: $large-1;
  line-height: 133%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include tablet {
    max-width: 80%;
    margin-bottom: 0.125rem;
    font-size: $moderate-med;
    line-height: 100%;
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  }
}

.description {
  margin: 0;
  font-size: $small;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @include tablet {
    max-width: 80%;
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  }
}

.rightHalf {
  display: flex;
  align-items: center;

  @include tablet {
    margin-top: 2rem;
  }
}

.joinButton {
  margin-right: 0;
}
.joinButtonWithMargin {
  margin-right: 3rem;
}

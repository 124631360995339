@import "../../../../../constants";

.custom-phone-input-custom-select {
  width: 100%;
  height: 100%;
  font-size: inherit;
  box-sizing: border-box;

  .custom-phone-input-custom-select__menu-button {
    height: 100%;
    padding: 0 1rem;
    border: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    background-color: transparent;
    color: $black;
  }

  .custom-phone-input-custom-select__value {
    flex: 0 0 auto;
    margin: 0;
  }

  .custom-phone-input-custom-select__arrow-icon {
    width: 1rem;
    height: 1rem;
  }

  .custom-phone-input-custom-select__menu {
    max-width: 15.25rem;
    width: 100%;
    border-radius: 0.25rem;
  }

  .custom-phone-input-custom-select__menu-list {
    max-height: 14.75rem;
  }
}

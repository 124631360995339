@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.bank-account-file-info-upload {
  width: 100%;
  padding: 1rem 0.5rem 0.75rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $xxsmall;
  color: $secondary-blue;
  text-align: center;
  border-radius: 0.75rem;
  background-color: var(--secondary-background);
  color: var(--primary-text);
  box-sizing: border-box;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--hover-fill);
  }

  @include big-phone {
    max-width: 100%;
  }
}

.bank-account-file-info-upload__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}
.bank-account-file-info-upload__container--between {
  justify-content: space-between;
}

.bank-account-file-info-upload__file-input {
  position: absolute;
  width: 0;
  height: 0;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

.bank-account-file-info-upload__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  margin-left: 0.5rem;
}

.bank-account-file-info-upload__text-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  .bank-account-file-info-upload__button-hint {
    margin-top: 0.25rem;
  }
}

@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.create-common-contribution {
  width: 100%;
  max-width: 36.5rem;
  min-height: 29.125rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PoppinsSans, sans-serif;

  .create-common-contribution__sub-title {
    margin: 0 0 0.25rem;
    font-family: inherit;
    font-weight: bold;
  }

  .create-common-contribution__sub-text-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .create-common-contribution__sub-text-item {
    max-width: 23.7rem;
    margin: 0 0 1.5rem;
    font-size: $xsmall;
    text-align: center;
    color: $light-gray-2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .create-common-contribution__separator {
    margin: 1.5rem 0 1rem;

    @include big-phone {
      margin-bottom: 1.5rem;
    }
  }

  @include big-phone {
    min-height: unset;
  }
}

.create-common-contribution__hint {
  margin: 1.5rem 0 0;
  font-size: $xsmall;
  line-height: 1.42;
  text-align: center;
  color: $blue-2;
}

.create-common-contribution__submit-button-wrapper {
  margin: 0 auto;
  padding: 1.75rem 0;
  display: flex;
  justify-content: center;

  .create-common-contribution__submit-button {
    min-width: 11.25rem;

    @include big-phone {
      min-width: unset;
    }
  }

  @include big-phone {
    padding: 1.5rem 0;
  }
}

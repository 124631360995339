@import "../../../../../../../constants";

.container {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.list {
  margin: 0;
  padding: 1rem var(--sidenav-content-ph);
  list-style-type: none;
}

.listItem {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

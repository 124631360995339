@import "../../../constants";

.social-login-button {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  background-color: var(--secondary-background);
  box-shadow: 0 6.8px 25.4px 0 var(--drop-shadow);
  transition: all 0.2s;
  color: var(--primary-text);

  &:hover {
    background-color: var(--hover-fill);
    cursor: pointer;
  }

  .social-login-button__img {
    width: 2.625rem;
    height: 2.625rem;
  }
}

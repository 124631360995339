@import "../../../../../constants";
@import "../../../../../styles/mixins";
@import "../../../../../styles/sizes";

.supporters-page-welcome {
  max-width: 36.625rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.supporters-page-welcome__title {
  margin: 0 0 1rem;
  font-family: Lexend, sans-serif;
  font-weight: normal;
  font-size: $large;
  line-height: 133%;
  text-align: center;

  @include big-phone {
    margin-bottom: 1.375rem;
    line-height: unset;
  }
}

.supporters-page-welcome__description {
  max-width: 19.25rem;
  margin: 0 0 3.75rem;

  @include big-phone {
    margin-bottom: 3rem;
  }
}

.supporters-page-welcome__rules-description {
  align-self: flex-start;
  max-width: 20.75rem;
  margin: 0 0 1.375rem;
  text-align: left;

  @include big-phone {
    margin-bottom: 3rem;
  }
}

.supporters-page-welcome__rules-title {
  align-self: flex-start;
  margin: 0 0 0.625rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
}

.supporters-page-welcome__rule-list {
  align-self: flex-start;
  margin: 0 0 2.375rem;
  padding: 0;
  list-style-type: none;
  text-align: left;
}

.supporters-page-welcome__rule-list-item {
  margin-bottom: 0.625rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.supporters-page-welcome__rule-title {
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
}

.supporters-page-welcome__rule-description {
  margin: 0;
  font-size: $small;
  line-height: 120%;
  letter-spacing: 0.01em;
  white-space: pre-line;
}

.supporters-page-welcome__rules-approval-checkbox {
  align-self: flex-start;
  margin-bottom: 4.625rem;
}

.supporters-page-welcome__submit-button {
  max-width: 12.625rem;

  @include big-phone {
    max-width: unset;
  }
}

@include rtl-direction {
  .supporters-page-welcome__rules-description,
  .supporters-page-welcome__rule-list {
    text-align: right;
  }
}

@import "../../../constants.scss";

.social-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  .share-button {
    width: 40px;
    height: 40px;
    color: $secondary-blue;
    border-radius: 40px;
    background-color: var(--share-button-bg);
    cursor: pointer;
  }
  .share-button:hover {
    background-color: $purple-hover;
  }
  .share-button:active {
    background-color: $purple-pressed;
  }
  .share-button__with-border {
    border: 1px solid $light-gray-1;
  }

  .social-wrapper__children-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .social-wrapper {
    align-self: self-end;
    .share-button:active {
      background-color: unset;
    }
  }
}

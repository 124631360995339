@import "../../../../../constants.scss";

.countdown-wrapper {
  align-items: center;
  justify-content: center;
  display: flex;

  .inner-wrapper {
    border-radius: 0.375rem;
    background-color: #fff3d8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;

    .clock-icon {
      width: 0.9rem;
      margin-right: 0.5rem;
    }
    .text {
      font-size: $xsmall;
      font-weight: bold;
      color: #ffae26;
    }
  }
}

.countdown-preview {
  font-size: $xsmall;
  color: $light-gray-2;
}

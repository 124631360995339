@import "../../../../../constants";
@import "../../../../../styles/sizes";

$payment-iframe-height: 424px;
$payment-iframe-height-mobile: 672px;

.membership-request-modal {
  color: $secondary-blue;

  .membership-request-modal__content--introduction {
    background: url("/assets/images/membership-request-layers.svg") no-repeat;
    background-size: cover;
  }

  .membership-request-modal__title {
    height: 80px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PoppinsSans, sans-serif;
    font-size: $moderate;
    font-weight: bold;
    text-align: center;
    color: var(--primary-text);

    @include big-phone {
      height: 64px;
      font-size: $small;
    }
  }
}

.membership-request-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--primary-text);

  .top {
    display: flex;
    align-items: center;

    .arrow-back {
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .title {
    text-align: center;
    font-family: NotoSerifSC-Bold;
    font-size: $moderate-med;
    margin: auto;
  }

  .membership-request-progress-bar {
    width: 304px;
    margin-bottom: 1.5rem;
    align-self: center;
  }

  .sub-title {
    font-weight: bold;
    font-family: PoppinsSans, sans-serif;
    margin-bottom: 0.2rem;
  }

  .sub-text {
    max-width: 584px;
    color: $light-gray-2;
    text-align: center;
    width: 100%;
    border-bottom: 1px solid $light-gray-1;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.membership-request-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 6rem;
  height: 100%;

  label {
    margin-bottom: 0.6rem;
    align-self: flex-start;
  }

  select {
    height: 40px;
    padding: 0 0.7rem;
    border-radius: 3px;
    border: 1px solid $light-gray-1;
    box-sizing: border-box;
  }
}

.membership-request-welcome-wrapper {
  &__title {
    font-size: $xlarge;
    color: $white;
    font-family: PoppinsSans, sans-serif;
    font-weight: bold;
  }
  .illustrations-container {
    display: flex;
    margin: 5rem 0;

    figure {
      border-radius: 34px;
      background-color: $transparent-white;
      box-shadow: 0 2px 34px 0 $fixed-modal-shadow;
      padding: 0 1.5rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 1.5rem 0 0;
      font-size: $small;
      text-align: center;
      width: 10rem;
      color: $secondary-blue;

      img {
        margin-bottom: 15px;
      }
    }

    figure:last-child {
      margin-right: unset;
    }

    .arrow {
      align-self: flex-start;
    }
  }

  .button-blue {
    min-width: 174px !important;
  }
}

.membership-request-welcome__modal-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 2.5rem;
  padding-top: 1rem;

  @include big-phone {
    padding-bottom: 2rem;
  }
}

.membership-request-welcome__submit-button {
  min-width: 11.25rem;

  @include big-phone {
    min-width: unset;
  }
}

.membership-request-introduce {
  .membership-request-introduce__form {
    width: 100%;
  }

  .membership-request-introduce__field-label {
    font-size: $xsmall;
  }

  .membership-request-introduce__links-array {
    margin-top: 2rem;

    &:last-child {
      margin-top: 0;
    }
  }

  .membership-request-introduce__links-array-item {
    margin-top: 1rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .membership-request-introduce__submit-button-wrapper {
    padding-top: 4.625rem;
    display: flex;
    justify-content: center;

    @include big-phone {
      padding-top: 1.75rem;
    }
  }

  .membership-request-introduce__submit-button {
    min-width: 11.25rem;

    @include big-phone {
      min-width: unset;
    }
  }
}

.membership-request-rules {
  .membership-request-rules__rules-wrapper {
    max-width: 36.5rem;
    margin: 0 0 2.875rem;
    padding: 0;
  }

  .membership-request-rules__rules-item-wrapper {
    margin-bottom: 0.875rem;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    color: $secondary-blue;
    list-style-position: inside;
    white-space: nowrap;

    &:last-child {
      margin-bottom: 0;
    }

    &::marker {
      color: inherit;
    }
  }

  .membership-request-rules__rules-item {
    padding-left: 0.5rem;
    display: inline-flex;
    flex-direction: column;
    white-space: initial;
    word-break: break-word;
  }

  .membership-request-rules__rules-item-title {
    margin: 0 0 0.25rem;
  }

  .membership-request-rules__rules-item-description {
    margin: 0;
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
    font-size: $xsmall;
  }

  .membership-request-rules__submit-button {
    min-width: 11.25rem;

    @include big-phone {
      min-width: unset;
    }
  }
}

.membership-request-contribution {
  min-height: 466px;

  .options-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    button {
      font-family: PoppinsSans, sans-serif;
      font-weight: normal;
      border: 1px solid $light-gray-1;
      width: 350px;
      height: 60px;
      color: $purple;
      border-radius: 8px;
      background-color: transparent;
      margin-bottom: 1.5rem;
      cursor: pointer;

      &.selected {
        background-color: $purple;
        color: $white;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .membership-request-contribution__description {
    font-size: $xsmall;
  }

  .membership-request-contribution__contribution-amount-selection {
    margin-top: 0.5rem;
  }
  .membership-request-contribution__contribution-amount-selection--one-time {
    margin-bottom: 2.375rem;
  }

  .membership-request-contribution__hint {
    margin: 1rem 0 0.625rem;
    font-size: $xsmall;
    line-height: 1.42;
    text-align: center;
    color: $blue-2;
  }

  @include big-phone {
    min-height: unset;
  }

  .button-blue {
    min-width: 11.25rem;
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
  }
}

.membership-request-contribution__modal-footer {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0;
}

.membership-request-contribution__submit-button {
  min-width: 11.25rem;

  @include big-phone {
    min-width: unset;
  }
}

.membership-request-billing {
  .button-blue {
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
    height: 48px;
  }

  .sub-text {
    margin: 0;
  }

  label {
    padding-top: 22px;
  }

  .inputs-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 7rem;

    .inputs-group {
      display: flex;
      flex-direction: column;

      select,
      input {
        width: 280px;
      }
    }
  }

  .membership-request-billing__loader-wrapper {
    padding-top: 30px;
    display: flex;
  }
}

.membership-request-payment {
  min-height: 25rem;

  input {
    width: 100%;
  }

  .error {
    align-self: flex-start;
    color: $red;
  }

  .expiration-cvv-wrapper {
    margin-top: 1.3rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .membership-rejected-text {
    color: $light-gray-2;
    margin-top: 1.5rem;
    text-align: center;
  }

  .circle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;

    span {
      font-size: $xxsmall;
      color: $light-gray-2;
      margin-bottom: 0.2rem;
    }
  }

  .membership-request-payment__amount {
    color: $purple;
  }

  @include big-phone {
    min-height: unset;
  }
}

.membership-request-payment__content {
  flex: 1 0;
  position: relative;
  max-width: 25rem;
  width: 100%;
  min-height: $payment-iframe-height;
  display: flex;

  @include big-phone {
    min-height: 3.125rem;
  }
}

.membership-request-payment__loader {
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

  @include big-phone {
    top: unset;
    transform: translateX(-50%);
  }
}

.membership-request-payment__continue-button-wrapper {
  padding: 1.75rem 0;
  display: flex;
  justify-content: center;

  .membership-request-payment__continue-button {
    min-width: 11.25rem;

    @include big-phone {
      min-width: unset;
    }
  }

  @include big-phone {
    padding: 1.5rem 0;
  }
}

.membership-request-creating {
  .loader-container {
    margin: 3rem 0;
  }
}

.membership-request-created {
  span {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 3.5rem;
  }

  button {
    width: 11.25rem;
    height: 3rem;
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
    font-size: $small;
    text-align: center;
    color: $secondary-blue;
    border-radius: 2rem;
    border: solid 1px $light-gray-1;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: $light-gray-1;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .membership-request-wrapper {
    .title {
      font-size: $small;
    }
  }

  .membership-request-content {
    padding: unset;

    select {
      width: 100%;
    }
  }

  .membership-request-welcome-wrapper {
    align-items: unset;

    &__title {
      line-height: 0.82;
    }

    .illustrations-container {
      margin: 0 0 1rem;
      flex-direction: column;

      figure {
        width: 100%;
        box-sizing: border-box;
        margin-right: unset;
        margin-bottom: 1rem;
        flex-direction: row;
        padding: 0;

        img {
          margin-bottom: unset;
        }

        figcaption {
          text-align: left;
        }
      }

      figure:first-child {
        margin-top: 1.75rem;
      }

      figure:nth-child(even) {
        figcaption {
          margin-right: 2rem;
          margin-left: auto;
        }
      }

      figure:nth-child(odd) {
        flex-direction: row-reverse;
        figcaption {
          margin-left: 2rem;
          margin-right: auto;
        }
      }
    }
  }

  .membership-request-contribution {
    .options-wrapper {
      button {
        width: 100%;
      }
    }
  }

  .membership-request-billing {
    .inputs-wrapper {
      flex-direction: column;
      margin-bottom: 1rem;

      .inputs-group {
        select,
        input {
          width: 100%;
        }
      }
    }
  }

  .membership-request-created {
    button {
      width: 100%;
    }
  }
}

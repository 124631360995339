@import "../../../../constants";
@import "../../../../styles/sizes";

/* importent! here is to override the other <a> elements in the menu */
.routes-wrapper {
  display: flex;
  flex-direction: column;
  a {
    cursor: pointer;
    border-radius: 0.5rem;
    height: 3.5rem;
    line-height: 3.5rem;
    padding-left: 2rem;
    color: $black;
    text-decoration: none;
    &.active {
      background-color: $light-gray-4 !important;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}

@include big-phone {
  .routes-wrapper {
    a {
      height: 2rem;
      line-height: 2rem;
      color: $black !important;
      border-top: unset !important;
      font-size: $small !important;
    }
  }
}

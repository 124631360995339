@import "../../../../../constants";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.secretChatLabel {
  color: $c-pink-light-2;
  margin: 1rem 0 0;
}

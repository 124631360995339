/* */
$content-padding: 80px;
$content-padding-mobile: 20px;
$content-padding-bottom: 20px;
$header-height: 80px;
$header-height-mobile: 60px;
$container-desktop-header-height: 4.5625rem;
$container-mobile-header-height: 3.25rem;
$share-z-index: 2;
$header-z-index: 10;
$date-picker-z-index: $header-z-index - 1;
$dropdown-z-index: $date-picker-z-index - 1;
$file-preview-z-index: $date-picker-z-index + 1;
$secondary-modal-z-index: 20;
$modal-z-index: 999;
$notifications-container-z-index: $modal-z-index + 1;
$modal-dropdown-menu-z-index: $modal-z-index + 1;
$global-overlay-z-index: $modal-dropdown-menu-z-index + 1;
$element-max-width: 1400px;
$element-max-height: 850px;
$payment-iframe-height: 26.5rem;
$payment-iframe-height-mobile: 42rem;
$download-common-app-mobile-menu-height: 3.125rem;

/* font sizes */
$xlarge: 45px; // 2.8125rem
$mlarge: 41px; // 2.5625rem
$large-1: 35px; // 2.1875rem
$large: 34px; // 2.125rem
$medium: 25px; // 1.5625rem
$moderate-med: 23px; // 1.4375rem
$moderate-med-2: 22px; // 1.375rem
$moderate-med-3: 21px; // 1.3125rem
$moderate-small: 19px; // 1.1875rem
$moderate-small-2: 18px; // 1.125rem
$moderate: 17px; // 1.0625rem
$moderate-xsmall: 16px; //1rem
$small: 15px; // 0.9375rem
$mobile-title: 14px; // 0.875rem
$xsmall: 13px; // 0.8125rem
$xxsmall-2: 12px; // 0.75rem
$xxsmall: 11px; // 0.6875rem
$xxsmall-3: 10px; // 0.625rem
$xxxsmall: 9px; // 0.5625rem
$xxxsmall-2: 8px; // 0.5rem
$xxxsmall-3: 7px; // 0.4375rem
$xxxxsmall: 5px; // 0.3125rem

/* colors */
$box-shadow-color: #001a3614;
$purple: #7786ff;
$purple-alpha-10: rgba(119, 134, 255, 0.1);
$purple-2: #5666f5;
$purple-hover: #e4e7ff;
$purple-pressed: #d6daff;
$light-purple: #f1f2ff;
$light-purple-background: #fbfcfc;
$white: #ffffff;
$transparent-white: #ffffffde;
$white-2: #fcfaf9;
$pink: #ff5e9d;
$crimson: #ff005c;
$blue: #0b2046;
$blue-2: #4f5c69;
$secondary-blue: #001a36;
$deep-blue: #071836;
$blue-text: #0a2149;
$light-gray-1: #eeeeee;
$light-gray-2: #979bba;
$light-gray-3: #d5d5e4;
$light-gray-4: #f4f6ff;
$light-gray-5: #f8f9f9;
$light-gray-6: #f3f4f5;
$light-gray-7: #e6eafb;
$light-gray-8: #7a8189;
$light-gray-9: #d2d8ff;
$light-gray-10: #ebedff;
$light-gray-11: #f9faff;
$light-gray-12: #432b33;
$gray: #92a2b5;
$gray-2: #6e7b8e;
$green: #6ee569;
$light-green: #f0fdf0;
$secondary-green: #f1fdf0;
$shadow: rgba(0, 0, 0, 0.14);
$shadow-2: rgba(0, 26, 54, 0.08);
$black: #000000;
$black-gray: rgba(0, 0, 0, 0.5);
$black-gray-2: rgba(0, 0, 0, 0.4);
$black-gray-3: rgba(0, 0, 0, 0.15);
$black-gray-4: rgba(0, 0, 0, 0.2);
$black-gray-5: rgba(255, 0, 92, 0);
$red: #ff603e;
$light-red: #feefec;
$light-red-2: #ffefff;
$secondary-red: #ffefec;
$dark-yellow: #ffae26;
$error: #ef5456;
$fixed-button-shadow: rgba(0, 26, 54, 0.14);
$fixed-modal-element-shadow: rgba(0, 26, 54, 0.07);
$fixed-modal-shadow: rgba(0, 26, 54, 0.2);
$invoice-description-card-shadow: rgba(0, 26, 54, 0.08);
$blue-button-disabled-second-option: #d5dee4;
$secondary-modal-shadow: 0 2px 34px 0 rgba(0, 0, 0, 0.3);
$invoice-approval-item-shadow: rgba(0, 26, 54, 0.08);
$sticky-info-shadow: rgba(0, 0, 0, 0.02);
$carousel-overlay-shadow: rgba(0, 26, 54, 0.4);
$shadow-1: rgba(79, 92, 105, 0.1);
$date-picker-arrow-color: #aeaeae;
$transparent: rgba(255, 255, 255, 0);

/* colors v2 */
$c-primary-100: #f4f6ff;
$c-primary-200: #d2d8ff;
$c-primary-300: #7786ff;
$c-primary-400: #5666f5;
$c-primary-500: #2c3fef;
$c-neutrals-100: #eeeeee;
$c-neutrals-100-hover: #e1e1e1;
$c-neutrals-100-shadow: 4px 4px 0px #e2e2e2;
$c-neutrals-200: #d5d5e4;
$c-neutrals-300: #979bba;
$c-neutrals-600: var(--primary-text);
$c-notification-100: #f9faff;
$c-shades-white: #ffffff;
$c-shades-black: #000000;
$c-shades-neutrals-30: rgba(0, 26, 54, 0.3);
$c-error-100: #ffefec;
$c-error-100-shadow: 4px 4px 0px #feded8;
$c-shades-100: #fbfcfc;
$c-error-100-hover: #feebe8;
$c-error-300: #ff603e;
$c-hover-secondary: #f9fcff;
$c-green-100: #ddffdc;
$c-green-300: #62a541;
$c-pink-light: #f3d4eb;
$c-pink-light-2: #d84c7f;
$c-pink-mention: #c32ea3;
$c-pink-mention-2: #ff74e1;
$c-pink-mention-3: #cf7d9b;
$c-pink-dark: #826a7d;
$c-pink-next-dark: #a4358b;
$c-pink-primary: #de189b;
$c-pink-active-btn: #f0daec;
$c-pink-active-feed-cards-light: #fcf4ff;
$c-pink-active-feed-cards: #a75a93;
$c-pink-hover-feed-cards: #fff9fd;
$c-gray-5: #f4f5f5;
$c-gray-10: var(--gentle-stroke);
$c-gray-20: var(--gentle-stroke);
$c-gray-30: #b7bcd2;
$c-gray-40: #8d91a9;
$c-gray-50: #7a819c;
$c-gray-60: #6b718e;
$c-gray-80: #2e3452;
$c-gray-90: #1f2535;
$c-gray-100: #131b23;
$c-gray-800: #27292c;
$c-separator-light-non-opaque: rgba(60, 60, 67, 0.36);
$logo-1: #99ca80;
$success-100: #f1fdf0;
$success-100-hover: #d8ffd4;
$success-100-shadow: 4px 4px 0px #e2f9e0;
$success-300: #6ee569;

$c-input-100: #f9fafa;
$c-light-gray: #f4f5f5;
$c-light-gray-2: #f8f8f8;
$c-light-gray-3: #f4f5f5;
$c-sidebar-shadow: rgba(0, 26, 54, 0.08);
$c-sidebar-user-menu-shadow: rgba(0, 0, 0, 0.15259);
$c-common-card-shadow: rgba(0, 26, 54, 0.08);
$c-common-card-shadow-hover: rgba(0, 26, 54, 0.4);
$split-view-resizer-border-color: rgba(255, 255, 255, 0);
$split-view-resizer-border-hover-color: rgba(0, 0, 0, 0.5);
$editor-checkbox-inactive: #99999d;
$editor-checkbox-active: #d84ca0;
$chat-checkbox-inactive: #cecece;
$link-my-reply-bg: #943367;
$link-my-reply: #ffa2ea;

/* breakpoints */
$small-laptop: 1366px;
$small-screen: 1240px;
$header-mobile-breakpoint: 1062px;
$mobile-breakpoint: 770px;
$mobile-portrait: 375px;

/* breakpoints v2 */
$desktop-breakpoint: 1560px;
$laptop-breakpoint: 1152px;
$tablet-breakpoint: 768px;
$phone-breakpoint: 390px;

/* */
$landing-page-animation-duration: 10s;

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.linkIcon {
  width: 1rem;
  height: 1rem;

  &:hover {
    color: $c-pink-next-dark;
  }
}

.tooltipContent {
  max-width: 30rem;
  z-index: 3;
  display: flex;
  flex-direction: column;

  @include tablet {
    max-width: 100vw;
  }
}

.contentTitle {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: $c-gray-40;
}

.loader {
  margin: 0 auto;
}

.link {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: var(--primary-text);
  text-decoration: none;

  &:hover {
    color: var(--primary-fill);
  }
}
.linkWithBottomMargin {
  margin-bottom: 0.625rem;
}

.linkLeft,
.linkRight {
  white-space: nowrap;
  overflow: hidden;
}

.linkLeft {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.linkRight {
  flex-shrink: 0;
}

.ellipsis {
  flex-shrink: 0;
}

.arrowIcon {
  flex-shrink: 0;
}

@import "../../../../../../../../../constants";

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  animation: listCollapse 0.3s;

  @keyframes listCollapse {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.newProjectImage {
  background-color: $c-pink-dark;
}

.addProjectItem {
  --item-text-color: var(--primary-fill);
}

.plusIcon {
  padding-right: 0.875rem;
}

$notoSerifSCFontName: "NotoSerifSC";
$assistantFontName: "Assistant";
$heeboFontName: "Heebo";
$lexendFontName: "Lexend";
$poppinsSansFontName: "PoppinsSans";

$trueTypeFontFormat: "truetype";
$openTypeFontFormat: "opentype";

// NotoSerifSC
@font-face {
  font-family: $notoSerifSCFontName;
  src: local($notoSerifSCFontName),
    url("/shared/assets/fonts/NotoSerifSC-SemiBold.woff2")
      format($openTypeFontFormat);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: $notoSerifSCFontName;
  src: local($notoSerifSCFontName),
    url("/shared/assets/fonts/NotoSerifSC-Bold.woff2")
      format($openTypeFontFormat);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: $notoSerifSCFontName;
  src: local($notoSerifSCFontName),
    url("/shared/assets/fonts/NotoSerifSC-Black.woff2")
      format($openTypeFontFormat);
  font-weight: 900;
  font-display: swap;
}

// Assistant
@font-face {
  font-family: $assistantFontName;
  src: local($assistantFontName),
    url("/shared/assets/fonts/Assistant-Light.ttf") format($trueTypeFontFormat);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: $assistantFontName;
  src: local($assistantFontName),
    url("/shared/assets/fonts/Assistant-Bold.ttf") format($trueTypeFontFormat);
  font-weight: bold;
  font-display: swap;
}

// Heebo
@font-face {
  font-family: $heeboFontName;
  src: local($heeboFontName),
    url("/shared/assets/fonts/Heebo-Regular.ttf") format($trueTypeFontFormat);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: $heeboFontName;
  src: local($heeboFontName),
    url("/shared/assets/fonts/Heebo-Medium.ttf") format($trueTypeFontFormat);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: $heeboFontName;
  src: local($heeboFontName),
    url("/shared/assets/fonts/Heebo-Bold.ttf") format($trueTypeFontFormat);
  font-weight: bold;
  font-display: swap;
}

// Lexend
@font-face {
  font-family: $lexendFontName;
  src: local($lexendFontName),
    url("/shared/assets/fonts/Lexend-Light.ttf") format($trueTypeFontFormat);
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: $lexendFontName;
  src: local($lexendFontName),
    url("/shared/assets/fonts/Lexend-Regular.ttf") format($trueTypeFontFormat);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: $lexendFontName;
  src: local($lexendFontName),
    url("/shared/assets/fonts/Lexend-Bold.ttf") format($trueTypeFontFormat);
  font-weight: bold;
  font-display: swap;
}

// Poppins
@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-Regular.ttf") format($trueTypeFontFormat);
  font-weight: normal;
  font-display: swap;
}

@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-Italic.ttf") format($trueTypeFontFormat);
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-Medium.ttf") format($trueTypeFontFormat);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-SemiBold.ttf") format($trueTypeFontFormat);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-Bold.ttf") format($trueTypeFontFormat);
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: $poppinsSansFontName;
  src: local($poppinsSansFontName),
    url("/shared/assets/fonts/Poppins-ExtraBold.ttf")
      format($trueTypeFontFormat);
  font-weight: 800;
  font-display: swap;
}

@import "../../../constants";
@import "../../../styles/mixins";

.delete-modal {
  max-width: 25rem;
  width: 100%;
  border-radius: 0.5rem;
}

.delete-modal__header {
  .modal__title {
    font-size: $moderate-med;
  }
}

.delete-modal__content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.delete-modal__title {
  font-size: $moderate;
  text-align: center;
  margin-top: 0;
}

.delete-modal__input {
  resize: none;
  width: 100%;
  border-width: 0;
  outline: 0;
  border: 0.0625rem $light-gray-3 solid;
  height: 100%;
}

.delete-modal__button-container {
  display: flex;
  justify-content: flex-end;
}

.delete-modal__button-container__button {
  width: 100%;
}

.delete-modal__button-container__cancel {
  margin-right: 0.75rem;
}

.delete-modal__button-container__send__loader {
  left: 0;
  transform: unset;
}

@import "../../../../constants";

.carousel-file-preview-wrapper {
  position: relative;
  max-width: 100%;
  max-height: 100%;

  .carousel-file-preview-wrapper__content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .carousel-file-preview-wrapper__image-wrapper {
    position: relative;
    height: 100%;
  }

  .carousel-file-preview-wrapper__image {
    max-width: 100%;
    min-width: 9.5rem;
    max-height: 100%;
    height: 100%;
    display: block;
    border-radius: 10px;
    object-fit: contain;
  }

  .carousel-file-preview-wrapper__general-file {
    width: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-gray-1;
    text-align: center;
    word-break: break-word;
    user-select: none;
    overflow: hidden;
  }

  .carousel-file-preview-wrapper__icons-wrapper {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  .carousel-file-preview-wrapper__icon-wrapper {
    margin-right: 0.5rem;
    display: flex;

    &:last-child {
      margin-right: 0;
    }
  }

  .carousel-file-preview-wrapper__icon {
    width: 3rem;
    height: 3rem;
    padding: 0.75rem;
    background-color: $black-gray;
    border-radius: 50%;
    color: $white;
    box-sizing: border-box;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .carousel-file-preview-wrapper__switch-icon-wrapper {
    height: 100%;
    padding: 0 1.5rem;
  }

  .carousel-file-preview-wrapper__switch-icon {
    background-color: $white;
    border: 1px solid $light-gray-1;
    color: $secondary-blue;
  }
}

.leaf {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

@import "../../../constants";
@import "../../../styles/sizes";

$zIndex: 3;

.sidenavBackground {
  display: none;
}
.sidenavBackgroundOpen {
  @include tablet {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zIndex;
    display: block;
    background-color: var(--drop-shadow);
    opacity: 0.5;
    animation: fade var(--sb-transition-duration);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}

.sidenav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex;
  max-width: var(--sb-max-width);
  width: var(--sb-width);

  @include tablet {
    right: 0;
    backdrop-filter: blur(0.125rem);
  }
}
.sidenavWithAnimation {
  display: flex;
  will-change: transform;
  transform: translateX(-110vw);
  transition: transform var(--sb-transition-duration) var(--sb-easing),
    visibility 0s linear var(--sb-transition-duration);
  overflow: hidden;
  overscroll-behavior: contain;
  visibility: hidden;
}
.sidenavWithAnimationOpen {
  transform: translateX(0);
  transition: transform var(--sb-transition-duration) var(--sb-easing);
  visibility: visible;
}

.contentWrapper {
  flex-shrink: 0;
  max-width: var(--sb-content-max-width);
  width: var(--sb-content-width);
  height: 100%;
  padding-bottom: var(--sb-content-pb);
  display: flex;
  flex-direction: column;
  background-color: var(--primary-background);
  box-shadow: var(--sb-shadow);
  overflow: hidden;
  box-sizing: border-box;
}

.closeLink {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  touch-action: manipulation;
  display: none;

  @include tablet {
    width: 100%;
    height: 100%;
    display: initial;
  }
}

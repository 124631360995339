@import "../../../../../constants.scss";

.edit-common-modal {
  color: $secondary-blue;

  .edit-common-modal__title {
    margin: 0;
    padding: 1.75rem 0 1.5rem;
    font-family: NotoSerifSC-Bold, serif;
    font-size: $moderate-med;
    font-weight: bold;
    line-height: 1.32;
    text-align: center;
  }
}

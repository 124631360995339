@import "../../../../../../constants";

.item {
  --item-color: var(--primary-text);
  --item-bg-color: var(--primary-background);
  --item-border: 0.0625rem solid var(--gentle-stroke);

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
  color: var(--item-color);
  background-color: var(--tertiary-fill);
  border: var(--item-border);
  border-bottom: 0;
  font-family: PoppinsSans, sans-serif;
  font-weight: 600;
  font-size: $small;
  text-align: left;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s;

  &:first-child {
    border-top-left-radius: var(--items-br);
    border-top-right-radius: var(--items-br);
  }

  &:last-child {
    border-bottom: var(--item-border);
    border-bottom-left-radius: var(--items-br);
    border-bottom-right-radius: var(--items-br);
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
}

.circleName {
  font-size: $small;
  line-height: 1.375rem;
  margin: 0;
}

.disabled {
  color: $light-gray-2;
}

.membersCountLoader {
  height: 1rem;
  width: 1rem;
  background-size: 1rem;
  margin-right: 0.25rem;
}

.actionButton {
  padding: 0 1rem;
  height: 2rem;
}

.pendingStatus {
  font-size: $small;
  line-height: 1.25rem;
  color: var(--primary-text);
  margin: 0;
}

.tooltipContent {
  margin-top: 1.125rem;
  max-width: 20.9375rem;
  z-index: 1;
  background-color: var(--primary-background);
}

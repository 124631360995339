@import "../../../../../../constants.scss";

.container {
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-background);
  padding: 0.4375rem 2rem 0.625rem;
  border-top: 0.0625rem solid var(--gentle-stroke);
  border-bottom: 0.0625rem solid var(--gentle-stroke);
}

.containerEmpty {
  display: none;
}

.messageContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background-color: var(--secondary-fill);
  color: var(--primary-text);
  border-radius: 0.875rem;
  padding: 0.5rem 0.75rem;
}

.image {
  height: 3.4375rem;
  width: 3.1875rem;
  object-fit: cover;
  margin-right: 0.6875rem;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
}

.username {
  font-size: $mobile-title;
  line-height: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.125rem;
}

.fileContainer {
  margin-left: 1rem;
  margin-right: 1.5rem;
  align-self: center;
}

.text {
  font-size: $mobile-title;
  line-height: 1.25rem;
  font-weight: 400;
  margin: 0;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fileSize {
  font-size: $xsmall;
  font-weight: 400;
  margin: 0;
}

.closeButton {
  margin-left: 1.375rem;
  color: var(--primary-text);
}

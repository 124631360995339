@import "../../../constants";
@import "../../../styles/mixins";
@import "../../../styles/sizes";

.payment-method {
  direction: ltr;
  width: 100%;
  color: var(--primary-text);

  .payment-method__content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
    border: solid 1px $light-gray-1;
    padding: 1.5rem 1rem;
  }

  .payment-method__title {
    margin-top: 0;
    margin-bottom: 14px;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    line-height: 1.5;
  }

  .payment-method__payment-logo {
    width: 64px;
    height: 32px;
    object-fit: contain;
    font-size: $xxxxsmall;
  }

  .payment-method__card-wrapper {
    display: flex;
    align-items: center;
    font-size: $xsmall;
    line-height: 1.42;
  }

  .payment-method__card-info-wrapper {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
  }

  .payment-method__card-holder {
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: bold;
    line-height: 1.5;
  }

  .payment-method__expiration {
    margin-top: auto;
    margin-left: auto;
  }

  .payment-method__replace-wrapper {
    margin-top: 10px;
  }
}

@include rtl-direction {
  .payment-method__title {
    direction: rtl;
  }
}

@import "../../../../../../constants";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.title {
  font-size: $moderate-small-2;
}

.subtitle {
  font-size: $mobile-title;
  color: $c-gray-60;
}

@import "../../../../../constants";

.itemsWrapper {
  --items-br: 0.875rem;

  max-width: 25rem;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: $small;
  list-style-type: none;
  background-color: rgba(242, 242, 242, 0.9);
  border-top-right-radius: var(--items-br);
  border-top-left-radius: var(--items-br);
  box-shadow: 2rem 2rem 2rem 2rem var(--drop-shadow-secondary);
}

@supports (backdrop-filter: blur(1.7rem)) {
  .itemsWrapper {
    background-color: transparent;
    backdrop-filter: blur(1.7rem);
  }
}

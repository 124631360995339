@import "../../../constants";

.linkify-link {
  color: $c-pink-mention-2;

  &:hover {
    opacity: 0.9;
  }

  &:visited {
    color: $c-pink-mention-2;
  }
}

@import "../../../../../../../../../../constants";
@import "../../../../../../../../../../styles/sizes";

.joinButton {
  margin-top: 1.75rem;
  padding: 0 0.875rem;
  width: 100%;

  @include tablet {
    margin-top: 1.625rem;
  }
}

.joinHint {
  font-size: $small;
  margin: 0.5rem 0 0;
}

@import "../../../../../constants.scss";
@import "../../../../../styles/sizes";

.votes-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  &.compact-card {
    flex-direction: row;
    justify-content: space-between;
  }

  .what-is-your-vote {
    text-align: center;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .votes-columns-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    &.compact-card {
      justify-content: flex-start;
      > div {
        margin-right: 0.3rem;
      }
    }

    .vote-column {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
      color: $gray;
      padding-bottom: 0.5rem;

      &.compact-card {
        flex-direction: row;
        font-size: $xxsmall;
        color: $light-gray-2;
      }

      button {
        background-color: transparent;
        border: 1px solid $gray;
        border-radius: 1.25rem;
        width: 6rem;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 2px 4px 0 $shadow-1;
        cursor: pointer;
        &.compact {
          border: none;
          box-shadow: none;
          width: 2rem;
          height: unset;
        }
        &.compact-card {
          width: 2rem;
        }
        &.disabled {
          pointer-events: none;
        }
        &:hover {
          opacity: 0.9;
        }
      }
      &.voted {
        border-bottom: 3px solid $purple;
      }
    }
  }

  .user-avatar {
    border: 0.2rem solid;
    width: 1.5rem;
    height: 1.5rem;

    &.approve {
      border-color: $green;
    }
    &.reject {
      border-color: $red;
    }
    &.abstain {
      border-color: $gray;
    }

    &.hidden {
      visibility: hidden;
    }
  }
}

@include big-phone {
  .votes-wrapper {
    .votes-columns-container {
      .vote-column {
        button {
          width: 5rem;
        }
      }
    }
  }
}

@mixin rtl-direction {
  body.direction--rtl {
    @content;
  }
}

@mixin flex-list-with-gap($gap) {
  width: calc(100% + #{$gap});
  margin: -#{$gap} 0 0 -#{$gap};
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  & > * {
    margin: $gap 0 0 $gap;
  }
}

@mixin lightTheme {
  :root[data-theme="light"] {
    @content;
  }
}

@mixin darkTheme {
  :root[data-theme="dark"] {
    @content;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.modal {
  max-width: 31.875rem;
  width: 100%;
  max-height: 33.75rem;
  min-height: 24rem;
  border-radius: 0;
  box-shadow: 0 0.25rem 0.9375rem var(--drop-shadow);

  :global(.modal__header-wrapper--with-modal-padding) {
    .modalHeader {
      justify-content: flex-start;
    }

    .modalTitle {
      margin: 0;
      font-family: PoppinsSans, sans-serif;
      font-weight: 600;
      font-size: 1.25rem;
      color: var(--primary-text);
      text-align: left;
      word-break: break-word;
    }
  }

  .modalContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-sizing: border-box;
  }

  .modalCloseWrapper {
    top: 1.7rem;
    margin: 0;

    @include tablet {
      top: 1.1rem;
    }
  }

  @include tablet {
    max-width: unset;
    max-height: unset;
  }
}

.submitButtonWrapper {
  margin-top: auto;
  padding-top: 1.5rem;
  display: flex;
  justify-content: flex-end;
}

.submitButton {
  --btn-w: 100%;

  max-width: 9.75rem;

  @include tablet {
    max-width: 100%;
  }
}

.loader {
  margin: 0 auto;
}

@import "../../../../../../constants";

.modal {
  max-width: 30rem;
}

.buttonsWrapper {
  display: flex;
  width: fit-content;
  margin-left: auto;
  margin-top: 3.25rem;
}

.deleteButton {
  margin-left: 1.375rem;
}

.error {
  font-size: $xsmall;
}

@import "../../../../../../constants";

.about-tab-common-rules {
  display: flex;
  flex-direction: column;
}

.about-tab-common-rules__title {
  margin: 0 0 1.5rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $moderate-med;
}

.about-tab-common-rules__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.about-tab-common-rules__list-item {
  margin-bottom: 1.125rem;

  &:last-child {
    margin-bottom: 0;
  }
}
.about-tab-common-rules__list-item--rtl {
  text-align: right;
  direction: rtl;
}

.about-tab-common-rules__list-item-title {
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
}

.about-tab-common-rules__list-item-description {
  margin: 0;
  font-size: $small;
  line-height: 120%;
  letter-spacing: 0.01em;
  white-space: pre-line;
}

.about-tab-common-rules__see-more-button {
  margin-top: 0.5rem;
  text-align: right;
  text-decoration: underline;
  color: $secondary-blue;
}

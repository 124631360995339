@import "../../../../constants";

.custom-phone-input {
  display: flex;
  flex-direction: column;

  .custom-phone-input__wrapper {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    font-family: PoppinsSans, sans-serif;
    font-size: $small;
    font-weight: 600;
    color: $secondary-blue;
    border: 1px solid var(--gentle-stroke);
    border-radius: 1.75rem;
    background-color: $white;
    box-shadow: 0 3px 8px 0 var(--drop-shadow);
    box-sizing: border-box;
  }

  .custom-phone-input__select {
    max-width: 5.438rem;
  }

  .custom-phone-input__input {
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    border: 0;
    border-left: 1px solid var(--gentle-stroke);
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;
    background-color: transparent;
    box-sizing: border-box;
  }
}

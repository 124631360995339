@import "../../../../../../../../../../constants";
@import "../../../../../../../../../../styles/sizes";

.item {
  --main-color: #{$c-neutrals-600};

  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;

  @include tablet {
    align-items: center;
    text-align: center;
  }
}
.itemDisabled {
  --main-color: #{$c-neutrals-300};
}

.image {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  object-fit: cover;
  box-sizing: border-box;
}

.title {
  width: 100%;
  margin-top: 1rem;
  font-weight: bold;
  font-size: $small;
  color: var(--main-color);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.tooltipContent {
  max-width: 20rem;
  z-index: 3;
  background-color: var(--primary-background);
}

@import "../../../../../../../constants";

.container {
  flex-shrink: 0;
  display: flex;
}

.inboxIcon,
.blocksIcon {
  color: inherit;
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 0.75rem;
}

.list {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;

  & > * {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

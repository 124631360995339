@import "../../../constants";
@import "../../../styles/sizes";

.container {
  margin-top: 2.125rem;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.mainContentContainer {
  position: relative;
  width: 100%;
  flex: 0;
}

.imageContainer {
  display: flex;
  margin-top: 1rem;
}

.imageContainerSingleImage {
  margin-top: 0;
}

.image {
  flex-shrink: 0;
  max-height: 13.125rem;
  border-radius: 0.25rem;
  object-fit: cover;
}

.singleImage {
  max-width: 100%;
  max-height: 31.25rem;
  border-radius: 0.25rem;
  margin: auto;
}

.leftItem {
  margin-right: 0.15625rem;
  margin-left: 0;
}

.rightItem {
  margin-left: 0.15625rem;
  margin-right: 0;
}

.seeAllGallery {
  font-size: $xsmall;
  line-height: 1.25rem;
  text-decoration: underline;
  margin-top: 2rem;
}

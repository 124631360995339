@import "../../../../constants";

.container {
  background-color: transparent;
  box-sizing: border-box;
}
.containerWithCardStyles {
  padding: 1.125rem 1.25rem;
  background-color: var(--primary-background);
  box-shadow: 0 0.1875rem 0.5rem var(--drop-shadow);
}

@import "../../../constants";

.progress-bar__wrapper {
  height: 0.8rem;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .progress-bar__container {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    background-color: $light-gray-1;
    margin-right: 0.5rem;
    position: relative;

    .progress-bar__filler {
      height: 100%;
      border-radius: inherit;

      &.completed {
        background: linear-gradient(90deg, #99ca80 0%, #6ee569 99.05%);
      }

      &.incompleted {
        background: linear-gradient(90deg, #ca8080 0%, #e56969 99.05%);
      }

      &.neutral {
        background: linear-gradient(90deg, #b8b8b8 0%, #d5d5d5 99.05%);
      }
    }

    .progress-bar__serif-mark {
      position: absolute;
      top: -3.7px;
      transform: translateX(-50%);
    }
  }

  .progress-bar__completed-caption {
    font-size: $xxsmall;
  }
}

@import "../../../../../../../constants";

.container {
  --item-pl: var(--sidenav-content-ph);
  --item-pr: var(--item-pl);

  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: inherit;
}

.list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

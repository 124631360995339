@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.container {
  display: flex;
  flex-direction: column;
}

.key {
  margin-bottom: 0.1875rem;
  font-size: $small;
  line-height: 120%;
  letter-spacing: 0.01em;
  white-space: nowrap;
  color: $c-neutrals-300;
}

.value {
  margin: 0;
  font-family: Lexend, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: $moderate-med;
  line-height: 121%;
  color: $c-neutrals-600;

  @include tablet {
    font-size: $moderate;
    line-height: 83%;
  }
}

@import "../../../../../../../../constants";
@import "../../../../../../../../styles/sizes";

.create-common-review-main-info {
  display: flex;
  justify-content: space-between;

  @include big-phone {
    justify-content: center;
  }

  .create-common-review-main-info__common-name {
    margin: 0;
    font-family: NotoSerifSC-Bold, serif;
    font-size: $moderate-small;
    font-weight: bold;
    line-height: 1.4;
  }

  .create-common-review-main-info__tagline {
    margin: 0.5rem 0 0;
    font-size: $small;
    line-height: 1.5;
  }

  .create-common-review-main-info__half {
    flex: 1 0;
    margin-right: 1rem;
    display: flex;
    justify-content: flex-end;

    &:last-child {
      margin-right: 0;
      margin-left: 1rem;
      justify-content: flex-start;
    }

    @include viewport-breakpoint(300px) {
      margin-right: 0.25rem;

      &:last-child {
        margin-left: 0.25rem;
      }
    }
  }

  .create-common-review-main-info__part-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    word-break: keep-all;

    @include big-phone {
      white-space: initial;
      word-break: break-word;
    }
  }

  .create-common-review-main-info__part-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    word-break: keep-all;

    @include big-phone {
      white-space: initial;
      word-break: break-word;
    }
  }

  .create-common-review-main-info__value {
    margin: 0.375rem 0 0.5rem;
    font-family: PoppinsSans, sans-serif;
    font-size: $moderate-med;
    font-weight: bold;
    line-height: 0.96;

    @include big-phone {
      margin: 0;
      font-size: $moderate-med-3;
      line-height: 1.27;
    }
  }

  .create-common-review-main-info__value-text {
    font-size: $small;
    line-height: 1.5;
    color: $light-gray-2;

    @include big-phone {
      font-family: PoppinsSans, sans-serif;
      font-size: $xxsmall;
      font-weight: 600;
      line-height: normal;
    }
  }

  .create-common-review-main-info__date {
    display: block;
    font-family: PoppinsSans, sans-serif;
    font-weight: normal;
    font-size: $xxsmall;
  }
}

@import "../../../constants";

.desktopStyleMenu {
  top: 50%;
  left: 50%;
  right: unset;
  z-index: 1;
  min-width: 13.75rem;
  transform: translateX(-50%);
}

@import "../../../constants";

.button-link {
  display: inline-block;
  color: var(--primary-fill);

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 4.5rem;
  justify-content: space-between;
  width: 100%;
  background-color: var(--primary-background);
  padding: 1rem var(--feed-card-ph, 1.5rem);
  box-sizing: border-box;

  @include tablet {
    padding: unset;
  }
}

.text {
  margin: 0;
}

.title {
  font-size: $moderate;
  color: var(--primary-text);
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
  font-family: Lexend, sans-serif;
}

.subtitle {
  font-size: $moderate;
  font-family: Lexend, sans-serif;
  line-height: 120%;
  color: $c-neutrals-300;
  margin-bottom: 0.5rem;
}

.description {
  font-size: $small;
  color: $c-neutrals-600;
  line-height: 1.25rem;
  margin-bottom: 0.625rem;
}

.descriptionShortened {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.seeMore {
  color: $purple-2;
  width: fit-content;
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

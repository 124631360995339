@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.update-governance-confirmation-success {
  width: 100%;
  max-width: 23.75rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .update-governance-confirmation-success__title {
    margin: 2.625rem 0 1rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: bold;
    text-align: center;

    @include big-phone {
      margin: 2.5rem 0 3rem;
    }
  }

  .update-governance-confirmation-success__sub-title {
    margin: 0;
    font-size: $xsmall;
    text-align: center;
  }

  .update-governance-confirmation-success__buttons {
    display: flex;
    justify-content: center;
    padding-top: 1.75rem;

    @include big-phone {
      padding: 1.5rem 0 0;
    }
  }

  .update-governance-confirmation-success__button-wrapper {
    max-width: 10.75rem;
    width: 100%;

    @include big-phone {
      max-width: unset;
    }
  }

  .update-governance-confirmation-success__continue-button {
    @extend .update-governance-confirmation-success__button-wrapper;

    @include big-phone {
      margin-top: 1rem;
    }
  }
}
.update-governance-confirmation-success__title-logo {
  width: 5.5rem;
  height: 2.5rem;
  margin: 0.75rem 0;
}

.update-governance-confirmation-success__image {
  width: 14.75rem;
  height: 14.75rem;

  @include big-phone {
    max-width: 13.25rem;
    width: 100%;
    max-height: 13.25rem;
    height: 100%;
    margin-top: 0.625rem;
  }
}

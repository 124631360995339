@import "../../../../../constants";

$big-phone-breakpoint: 460px;
$phone-breakpoint: 415px;

.container {
  position: absolute;
  bottom: 1.5625rem;
  right: 0.5625rem;
  transform: translateY(50%);
  width: fit-content;
}

.pickerContainer {
  position: absolute;
  bottom: 3.25rem;
  right: 0.5625rem;

  @media only screen and (max-width: $big-phone-breakpoint) {
    right: -1.5rem; // Picker would be at the left corner of send button icon
  }

  @media only screen and (max-width: $phone-breakpoint) {
    right: -4.5rem; // Picker would be at the right corner of send button icon
  }
}

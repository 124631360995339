@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.container {
  display: flex;
  flex: 1 0;
  min-height: 4.5rem;
  justify-content: space-between;
  width: 100%;
  background-color: var(--primary-background);
  padding: 1rem var(--feed-card-ph, 1.5rem);
  box-sizing: border-box;

  @include tablet {
    padding: unset;
  }
}

.content {
  display: flex;
  align-items: center;
  position: relative;
}

.avatar {
  margin-right: 0.5rem;
  cursor: pointer;
}

.text {
  margin: 0;
}

.title {
  font-size: $small;
  color: var(--primary-text);
  font-weight: 500;
  line-height: 1.3125rem;
  word-break: break-word;
  cursor: pointer;
}

.createdAt {
  font-size: $xsmall;
  color: $c-neutrals-300;
  font-weight: 400;
  line-height: 1.25rem;
  word-break: break-word;
}

.entityType {
  font-size: $small;
  color: $c-neutrals-300;
  line-height: 1.375rem;
  text-align: right;
}

.visibility {
  font-size: $xsmall;
  color: $c-neutrals-300;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0.02em;
  text-align: right;
}

.threeDotMenu {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 1.5rem;
  background-color: transparent;
  color: var(--primary-text);
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.modal {
  max-width: 31.875rem;
}

.buttonsWrapper {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;

  @include tablet {
    flex-direction: column;
    margin-top: 100%;
  }
}

.button {
  width: 10rem;

  @include tablet {
    width: 100%;
  }
}

.unlinkButton {
  margin-left: 1.5rem;

  @include tablet {
    margin-left: unset;
    margin-top: 1rem;
  }
}

.errorText {
  font-size: $xsmall;
  color: $red;
}

@import "../../../../../../../../constants";

.list {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  color: var(--primary-fill);
  list-style-type: none;
}

.listItem {
  margin-bottom: 1rem;
  line-height: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  display: flex;
  font-size: $small;
  text-decoration: none;
  color: var(--primary-fill);

  &:hover {
    text-decoration: underline;
  }
}

.linkIconWrapper {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkIcon {
  width: 1rem;
  height: 1rem;
  color: inherit;
}

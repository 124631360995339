@import "../../../../../constants";

.login-error {
  max-width: 20.5rem;
  width: 100%;
  padding: 0.625rem 0.75rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $xsmall;
  letter-spacing: 0.28px;
  color: $red;
  text-align: center;
  border-radius: 0.5rem;
  background-color: $secondary-red;
  box-sizing: border-box;
}

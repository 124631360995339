@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.projectsTree {
  box-sizing: border-box;
}

.projectsTreeItemTriggerClassName {
  --item-pl-per-level: 1.25rem;
  --item-arrow-pl: 0.5rem;

  height: 3rem;
  border-radius: 0;

  &:hover {
    --bg-color: #{var(--hover-fill)};
  }
}
.projectsTreeItemTriggerActiveClassName {
  --bg-color: #{var(--secondary-background)};
  &:hover {
    --bg-color: #{var(--hover-fill)};
  }
}

.projectsTreeItemTriggerNameClassName {
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
}

.projectsTreeItemTriggerImageClassName {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}
.projectsTreeItemTriggerImageNonRoundedClassName {
  border-radius: 0.375rem;
}

.loader {
  margin: 1rem auto 0;
  display: block;
}

.createCommonButton {
  width: 100%;
  padding-left: 2.125rem;
  padding-right: 0.875rem;
}

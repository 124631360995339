@import "../../../../../constants.scss";

.container {
  position: absolute;
  bottom: 5.25rem;
  left: 2.875rem;
  width: 20.125rem;
  max-height: 22.75rem;
  background: var(--tertiary-fill);
  color: var(--primary-text);
  border-radius: 0.25rem;
  box-shadow: 0 0.0625rem 0.3125rem var(--drop-shadow);
  padding: 0;
  margin: 0;
  overflow-y: auto;
}

.content {
  padding: 0.375rem 1.75rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 0.0625rem solid var(--gentle-stroke);

  &:hover {
    cursor: pointer;
    background-color: var(--hover-fill);
  }

  &:focus {
    background-color: var(--hover-fill);
    outline: none;
  }
}

.userAvatar {
  margin-right: 0.5625rem;
}

.userName {
  font-weight: 500;
  font-size: $mobile-title;
}

.loader {
  margin: auto;
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
}

.image {
  max-width: 11rem;
  width: 100%;
  margin-bottom: 2.375rem;
}

.title {
  margin: 0;
  padding: 0;
  font-family: Lexend, sans-serif;
  font-weight: normal;
  font-size: $moderate-med;
  color: $c-neutrals-600;
  letter-spacing: 0.02em;
  max-width: 20.5rem;
}

.description {
  max-width: 30.125rem;
  width: 100%;
  margin: 0.5rem 4.5rem 1.5rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
  line-height: 150%;
  color: $c-neutrals-300;
}

.error {
  font-size: $xsmall;
}

.button {
  max-width: 13.1875rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: $purple;
  color: $white;
  margin-top: 4.875rem;
}

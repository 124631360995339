@import "../../../constants";
@import "../../../styles/mixins";
@import "../../../styles/sizes";

.footer {
  flex-shrink: 0;
  z-index: var(--footer-z-index, unset);
  height: var(--footer-height, 4.625rem);
  display: flex;
  font-family: PoppinsSans, sans-serif;
  font-size: $xxsmall;
  color: $c-neutrals-300;
  background-color: $c-notification-100;
  border-top: 0.0625rem solid $c-neutrals-200;
  box-sizing: border-box;
  overflow: hidden;
}
.footerDefault {
  padding-left: 8.125rem;
  padding-right: 7.5rem;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.footerSmall {
  padding: 0.5rem 1rem 0.875rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.commonLogo {
  flex-shrink: 0;
  height: 1.5rem;
}

.content {
  @include flex-list-with-gap(1rem);

  align-items: center;
}

.poweredByInfo {
  display: flex;
  align-items: center;
}

.daoStackLogo {
  margin: 0 0.375rem;
}

.copyrightInfo {
  display: flex;
  align-items: center;
  font-weight: 300;
}

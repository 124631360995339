@import "../../../../../../constants";

.link {
  color: var(--mention-secondary);

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

@import "../../../../../constants";
@import "../../../../../styles/mixins";

$custom-toggle-button-border-radius: 0.5rem;

.custom-toggle-button {
  flex: 1 0;
  padding: 10px;
  border: 1px solid $light-gray-1;
  background-color: $white;
  font-family: inherit;
  font-size: inherit;
  text-align: center;
  color: var(--primary-fill);
  box-sizing: border-box;
  cursor: pointer;

  &:first-child {
    border-top-left-radius: $custom-toggle-button-border-radius;
    border-bottom-left-radius: $custom-toggle-button-border-radius;
  }
  &:last-child {
    border-top-right-radius: $custom-toggle-button-border-radius;
    border-bottom-right-radius: $custom-toggle-button-border-radius;
  }

  &:hover {
    background-color: $light-gray-5;
  }

  &:active {
    background-color: $light-gray-6;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
.custom-toggle-button--active {
  border: var(--primary-fill);
  background-color: var(--primary-fill);
  color: $white;

  &:hover,
  &:active {
    background-color: var(--primary-fill);
  }
}
.custom-toggle-button--vertical {
  margin-top: 1.5rem;
  border-radius: $custom-toggle-button-border-radius;

  &:first-child {
    margin-top: 0;
  }
}

@include rtl-direction {
  .custom-toggle-button {
    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: $custom-toggle-button-border-radius;
      border-bottom-right-radius: $custom-toggle-button-border-radius;
    }
    &:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: $custom-toggle-button-border-radius;
      border-bottom-left-radius: $custom-toggle-button-border-radius;
    }
  }
  .custom-toggle-button--vertical {
    &:first-child,
    &:last-child {
      border-radius: $custom-toggle-button-border-radius;
    }
  }
}

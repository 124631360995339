@import "../../../../../../constants";

.container {
  display: flex;
  flex: 1;
  min-height: 4.5rem;
  width: 100%;
  background-color: $white;
  box-shadow: 0px 3px 8px $shadow-2;
  padding: 1rem 1.5625rem 1rem 1.4375rem;
  box-sizing: border-box;
  border-bottom: 0.0625rem solid $c-neutrals-200;
}

.createdAt {
  font-size: $xsmall;
  color: $c-neutrals-300;
  font-weight: 400;
  line-height: 1.25rem;
}

.avatarContainer {
  display: flex;
  margin-right: 0.75rem;
}

.avatarContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
}

.avatarContentMultiple {
  border: 1.5px solid $white;
  box-sizing: border-box;
  border-radius: 50%;
  margin-left: -1.25rem;
  z-index: 2;
}

.avatarMultiple {
  width: 2.40625rem;
  height: 2.40625rem;
}

.text {
  margin: 0;
}

.title {
  font-size: $small;
  color: $c-neutrals-600;
  line-height: 1.25rem;
  margin-bottom: 0.5rem;
  font-family: Lexend, sans-serif;
}

.titleShortened {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.seeMore {
  color: $purple-2;
  width: fit-content;
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
}

.noCommonsInfoContainer {
  padding: 0 1rem;
}

.noCommonsText {
  margin: 0 0 1rem;
  padding: 0;
  text-align: center;
  color: var(--primary-text);
}

.createCommonButton {
  margin: 0 auto;
}

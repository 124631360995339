@import "../../../../../../constants";

.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.75rem;
  border-radius: 0.625rem;
  font-size: $xxsmall-2;
  font-weight: 600;
  color: $c-neutrals-100;
  height: 1.375rem;
}

.unreadMessages {
  min-width: 1.4375rem;
  background-color: var(--highlight);
}

.unreadMessagesLong {
  width: 1.9375rem;
}

.proposal {
  background-color: var(--secondary-background);
  padding: 0 0.5rem;
  color: $gray;
}

.new {
  width: 2.5rem;
  background-color: var(--highlight);
}

.tagActive {
  background-color: var(--highlight);
  color: $white;
}

.pin {
  color: var(--highlight);
  margin-left: 0.75rem;
}

.starIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.75rem;
  color: var(--highlight);
}

.unseen {
  width: 1.4375rem;
  border-radius: 50%;
  background-color: var(--highlight);
}

.hasUnseenMention {
  color: var(--highlight);
  margin-left: 0.75rem;
}

@import "../../../../../../../constants";

.item {
  --item-color: var(--primary-text);
  --item-bg-color: var(--tertiary-fill);

  margin: 0;
  padding: 1.125rem 1.5rem;
  text-decoration: none;
  color: var(--item-color);
  background-color: var(--item-bg-color);
  border: 0;
  border-bottom: 0.0625rem solid var(--secondary-fill);
  font-family: PoppinsSans, sans-serif;
  font-weight: 600;
  font-size: $small;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.2s;
  user-select: none;

  &:first-child {
    border-top-left-radius: var(--items-br);
    border-top-right-radius: var(--items-br);
  }
}
.itemActive {
  --item-bg-color: #{var(--hover-fill)};
}

.itemWithWarning {
  --item-color: #{$c-error-300};
}

@import "../../../../../../constants.scss";

.container {
  display: flex;
  flex-direction: row;
  background-color: var(--secondary-background);
  padding: 0 1rem;
}

.wrapper {
  overflow-x: auto;
  display: flex;
  width: 100%;
}

.filePreviewWrapper {
  position: relative;
  margin: 1rem 0.5rem 0;
}

.filePreview {
  height: 6.25rem;
  width: 6.25rem;
  object-fit: cover;
  border-radius: 0.25rem;
}

.filePreviewContainer {
  margin-left: 0;
}

.closeButton {
  position: absolute;
  top: -0.375rem;
  left: -0.3125rem;
  background-color: var(--primary-text);
  color: var(--primary-background);
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  border: 0.0625rem solid var(--gentle-stroke);
}

@import "../../../../../constants";

.editor {
  --text-editor-h: auto;
  height: var(--text-editor-h);
  padding: 0.75rem;
  font-family: PoppinsSans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: var(--text-editor-elements-fs);
  line-height: 120%;
  color: var(--primary-text);
  overflow-y: auto;
  border: var(--text-editor-border);
  box-sizing: border-box;

  & [dir="rtl"] {
    font-family: Heebo, sans-serif;
    direction: rtl;
    text-align: right;
  }

  &:focus {
    outline: $transparent;
  }
}

.editorSmallSize {
  --text-editor-h: 4.5rem;
}

.editorBigSize {
  --text-editor-h: 8.625rem;
}

.editorAutoSize {
  --text-editor-h: auto;
  max-height: 50vh;
  min-height: 4.5rem;
  background-color: var(--secondary-background);
}

.editorReadOnly {
  --text-editor-h: auto;

  padding: 0;
  overflow-y: unset;
}

@import "../../../../../../constants";

.button {
  padding: 0.375rem 0.5rem;
  display: flex;
  align-items: center;
  font-family: PoppinsSans-SemiBold, sans-serif;
  font-size: $small;
  color: $c-neutrals-600;
  text-align: center;
  line-height: 100%;
  letter-spacing: 0.01em;
  background-color: var(--secondary-background);
  border: 0;
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;
  box-sizing: border-box;

  &:hover {
    background-color: var(--hover-fill);
  }
}

.icon {
  margin-left: 0.75rem;
  color: $c-neutrals-600;
  transform: rotate(90deg);
}

@import "../../../../../constants";
@import "../../../../../styles/sizes";

.container {
  --sidenav-content-ph: 1.125rem;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include tablet {
    --sidenav-content-ph: 1.25rem;
  }
}

.separator {
  flex-shrink: 0;
  height: 0.0625rem;
  margin: 0 var(--sidenav-content-ph);
  background-color: $c-neutrals-200;
}

.footer {
  margin-top: auto;
}

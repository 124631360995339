@import "../../../../../../../../../constants";

.contextMenuList {
  width: 16.25rem;
  max-height: 23.625rem;
  overflow-y: auto;
  background-color: var((--primary-background));
  border-radius: var(--items-br);
}

.contextMenuItem {
  padding: 1rem 1.375rem 1rem 0.75rem;
  display: flex;
  align-items: center;
  font-size: $moderate-xsmall;
}
.contextMenuItemWithoutMembership {
  color: $c-gray-30;
}
.contextMenuItemToAddProject {
  color: var(--primary-fill);
}

@import "../../../../../../../../../../constants";

.button {
  margin: 0;
  padding: 0 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: PoppinsSans, sans-serif;
  font-size: $xsmall;
  font-weight: normal;
  color: var(--primary-text);
  line-height: 143%;
  letter-spacing: 0.02em;
  background-color: var(--secondary-background);
  border: 0;
  border-radius: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s;
  box-sizing: border-box;

  &:hover {
    background-color: var(--hover-fill);
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.arrowIcon {
  flex-shrink: 0;
  width: 1.375rem;
  height: 1.375rem;
  margin-left: 0.5rem;
  transform: rotate(-90deg);
}

.desktopMenuItems {
  right: unset;
  left: 0;
}

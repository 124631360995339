@import "../../../constants";

.custom-button {
  height: 3rem;
  padding: 0 1.25rem;
  font-family: PoppinsSans, sans-serif;
  font-size: $small;
  font-weight: normal;
  text-align: center;
  border: none;
  border-radius: 6.25rem;
  transition: all 0.2s;
  overflow: hidden;
  user-select: none;

  &:hover {
    cursor: pointer;
  }
}
.custom-button--full-width {
  width: 100%;
}
.custom-button--disabled,
.custom-button--disabled:disabled {
  background-color: $light-gray-3;
  color: $light-gray-2;

  &:hover {
    cursor: not-allowed;
  }
}
.custom-button--primary {
  background-color: $purple;
  color: $white;

  &:hover,
  &:active,
  &:focus {
    background-color: $purple-2;
  }
}
.custom-button--secondary {
  border: 1px solid $light-gray-1;
  background-color: $white;
  color: $secondary-blue;

  &:hover,
  &:active,
  &:focus {
    background-color: $light-gray-1;
  }
}
.custom-button--secondary-purple {
  border: 1px solid $purple;
  background-color: $white;
  color: $purple;

  &:hover,
  &:active,
  &:focus {
    border-color: $purple-2;
    background-color: $purple-2;
    color: $white;
  }
}
.custom-button--shadowed {
  box-shadow: 0 3px 8px $shadow-2;
}

@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.remove-circle-configuration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remove-circle-configuration__stage-name {
  max-width: unset;
  width: unset;
  margin-top: 0.75rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  @include big-phone {
    margin-top: 1.5rem;
  }
}

.remove-circle-configuration__avatar-icon {
  flex-shrink: 0;
}

.remove-circle-configuration__form {
  max-width: 36.5rem;
  width: 100%;
}

.remove-circle-configuration__circle-dropdown {
  z-index: $dropdown-z-index + 1;
}

.remove-circle-configuration__member-autocomplete {
  z-index: $dropdown-z-index;
  margin-top: 1.5rem;

  .remove-circle-configuration__member-info {
    font-weight: normal;
    font-size: $xsmall;
    line-height: 120%;

    @include big-phone {
      font-weight: 600;
      font-size: $small;
      line-height: 100%;
    }
  }
}

.remove-circle-configuration__input {
  margin-top: 1.5rem;
}

.remove-circle-configuration__input-label {
  font-size: $xsmall;
}

.remove-circle-configuration__info-text {
  margin: 2rem 0 0;
  font-size: $small;
  color: $light-gray-2;
  text-align: center;
}

.remove-circle-configuration__modal-footer {
  display: flex;
  justify-content: center;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.remove-circle-configuration__submit-button {
  max-width: 13.125rem;

  @include big-phone {
    max-width: unset;
  }
}

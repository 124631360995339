@import "../../../../../../../constants";
@import "../../../../../../../styles/sizes";

.update-common-confirmation-processing {
  width: 100%;
  max-width: 36.5rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .update-common-confirmation-processing__image {
    width: 14.75rem;
    height: 14.75rem;

    @include big-phone {
      max-width: 23.375rem;
      width: 100%;
      max-height: 23.375rem;
      height: 100%;
    }
  }

  .update-common-confirmation-processing__title {
    margin: 2.625rem 0 5rem;
    font-family: NotoSerifSC, serif;
    font-size: $moderate-med;
    font-weight: bold;
    text-align: center;

    @include big-phone {
      margin: 1rem 0 5.625rem;
    }
  }

  .update-common-confirmation-processing__loader {
    margin-bottom: 5.375rem;

    @include big-phone {
      margin-bottom: 0;
    }
  }
}

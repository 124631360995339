@import "../../../../constants.scss";

.common-list-wrapper {
  padding-top: 40px;
  padding-bottom: 44px;

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    .page-title {
      margin: 0;
    }
  }

  .common-list {
    display: grid;
    justify-items: center;
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  .loader-container {
    line-height: 40px;
    margin-top: 54px;
    margin-bottom: 0;
    .loading-btn {
      margin: auto;
      background-color: $white;
      color: black;
      border: 1px solid $light-gray-1;
      font-family: PoppinsSans, sans-serif;
      font-weight: normal;
      font-weight: unset;
    }
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .common-list-wrapper {
    padding-bottom: 0;
    padding-top: 20px;

    .title-wrapper {
      justify-content: center;
    }

    .loader-container {
      margin-top: 35px;
      margin-bottom: 20px;
    }
  }
}

.boldIcon {
  width: 0.75rem;
  height: 0.875rem;
}

.indentMarkIcon {
  width: 1.125rem;
  height: 0.75rem;
}

.textDirectionMarkIcon {
  width: 1.125rem;
  height: 1rem;
  margin-top: 0.125rem;
}

@import "../../../../constants";
@import "../../../../styles/sizes";

.container {
  flex-shrink: 0;
  min-width: 16.5rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  @include tablet {
    margin: auto;
    min-width: 13.5rem;
    max-width: 27.5rem;
  }
}

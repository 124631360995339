@import "../../../../../constants";

.button {
  padding: 0.3125rem;
  color: $c-neutrals-600;
}

.icon {
  flex-shrink: 0;
  width: 1.125rem;
  height: 0.875rem;
}

@import "../../../../../constants";
@import "../../../../../styles/sizes";

.container {
  --sidenav-content-ph: 1.375rem;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include tablet {
    --sidenav-content-ph: 1.25rem;
  }
}

.commonLogoContainer {
  --logo-top-indent: 1.125rem;
  --logo-bottom-indent: 1.25rem;
  --logo-left-indent: 1.75rem;

  background-color: $c-light-gray-2;

  @include tablet {
    display: none;
  }
}

.commonLogo {
  width: 6.875rem;
  height: 2.125rem;

  @include tablet {
    max-width: 6.125rem;
    height: 1.875rem;
  }
}

.closeIconWrapper {
  margin-left: auto;
  padding: 1.25rem 1.5rem;
}

.separator {
  flex-shrink: 0;
  height: 0.0625rem;
  background-color: $c-light-gray;
}

.userInfoSeparator {
  @extend .separator;

  margin-top: auto;
}

.userInfoContentButton {
  padding: 1rem 0;

  @include tablet {
    padding-left: 0;
    padding-right: 0;
  }
}

.userInfoAvatar {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}

.userInfoName {
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
}

.userInfoRightArrowIcon {
  margin-left: auto;
}

@import "../../../../constants";
@import "../../../../styles/sizes";

.container {
  --main-content-mw: 62%;
  --bottom-tabs-height: 4rem;
  --bottom-tabs-pb: var(--safe-area-inset-bottom);

  flex: 1;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  @include tablet {
    --main-content-mw: 100%;

    padding-bottom: calc(
      3.125rem + var(--bottom-tabs-height) + var(--bottom-tabs-pb)
    );
  }
}

.contentHeaderWrapper {
  margin: 0 0.5rem;
  padding-top: 3rem;
  background-color: var(--page-bg-color);

  @include tablet {
    margin: 0;
    padding-top: 1.25rem;
    background-color: transparent;
  }
}

.commonHeaderSeparator {
  display: none;

  @include tablet {
    width: 100%;
    height: 0.0625rem;
    margin-top: 1rem;
    display: block;
    background-color: $c-neutrals-100;
  }
}

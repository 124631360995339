@import "../../../../constants";
@import "../../../../styles/sizes";

.container {
  --feed-card-ph: 1.5rem;

  position: relative;
  display: flex;
  flex-direction: column;
  color: $c-neutrals-600;
}
.containerActive {
  border: 0.0625rem solid var(--gentle-stroke);
  border-bottom-width: 0.125rem;
  border-top: none;

  @include tablet {
    margin-right: -1.5rem;
  }
}

.containerHovering {
  cursor: pointer;
}

.toggleCard {
  outline: none;
}

.loader {
  align-self: center;
}

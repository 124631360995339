@import "../../../../../constants";
@import "../../../../../styles/sizes";

.close-prompt-wrapper {
  position: absolute;
  z-index: $secondary-modal-z-index;
  width: 100%;
  height: 100%;
  background-color: $fixed-modal-shadow;
  display: flex;
  justify-content: center;
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-background);
    margin: auto;
    border-radius: 12px;
    box-shadow: 0 4px 15px 0 var(--drop-shadow);
    padding: 50px;
    text-align: center;
  }
  .buttons-wrapper {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;

    button {
      margin: 0 0.5rem;
    }
  }
}

@include big-phone {
  .close-prompt-wrapper {
    .content {
      height: 100%;
      box-sizing: border-box;
      padding: 10px;
      border-radius: unset;
    }
    .buttons-wrapper {
      flex-direction: column;

      button {
        margin: 0.5rem 0;
      }
    }
  }
}

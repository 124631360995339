@import "../../../../../../../../../constants";

.container {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.text {
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }
}

@import "../../../../../../../../constants";
@import "../../../../../../../../styles/sizes";

.container {
  display: flex;
  flex-direction: column;
}

.description {
  white-space: pre-line;
}

.descriptionShortened {
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.seeMore {
  color: $c-pink-active-feed-cards;
  width: 100%;
  margin-bottom: 1.75rem;
  display: inline-block;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.tagsWrapper {
  margin-top: 2.125rem;
}

.commonImage {
  flex-shrink: 0;
  max-width: 28.25rem;
  width: 100%;
  height: 18.875rem;
  margin-top: 2.125rem;
  border-radius: 0.25rem;
  object-fit: cover;

  @include phone {
    height: 13rem;
    margin-top: 1rem;
  }
}

.commonLinks {
  margin-top: 2.125rem;
}

@import "../../../../constants";

.container {
  width: 100%;
}

.label {
  display: inline-flex;
  font-size: $xsmall;
  color: $c-neutrals-600;
  margin-bottom: 0.5rem;
  line-height: 100%;
}

.selectDropdownIcon {
  transform: rotate(90deg);
  width: 1rem;
  height: 1rem;
  color: $c-neutrals-600;
}

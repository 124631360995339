@import "../../../../constants";
@import "../../../../styles/mixins";
@import "../../../../styles/sizes";

.container {
  &:hover {
    .emojiButton {
      opacity: 1;
    }
  }
}

.message {
  display: flex;
  position: relative;
  margin-bottom: 1rem;
}

.messageCurrentUser {
  justify-content: flex-end;
}

.iconContainerFilePreviewCurrentUser {
  background-color: $white;
}

.iconWrapper {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: $white;
  background-color: #4ce2f1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  cursor: pointer;

  img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.userAvatarContainer {
  width: 100%;
  height: 100%;
}

.messageText {
  padding: 0.5rem 0rem;
  border-radius: 0.875rem;
  background-color: var(--secondary-background);
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  text-align: start;
  font-size: $small;
  color: var(--primary-text);
  max-width: 33.563rem;

  &:hover {
    .menuArrowButton {
      display: block;
    }
  }
}

.elementDropdownMenu {
  direction: ltr;
}

.menuArrowButton {
  display: none;
}
.menuArrowButtonVisible {
  display: block;
}

.messageTextWithReply {
  padding: 0.25rem 0rem 0.5rem;
}

.messageTextRtl {
  text-align: right;
  direction: rtl;
}

.messageTextCurrentUser {
  background-color: var(--primary-fill);
  margin-right: unset;

  .mentionText {
    color: var(--mention-secondary);
  }
}

.systemMessageContainer {
  flex-direction: column;
  align-items: center;
}

.systemMessage {
  width: fit-content;
  text-align: center;
  font-size: $xxsmall-2;
  color: $c-gray-60;
  direction: ltr;
  margin: 0 1.25rem;
}

.messageName {
  font-size: $xsmall;
  font-weight: 600;
  margin-right: 2.5rem;
  margin-left: 0.75rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.messageContent {
  word-wrap: break-word;
  white-space: pre-line;
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  word-break: break-word;
}

.messageContentRtl {
  direction: rtl;
  text-align: right;
}

.messageContentCurrentUser {
  color: $white;
  margin-right: 0.75rem;
  margin-top: 0.5rem;
}

.mentionText {
  cursor: pointer;
  color: var(--primary-fill);
}

.mentionTextCurrentUser {
  color: $c-pink-mention-3;
  font-weight: 600;
}

.systemMessageCommonLink {
  color: var(--primary-fill);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.internalLink {
  color: var(--mention-secondary);

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
}

.replyMessageContainer {
  display: flex;
  cursor: pointer;
  background-color: var(--secondary-fill);
  color: var(--primary-text);
  border-radius: 0.625rem;
  padding: 0.5rem 0 0.625rem;
  margin: 0.25rem;
  margin-top: 0;
}

.replyMessageContainerCurrentUser {
  background-color: var(--quaternary-fill);

  .replyMessageName {
    color: $white;
  }

  .mentionText {
    color: var(--mention-secondary);
  }
}

.replyMessageImage {
  height: 2.8125rem;
  width: 2.625rem;
  object-fit: cover;
  margin-left: 0.75rem;
}

.replyMessagesWrapper {
  max-width: 100%;
}

.replyMessageName {
  font-size: $xxsmall;
  font-weight: 600;
  line-height: 1.25rem;
  margin-bottom: 0.125rem;
  color: var(--primary-text);
}

.replyMessageNameWithImage {
  margin-left: 0.5rem;
}

.replyMessageContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: $xxsmall;
  margin-bottom: 0.4rem;
  line-height: 0.8rem;
  max-height: 1.6rem;
  color: $white;
}

.replyMessageContentNotCurrentUser {
  color: var(--primary-text);
}

.replyMessageContentWithImage {
  margin-left: 0.5rem;
}

.replyMessageContentWithFile {
  margin-left: 1rem;
  max-height: 2.5rem;
}

.dropdownMenu {
  position: absolute;
  top: 0.375rem;
  right: 0.5rem;
  z-index: unset;

  .custom-dropdown-wrapper__menu {
    z-index: $dropdown-z-index;
  }
}

.highlighted {
  animation: highlighted 10s forwards;
  border: 0.06rem solid $c-pink-active-feed-cards;
  background-color: $c-pink-active-btn;
}

@include darkTheme {
  .highlighted {
    border: 0;
    background-color: var(--quaternary-fill);
  }
}

.highlightedOwn {
  animation: highlightedOwn 10s forwards;
  border: 0.094rem solid $dark-yellow;
  background-color: var(--primary-fill);
}

.hasReactions {
  margin-bottom: 1rem;
}

@keyframes highlighted {
  100% {
    border-color: transparent;
    background-color: $c-light-gray;
  }
}

@include darkTheme {
  @keyframes highlighted {
    100% {
      border-color: transparent;
      background-color: var(--secondary-background);
    }
  }
}

@keyframes highlightedOwn {
  100% {
    border-color: transparent;
    background-color: $c-pink-active-feed-cards;
  }
}

.fileContainer {
  margin-left: 1.25rem;
  margin-right: 0.25rem;
  align-self: center;
}

.fileTitle {
  font-size: $xxsmall;
  font-weight: 600;
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: 0.125rem;
}

.fileSize {
  font-size: $xxsmall-3;
  font-weight: 400;
  margin: 0;
}

.singleEmojiText {
  font-size: $xlarge;
}

.multipleEmojiText {
  font-size: $large;
}

.reactWithEmoji {
  display: flex;
  align-items: center;
  margin-right: 2.8rem;
}

.reactWithEmojiSelf {
  display: flex;
  align-items: center;
  margin-right: unset;
  margin-left: 2.8rem;
}

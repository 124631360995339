@import "../../../constants";
@import "../../../styles/mixins";

.modal {
  max-width: 31.875rem;

  .modalHeader {
    margin-top: 1rem;
    justify-content: flex-start;
  }

  .modalContent {
    padding: 0 2.5rem 2.5rem 2.5rem;
  }
}

.modalTitle {
  color: var(--primary-text);
  font-family: Poppins, sans-serif;
  font-size: 20px;
  line-height: 120%;
}

.description {
  margin: 0;
  color: var(--primary-text);
  font-family: Poppins, sans-serif;
  font-size: 16px;
  line-height: 20px;
}

.buttonsContainer {
  margin-top: 2.5rem;
}

.buttonsWrapper {
  @include flex-list-with-gap(1.375rem);

  justify-content: flex-end;
}

.button {
  max-width: 9.5rem;
  width: 100%;
}

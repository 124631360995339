@import "../../../../../constants.scss";

.proposal-state-wrapper {
  .state-wrapper {
    .state-inner-wrapper {
      width: 100%;
      margin: 0;
      border-radius: 0.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem;
      img {
        width: 1.063rem;
        height: 1.063rem;
        margin-right: 0.25rem;
      }
      .state-name {
        font-size: $xsmall;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
      }
    }
    &.approved,
    &.withdrawn {
      .state-inner-wrapper {
        background: $light-green;
        .state-name {
          color: $green;
        }
      }
    }
    &.rejected,
    &.canceled {
      .state-inner-wrapper {
        background: $light-red;
        .state-name {
          color: $red;
        }
      }
    }
    &.unclaimed {
      .state-inner-wrapper {
        background: $light-gray-3;
        .state-name {
          color: $blue;
        }
      }
    }
  }
}

@import "../../../constants";
@import "../../../styles/sizes";

.container {
  --main-pl: unset;
  --sb-max-width: unset;
  --sb-width: 15rem;
  --sb-content-max-width: 100%;
  --sb-content-width: 100%;
  --sb-content-pb: 0;
  --sb-bg-color: #{$c-shades-white};
  --sb-shadow: 0.125rem 0 0.375rem #{$c-sidebar-shadow};
  --sb-easing: cubic-bezier(0.16, 1, 0.3, 1);
  --sb-transition-duration: 0.6s;
  --page-bg-color: #{$c-shades-100};
  --scroll-bg-color: #{$c-shades-white};
  --scroll-thumb-color: var(--gentle-stroke);

  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg-color);
  box-sizing: border-box;

  @include tablet {
    --sb-max-width: 100%;
    --sb-width: 100%;
    --sb-content-width: 100%;
  }
}
.containerWithSidenav {
  --main-pl: var(--sb-width);

  @include tablet {
    --main-pl: unset;
  }
}

.sidenavContent {
  margin: 0 auto;
  max-width: 25rem;
}

.main {
  flex: 1;
  padding-left: var(--main-pl);
  display: flex;
  flex-direction: column;
  background-color: var(--page-bg-color);
}

@import "../../../../../../constants";

.popoverContent {
  z-index: 2;
  max-width: 20rem;
  width: 100%;
  max-height: 22.75rem;
  display: flex;
  justify-content: center;
  background-color: var(--primary-background);
  overflow-y: auto;
}

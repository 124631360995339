@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.commonCardClassName {
  margin: 0;
  padding: 0;
}

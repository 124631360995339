@import "../../../../constants";
@import "../../../../styles/sizes";

.custom-tab {
  --tab-ml: 2rem;
  --tab-pb: 1.25rem;
  --tab-inactive-color: #{$light-gray-2};
  --tab-inactive-hover-color: #{$purple};
  --tab-active-color: #{$purple-2};
  --tab-color: var(--tab-inactive-color);
  --tab-bb-color: transparent;
  --tab-fw: bold;
  --tab-fs: #{$moderate};

  margin: 0 0 0 var(--tab-ml);
  padding: 0 0 var(--tab-pb) 0;
  font-family: PoppinsSans, sans-serif;
  font-size: var(--tab-fs);
  font-weight: var(--tab-fw);
  text-align: center;
  color: var(--tab-color);
  background-color: transparent;
  border: 0;
  border-bottom: 0.25rem solid var(--tab-bb-color);
  cursor: pointer;
  transition: color 0.2s, border-bottom-color 0.2s;
  user-select: none;
  -webkit-user-select: none;

  &:active {
    --tab-bb-color: var(--tab-inactive-hover-color);
    --tab-color: var(--tab-inactive-hover-color);
  }

  &:first-child {
    --tab-ml: 0;
  }
}
.custom-tab--default-mobile {
  @include big-phone {
    --tab-ml: 1.5rem;
    --tab-pb: 0.75rem;
  }
}
.custom-tab--active {
  --tab-bb-color: var(--primary-fill);
  --tab-color: var(--primary-fill);

  &:active {
    --tab-bb-color: var(--primary-fill);
    --tab-color: var(--primary-fill);
  }
}
.custom-tab--with-icon {
  --tab-pb: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0;
}

@media (hover: hover) and (pointer: fine) {
  .custom-tab {
    &:hover {
      --tab-color: var(--tab-inactive-hover-color);
    }
  }

  .custom-tab--active {
    &:hover {
      --tab-bb-color: var(--secondary-text);
      --tab-color: var(--secondary-text);
    }
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.projectsTree {
  overflow-y: auto;
  box-sizing: border-box;
}

.projectsTreeItemTriggerClassName {
  --item-pl-per-level: 1.25rem;
  --item-arrow-pl: 0.5rem;

  height: 3rem;
  border-radius: 0;

  &:hover {
    --bg-color: var(--hover-fill);
    --item-text-color: var(--primary-text);
  }

  @media (hover: none) {
    &:hover {
      --bg-color: var(--primary-background);
      --item-text-color: var(--primary-text);
    }
  }
}
.projectsTreeItemTriggerActiveClassName {
  --bg-color: var(--primary-fill);
  --item-text-color: #{$c-shades-white};

  &:hover {
    --bg-color: var(--primary-fill);
  }

  @media (hover: none) {
    &:hover {
      --bg-color: var(--primary-fill);
      --item-text-color: #{$c-shades-white};
    }
  }
}

.projectsTreeItemTriggerNameClassName {
  font-family: PoppinsSans, sans-serif;
  font-weight: 500;
}

.projectsTreeItemTriggerImageClassName {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.875rem;
}
.projectsTreeItemTriggerImageNonRoundedClassName {
  border-radius: 0.375rem;
}

.loader {
  margin: 1rem auto 0;
  display: block;
}

.createCommonButton {
  width: 100%;
  padding-left: 2.125rem;
  padding-right: 0.875rem;
}

.commonsMenuClassName {
  max-height: 15rem;
}

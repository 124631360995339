.linkLeft,
.linkRight {
  white-space: nowrap;
  overflow: hidden;
}

.linkLeft {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.linkRight {
  flex-shrink: 0;
  max-width: 25rem;
  display: flex;
  align-items: center;
}

.ellipsis {
  flex-shrink: 0;
}

.arrowIcon {
  flex-shrink: 0;
}

@import "./constants.scss";
@import "./styles/fonts";

@font-face {
  font-family: NotoSerifSC-Black;
  src: url("/shared/assets/fonts/NotoSerifSC-Black.woff2");
  font-display: swap;
}

@font-face {
  font-family: NotoSerifSC-Bold;
  src: url("/shared/assets/fonts/NotoSerifSC-Bold.woff2");
  font-display: swap;
}

@font-face {
  font-family: NotoSerifSC-SemiBold;
  src: url("/shared/assets/fonts/NotoSerifSC-SemiBold.woff2");
  font-display: swap;
}

@font-face {
  font-family: Assistant-Bold;
  src: url("/shared/assets/fonts/Assistant-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: Assistant-Light;
  src: url("/shared/assets/fonts/Assistant-Light.ttf");
  font-display: swap;
}

html {
  background-color: var(--primary-background);
}

:root {
  --page-extra-pr: 0px;
}

// Remove Arrows/Spinners from input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// Disable highlight when Touch/Press for inputs
input:focus {
  outline: $transparent;
}

body {
  --safe-area-inset-bottom: 0;

  // Disable highlight when Touch/Press
  -webkit-tap-highlight-color: $transparent;
  margin: 0;
  font-family: PoppinsSans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .content-wrapper {
    flex: 1 0 auto;
    padding: 0px $content-padding;
    padding-bottom: $content-padding-bottom;
    min-height: 350px;
    margin-top: $header-height;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .page-title {
      color: $deep-blue;
    }

    .line {
      margin: 0 -40px;
      height: 1px;
      background-color: $light-gray-1;
    }

    /* Remove inputs border highlight */
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: none;
    }
  }

  /* TODO: need to eliminate the use of button-blue across the project and either create a button component or better global styles. */
  .button-blue {
    font-family: PoppinsSans, sans-serif;
    height: 40px;
    border-radius: 30.5px;
    background-color: $purple;
    border: none;
    outline: none;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: $white;
    cursor: pointer;
    text-decoration: none;
    width: fit-content;
    min-width: 200px;
    user-select: none;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      padding: 0 1.25rem;
      font-family: PoppinsSans, sans-serif;
      font-weight: normal;
      color: $light-gray-2;
      border-radius: 6.25rem;
      background-color: $light-gray-3;
      cursor: not-allowed;
    }

    &.transparent {
      background-color: transparent;
      color: $secondary-blue;
      border: 1px solid $light-gray-1;
    }
  }

  h1 {
    font-size: $large;
  }

  h2,
  h1 {
    font-family: PoppinsSans, sans-serif;
  }

  p {
    font-size: $moderate;
  }

  textarea {
    font-family: PoppinsSans, sans-serif;
  }
}

:lang(he) {
  font-family: Assistant-Bold, sans-serif;
}

.content-element {
  max-width: $element-max-width;
  margin: auto;
}

// Use with <br /> tags
@media screen and (min-width: $mobile-breakpoint) {
  .mobile-break {
    display: none;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  body {
    // Use with <br /> tags
    .desktop-break {
      display: none;
    }

    h1 {
      font-size: $medium;
    }

    h2 {
      font-size: $medium;
    }

    .content-wrapper {
      padding: 0px $content-padding-mobile;
      padding-bottom: $content-padding-bottom;
      margin-top: $header-height-mobile;

      .page-title {
        text-align: center;
      }

      .line {
        margin: 0 -20px;
      }
    }

    .button-blue {
      width: 100%;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hebrew-font {
  font-family: Heebo, sans-serif;

  h1,
  h2,
  h3,
  p,
  button {
    font-family: Heebo, sans-serif;
  }
}

:-webkit-any-link {
  color: inherit;
}

@supports (padding-bottom: constant(safe-area-inset-bottom)) {
  body {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}

@supports (padding-bottom: env(safe-area-inset-bottom)) {
  body {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: $c-gray-20;
  border-radius: 0.5rem;
  &:hover {
    background-color: $c-gray-50;
    cursor: pointer;
  }
}

a:visited {
  background-color: transparent;
  text-decoration: none;
}

@import "../../../../../../../../constants";
@import "../../../../../../../../styles/mixins";
@import "../../../../../../../../styles/sizes";

.container {
  display: flex;
  flex-direction: column;
  color: $c-neutrals-600;
}

.title {
  margin-top: 0;
  margin-bottom: 1.5rem;
  font-size: $small;

  @include tablet {
    margin-bottom: 1.75rem;
  }
}

.projectsWrapper {
  @include flex-list-with-gap(0.75rem);

  padding: 0;
  list-style-type: none;

  @include tablet {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.projectsItem {
  max-width: 5.75rem;
  width: 100%;

  @include tablet {
    max-width: 6rem;
  }
}

@import "../../../../../../constants";
@import "../../../../../../styles/sizes";

.container {
  margin-top: 1.375rem;
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-template-areas: "approve abstain reject";
  grid-gap: 1rem 1.5rem;

  @include phone-orientated {
    grid-template-areas:
      "approve"
      "reject"
      "abstain";
  }
}

.containerImmediate {
  grid-template-areas: "approve reject";
}

.buttonApprove {
  grid-area: approve;
}

.buttonAbstain {
  grid-area: abstain;
}

.buttonReject {
  grid-area: reject;
}

@import "../../../../constants";
@import "../../../../styles/sizes";

.tabs {
  flex-shrink: unset;
  white-space: nowrap;
}
.tabsFixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  height: var(--bottom-tabs-height);
  padding: 0 0 var(--bottom-tabs-pb);
  display: grid;
  grid-template-columns: repeat(var(--items-amount, 5), minmax(0, 1fr));
  background-color: var(--secondary-background);
  border-top: 0.0625rem solid var(--gentle-stroke);
}

.tab {
  --tab-ml: 3rem;
  --tab-pb: 1.375rem;
  --tab-fw: 600;
  --tab-fs: #{$small};
  --tab-inactive-color: #{$c-neutrals-300};
  --tab-inactive-hover-color: #{var(--primary-text)};
  --tab-active-color: #{$c-neutrals-600};

  @include tablet {
    --tab-ml: 0;
    --tab-pb: 0;
    --tab-fs: #{$xxxsmall-2};
  }
}

.iconWrapper {
  height: 1.5rem;
  margin-bottom: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

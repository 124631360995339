@import "../../../../../../constants";

.button {
  display: flex;
  justify-content: space-between;
  border-radius: 0.3rem;
  padding: 0.875rem 1.25rem;
  background-color: transparent;
  border: 0.06rem solid;
}

.buttonText {
  margin: 0;
  font-size: $small;
  letter-spacing: 0.01em;
  line-height: 120%;
}

.approveText {
}
.approveButton {
  color: var(--primary-fill);
  border-color: var(--primary-fill);
}

.approveButton:hover {
}

.abstainText {
}

.abstainButton {
  color: $c-gray-50;
  border-color: $c-gray-50;
}

.abstainButton:hover {
}

.rejectText {
}

.rejectButton {
  color: var(--warning);
  border-color: var(--warning);
}

.rejectButton:hover {
}

.icon {
  flex-shrink: 0;
}

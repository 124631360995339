@import "../../../../../constants";
@import "../../../../../styles/sizes";

.modal {
  max-height: 100vh;
  max-width: 100vw;
  width: 100vw;
  margin: 0;
  border-radius: 0;
}

.modalHeader {
  min-height: 0 !important;
  background-color: $black;
}

.modalContent {
  padding: 0 !important;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem 0.9375rem 1.3125rem;
  margin-bottom: 1.9375rem;
  box-sizing: border-box;
  background-color: $black;
}

.backButtonIcon {
  margin-right: 0.25rem;
  color: $white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $black;
  height: calc(100vh - 1.5rem);
  width: 100vw;
}

.imageWrapper {
  overflow-y: auto;
}

.videoContainer {
  position: relative;
  width: fit-content;
  z-index: 3;
}

.video {
  width: 100%;
  height: auto;
  object-fit: contain;
  padding: 0.5rem 0.1875rem;
  box-sizing: border-box;
}

.image {
 width: 100%;
 height: auto;
 object-fit: contain;
 padding: 0.5rem 0.1875rem;
 box-sizing: border-box;
}
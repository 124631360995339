@import "../../../../../../../../../../constants";
@import "../../../../../../../../../../styles/sizes";

.item {
  --main-color: #{$c-primary-400};
  --text-decoration: none;

  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover {
    --text-decoration: underline;
  }

  @include tablet {
    align-items: center;
    text-align: center;
  }
}
.itemDisabled {
  --main-color: #{$c-neutrals-300};

  cursor: not-allowed;
}

.iconWrapper {
  width: 4.5rem;
  height: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-fill);
  background-color: var(--secondary-background);
  border: 0.0625rem solid var(--primary-fill);
  border-radius: 50%;
  box-sizing: border-box;
}

.icon {
  flex-shrink: 0;
  width: 1.375rem;
  height: 1.375rem;
  color: inherit;
}

.text {
  margin-top: 1rem;
  font-family: PoppinsSans, sans-serif;
  font-weight: bold;
  font-size: $small;
  color: var(--primary-fill);
  text-decoration: var(--text-decoration);
}

.tooltipContent {
  max-width: 20rem;
  z-index: 3;
  background-color: var(--secondary-background);
}
